import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './http-routing-fields.constant';

const en = {
  hostHint: '',
  hostLabel: 'Domains',
  hostPlaceholder: 'Domain, subdomain or wildcard..',
  ssl: 'SSL enabled'
};

export type HttpRoutingFieldsTranslations = typeof en;

@NgModule()
export class HttpRoutingFieldsTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
