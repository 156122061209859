import { Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { ThemeService } from '@zerops/zef/theme';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { formValueOnValid } from '@zerops/zef/forms';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { UserDataAddForm } from '@zerops/zui/user-data-add-form';
import { AppState } from '@zerops/zerops/app';
import { addProjectCustomEnv } from '@zerops/zerops/core/project-base';
import { FEATURE_NAME } from './project-env-add-dialog.constant';
import { ProjectEnvAddDialogTranslations } from './project-env-add-dialog.translations';

@Component({
  selector: 'z-project-env-add-dialog',
  templateUrl: './project-env-add-dialog.container.html',
  styleUrls: [ './project-env-add-dialog.container.scss' ]
})
export class ProjectEnvAddDialogContainer extends ZefReactiveComponent implements OnDestroy {

  /**
   * Event streams properties.
   */
  onAdd$ = new Subject<void>();
  onClose$ = new Subject<void>();

  /**
   * Forms properties.
   */
  formState$ = this._userDataAddForm.state$;

  /**
   * Sync properties.
   */
  dialogKey = FEATURE_NAME;
  actionKey = addProjectCustomEnv.type;

  /**
   * Async properties.
   */
  open$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  projectId$ = this._store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map(({ meta }) => meta as string)
  );
  projectEnvAddFormTranslations$ = this.translate$<ProjectEnvAddDialogTranslations>(FEATURE_NAME);

  /**
   * State resolver property.
   */
  state = this.$connect({
    open: this.open$,
    formState: this.formState$,
    isDarkTheme: this._theme.isDarkMode$,
    projectEnvAddFormTranslations: this.projectEnvAddFormTranslations$
  });

  /**
   * Action streams methods.
   */
  private _addAction$ = this.onAdd$.pipe(
    formValueOnValid(this._userDataAddForm),
    withLatestFrom(this.projectId$),
    map(([ state, id ]) => addProjectCustomEnv({
      projectId: id,
      payload: state
    }, { type: 'pop' }))
  );

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _userDataAddForm: UserDataAddForm,
    private _theme: ThemeService
  ) {
    super();

    this.$dispatchActions([
      this._addAction$,
      this._closeAction$
    ]);
  }

  // TODO https://github.com/typebytes/ngx-template-streams/issues/8
  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
