import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { onZefDialogClose, zefDialogClose, zefDialogOpen } from '@zerops/zef/dialog';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { pipelineRetriggerDialogActions } from './pipeline-retrigger-dialog.state';
import { FEATURE_NAME } from './pipeline-retrigger-dialog.constant';
import { PipelineRetriggerForm } from './pipeline-retrigger-dialog.form';
import { ServiceStackEntity, triggerRedeploySuccess } from '@zerops/zerops/core/service-stack-base';
import { firstAvailable } from '@zerops/zef/core';
import { UserDataEntity } from '@zerops/zerops/core/user-data-base';

@Injectable()
export class PipelineRetriggerDialogEffect {

  // # Deps
  #actions$ = inject(Actions);
  #form = inject(PipelineRetriggerForm);
  #userDataEntity = inject(UserDataEntity);
  #serviceStackEntity = inject(ServiceStackEntity);

  // # Streams
  serviceStackAvailable$ = this.#actions$.pipe(
    ofType(pipelineRetriggerDialogActions.open),
    map((d) => d.id),
    filter((d) => !!d),
    switchMap((d) => this.#serviceStackEntity.entityById$(d).pipe(
      firstAvailable()
    ))
  );
  dialogClose$ = this.#actions$.pipe(onZefDialogClose(FEATURE_NAME));

  // # Effects
  onDialogOpen$ = createEffect(() => this.#actions$.pipe(
    ofType(pipelineRetriggerDialogActions.open),
    map(({ id }) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: id
    }))
  ));

  setupUserDataListStream$ = createEffect(() => this.serviceStackAvailable$.pipe(
    map((serviceStack) => this.#userDataEntity.listSubscribe(
      serviceStack.project.clientId,
      {
        name: FEATURE_NAME,
        id: serviceStack.id
      },
      {
        search: [
          {
            name: 'serviceStackId',
            operator: 'eq',
            value: serviceStack.id
          }
        ]
      }
    ))
  ));

  // onDialogOpenSetForm$ = createEffect(() => this.onDialogOpen$.pipe(
  //   switchMap(({ meta }) => this.#userDataEntity.list$({
  //     name: FEATURE_NAME,
  //     id: meta
  //   }).pipe(
  //     filter((d) => !!d?.length),
  //     take(1),
  //     map(() => this.#form.setValue({
  //       ...this.#form.defaultValues
  //     }))
  //   ))
  // ));

  onRetriggerPipelineSuccess$ = createEffect(() => this.#actions$.pipe(
    ofType(triggerRedeploySuccess),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onDialogCloseResetForm$ = createEffect(() => this.dialogClose$.pipe(
    mergeMap(() => [
      this.#form.reset(),
      this.#form.setDefaultValues()
    ])
  ));

}
