import { ChangeDetectionStrategy, Component, inject, viewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import { Subject, map } from 'rxjs';
import { AppState } from '@zerops/zerops/app';
import { FEATURE_NAME } from './recurring-top-up-config-dialog.constant';
import { RecurringTopUpConfigDialogForm } from './recurring-top-up-config-dialog.form';
import { RecurringTopUpConfigFormComponent } from './components/recurring-top-up-config-form.component';
import { autoChargeUpdate } from '@zerops/zerops/core/billing-base';
import { formValueOnValid } from '@zerops/zef/forms';
import { selectBillingInfo } from '@zerops/zerops/core/client-base';
import { BILLING_INFO_EDIT_DIALOG_FEATURE_NAME } from '../billing-info-edit-dialog';

@Component({
  selector: 'z-recurring-top-up-config-dialog',
  templateUrl: './recurring-top-up-config-dialog.feature.html',
  styleUrl: './recurring-top-up-config-dialog.feature.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecurringTopUpConfigDialogFeature extends ZefReactiveComponent {

  // # Deps
  #store = inject<Store<AppState>>(Store);
  #form = inject(RecurringTopUpConfigDialogForm);

  // # Event Streams
  onClose$ = new Subject<void>();
  onSubmit$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  billingInfoEditDialogKey = BILLING_INFO_EDIT_DIALOG_FEATURE_NAME;
  autoChargeUpdateKey = autoChargeUpdate.type;

  topUpFormRef = viewChild(RecurringTopUpConfigFormComponent);

  // -- async
  open$ = this.#store.pipe(
    select(selectZefDialogState(FEATURE_NAME)),
    map((data) => data.state)
  );
  formState$ = this.#form.state$;
  billingInfo$ = this.#store.pipe(select(selectBillingInfo));

  // # State resolver
  state = this.$connect({
    open: this.open$,
    billingInfo: this.billingInfo$,
    formState: this.formState$
  });

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([

      this.onClose$.pipe(
        map(() => zefDialogClose({ key: FEATURE_NAME }))
      ),

      this.onSubmit$.pipe(
        formValueOnValid(this.#form),
        map((options) => autoChargeUpdate({
          autoCharge: options.autoChargePeriod !== 'DISABLE',
          autoChargePeriod: options.autoChargePeriod !== 'DISABLE'
            ? options.autoChargePeriod
            : 'MONTH',
          maximumChargeAmount: options.maximumChargeAmount || 1000
        }))
      )

    ]);
  }

}
