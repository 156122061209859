import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zefDialogOpen, zefDialogClose } from '@zerops/zef/dialog';
import { map, filter, switchMap, withLatestFrom, take } from 'rxjs/operators';
import { httpRoutingAddDialogOpen } from './http-routing-add-dialog.action';
import { FEATURE_NAME } from './http-routing-add-dialog.constant';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { HttpRoutingForm } from '@zerops/zui/http-routing-form';
import { box } from 'ngrx-forms';
import { successOf } from '@zerops/zef/entities';
import { HttpRouting } from '@zerops/models/http-routing';
import { HttpRoutingEntity } from '@zerops/zerops/core/http-routing-base';
import { ZefSnackService } from '@zerops/zef/snack';

@Injectable()
export class HttpRoutingAddDialogEffect {

  private _onHttpRoutingAddDialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(httpRoutingAddDialogOpen),
    map((data) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: data
    }))
  ));

  // FIXME TODO: remove dependency on service stack
  private _onAddDialogOpenSetDefaultLocation$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogOpen),
    filter((action) => action?.key === FEATURE_NAME),
    switchMap(({ meta }) => this._serviceStackEntity
      .entityById$(meta?.serviceStackId)
      .pipe(
        filter((d) => !!d),
        map((d) => ({
          ports: d.ports.filter((itm) => [ 'http', 'https' ].includes(itm.scheme)),
          serviceStackId: d.id
        })),
        take(1)
      )
    ),
    withLatestFrom(this._httpRoutingForm.value$),
    map(([ { ports, serviceStackId } , formValues ]) => this._httpRoutingForm.setValue({
      ...formValues,
      locations: [ ports.length === 1
        ? { path: '/', portData: box({ port: ports[0].port, serviceStackId }) }
        : formValues.locations[0]
      ]
    }))
  ));

  private _onAddSuccess$ = this._actions$.pipe(
    successOf<HttpRouting>(this._httpRoutingEntity.addOne)
  );

  // private _onAddSuccessNotification$ = createEffect(() => this._onAddSuccess$.pipe(
  //   switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.addSuccess` }))
  // ), { dispatch: false });

  private _onAddSuccessDialogClose$ = createEffect(() => this._onAddSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter(({ key, meta }) => key === FEATURE_NAME && !meta?.preventReset),
    switchMap(() => [
      this._httpRoutingForm.reset(),
      this._httpRoutingForm.setDefaultValues()
    ])
  ));

  constructor(
    private _actions$: Actions,
    private _serviceStackEntity: ServiceStackEntity,
    private _httpRoutingForm: HttpRoutingForm,
    private _httpRoutingEntity: HttpRoutingEntity,
    private _snack: ZefSnackService
  ) { }
}
