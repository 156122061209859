import { Injectable } from '@angular/core';
import { switchMap, of, catchError, map, filter, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';
import {
  exportCreditConsumption,
  exportCreditConsumptionSuccess,
  exportCreditConsumptionFail
} from './payment-base.action';
import { PaymentBaseApi } from './payment-base.api';
import { UserEntity } from '../user-base';
import { firstAvailable } from '@zerops/zef/core';
import { ZefSnackService } from '@zerops/zef/snack';

@Injectable()
export class PaymentBaseEffect {

  private _activeClientId$ = this._userEntity.activeClientId$.pipe(
    filter((d) => !!d)
  );

  /**
   * Is invoked by the action to download a file with credit consumption.
   */
  onExportCreditConsumptionRequest$ = createEffect(() => this._actions$.pipe(
    ofType(exportCreditConsumption),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this._api
        .downloadCreditConsumption$(clientId, action.data.month).pipe(
          map(({ file, contentType, contentDisposition }) => exportCreditConsumptionSuccess({
            file,
            contentType,
            contentDisposition,
            month: action.data.month
          }, action)),
          catchError((response) => of(exportCreditConsumptionFail(response, action)))
        )
      )
    ))
  ));

  /**
   * Processing of the downloaded binary data of credit consumption.
   */
  onSaveCreditConsumption$ = createEffect(() => this._actions$.pipe(
    ofType(exportCreditConsumptionSuccess),
    map(({ data }) => ({
      blob: new Blob([ data.file ], { type: data.contentType }),
      filename: this._getFilename(data.contentDisposition),
      month: data.month
    })),
    tap(({ blob, filename, month }) => saveAs(
      blob,
      filename ? filename : `zerops-credit-export-${month}.txt`
    ))
  ), { dispatch: false });

  /**
   * Showing a notification message on a successful export of credit consumption.
   */
  onSaveCreditConsumptionSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(exportCreditConsumptionSuccess),
    switchMap(({ data }) => this._snack.success$({ text: `Credit consumption for ${data.month} exported successfully. Use the downloaded file.` }))
  ), { dispatch: false });

  constructor(
    private _userEntity: UserEntity,
    private _actions$: Actions,
    private _api: PaymentBaseApi,
    private _snack: ZefSnackService
  ) {}

  private _getFilename(contentDisposition: string): string | null {
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
    return null;
  }

}
