@if (state?.controls) {
  <zef-form
    [state]="state"
    (submit)="submit.emit()">

    <zui-form-section class="__form-section" separator="none">

      <span *zuiFormSectionTitle>
        One-time pipeline trigger
      </span>

      <div class="fx:pt-1">

        <!-- build from git -->
        <div
          [class.is-active]="state?.controls.buildFromGitToggle.value === true"
          [class.is-disabled]="state?.controls.buildFromGitToggle.isDisabled"
          class="__toggle-group-wrap  __toggle-group-wrap--build-from-git">

          <zef-form-field
            class="__start-empty-toggle"
            last
            [appearance]="'slide-toggle'">
            <mat-slide-toggle
              [ngrxFormControlState]="state?.controls.buildFromGitToggle"
              [ngrxEnableFocusTracking]="true">
              <strong>Trigger a pipeline from a git repo containing zerops.yml</strong>
            </mat-slide-toggle>
          </zef-form-field>

          <p class="__toggle-group-desc">
            You can paste an URL of a public repository containing a zerops.yml file. This will trigger
            a pipeline once and create a service stack based on the code from the repository and the
            instructions in the zerops.yml file.
          </p>

          @if (state?.controls.buildFromGitToggle.value === true) {

            <zui-separator
              class="__separator"
              [position]="'start'"
              [spacing]="'mini'"
              [size]="'medium'"
            />

            <div class="__toggle-group-wrap_content">

              <!-- repo + setup -->
              <div class="__toggle-group-wrap-narrow-fields">

                <zef-form-field last class="__git-repo-form-field">
                  <mat-form-field color="accent">

                    <mat-label>Any publicly accessible git repository</mat-label>

                    <input
                      matInput
                      type="text"
                      [placeholder]="'e.g. https://github.com/zeropsio/recipe-nodejs'"
                      [ngrxEnableFocusTracking]="true"
                      [ngrxFormControlState]="state?.controls.buildFromGit"
                    />

                  </mat-form-field>
                </zef-form-field>

                <div class="fx:pt-1">
                  <zef-form-field last class="__git-repo-form-field">
                    <mat-form-field color="accent">

                      <mat-label><strong>Optional:</strong> Which zerops.yml <strong>`setup`</strong> to use (if `setup` in repo differs from your hostname)</mat-label>

                      <input
                        matInput
                        type="text"
                        [disabled]="state?.controls.buildFromGitSetup.isDisabled"
                        [placeholder]="'e.g. api'"
                        [ngrxEnableFocusTracking]="true"
                        [ngrxFormControlState]="state?.controls.buildFromGitSetup"
                      />

                    </mat-form-field>
                  </zef-form-field>
                </div>

              </div>

              <zui-separator
                class="__separator"
                [position]="'start'"
                [spacing]="'mini'"
                [size]="'medium'"
              />

              <!-- zerops.yml override -->
              <div>
                <zef-form-field class="__git-repo-form-code-field">

                  <zef-form-label><strong>Optional:</strong> Override the zerops.yml configuration in the repository</zef-form-label>

                  <div class="fx:pb-0-5  fx:pt-0-25  fx:text-center">

                    <button
                      mat-flat-button
                      type="button"
                      [disabled]="!activeAppVersion()"
                      (click)="prefillBuildFromGitYamlFromLastDeploy.emit()"
                      class="c-button--smaller  __prefill-button">
                      <mat-icon fontSet="material-icons-outlined">post_add</mat-icon>&nbsp;<span>Prefill from active deploy</span>
                    </button>

                    <button
                      mat-flat-button
                      type="button"
                      [disabled]="!state?.controls.buildFromGit.isValid || !enableLoadRecipeInfo() || recipeInfoLoading()"
                      (click)="prefillBuildFromGitYamlFromGitRepo.emit()"
                      class="c-button--smaller  __prefill-button">
                      <mat-icon fontSet="material-icons-outlined">post_add</mat-icon>&nbsp;<span>Prefill from the repository</span>
                    </button>

                  </div>

                  <zui-code-field
                    class="__code-field  __code-field--bigger"
                    [editorOptions]="{
                      quickSuggestions: {
                        other: true,
                        comments: false,
                        strings: true
                      }
                    }"
                    [editorModel]="{
                      language: 'yaml',
                      fileName: 'zerops-yml'
                    }"
                    [ngrxFormControlState]="state?.controls.buildFromGitYaml"
                    [ngrxEnableFocusTracking]="true">
                  </zui-code-field>

                </zef-form-field>
              </div>

              <zui-separator
                class="__separator"
                [position]="'start'"
                [spacing]="'mini'"
                [size]="'medium'"
              />

              <!-- env secrets override -->
              <div>
                <zef-form-field class="__git-repo-form-code-field">

                  <zef-form-label><strong>Optional:</strong> Update secret environment variables for the next deploy (will upsert)</zef-form-label>

                  <div class="fx:pb-0-5  fx:pt-0-25  fx:text-center">
                    <button
                      mat-flat-button
                      type="button"
                      (click)="prefillEnvs.emit()"
                      class="c-button--smaller  __prefill-button">
                      <mat-icon fontSet="material-icons-outlined">post_add</mat-icon>&nbsp;<span>Fill with the current secret variables</span>
                    </button>
                  </div>

                  <zui-code-field
                    class="__code-field"
                    [editorOptions]="{
                      language: 'ini',
                      wordWrap: true
                    }"
                    [ngrxFormControlState]="state.controls.userDataEnvFile"
                    [ngrxEnableFocusTracking]="true">
                  </zui-code-field>

                </zef-form-field>
              </div>

            </div>
          }

        </div>

        <!-- pipeline-->
        <div
          [class.is-active]="state?.controls.zeropsYamlToggle.value === true"
          [class.is-disabled]="state?.controls.zeropsYamlToggle.isDisabled"
          class="__toggle-group-wrap  __toggle-group-wrap--zerops-setup">

          <zef-form-field
            class="__start-empty-toggle"
            last
            [appearance]="'slide-toggle'">
            <mat-slide-toggle
              [ngrxFormControlState]="state?.controls.zeropsYamlToggle"
              [ngrxEnableFocusTracking]="true">
              <strong>Trigger a pipeline by writing a custom pipeline setup</strong>
            </mat-slide-toggle>
          </zef-form-field>

          <p class="__toggle-group-desc">
            You can write a custom pipeline setup to trigger once, this doesn't require
            any code (which can then be fetched manually inside the pipeline process).
          </p>

          @if (state?.controls.zeropsYamlToggle.value === true) {

            <zui-separator
              class="__separator"
              [position]="'start'"
              [spacing]="'mini'"
              [size]="'medium'"
            />

            <!-- zerops.yml -->
            <div class="__toggle-group-wrap_content">
              <zef-form-field class="__pipeline-form-code-field">

                <zef-form-label>Write pipeline to run and deploy</zef-form-label>

                <div class="fx:pb-0-5  fx:pt-0-25  fx:text-center">

                  <button
                    mat-flat-button
                    type="button"
                    [disabled]="!activeAppVersion()"
                    (click)="prefillZeropsYmlFromLastDeploy.emit()"
                    class="c-button--smaller  __prefill-button">
                    <mat-icon fontSet="material-icons-outlined">post_add</mat-icon>&nbsp;<span>Prefill from active deploy</span>
                  </button>

                  <button
                    mat-flat-button
                    type="button"
                    (click)="prefillZeropsYmlOutline.emit()"
                    class="c-button--smaller  __prefill-button">
                    <mat-icon fontSet="material-icons-outlined">post_add</mat-icon>&nbsp;<span>Prefill with basic outline of a pipeline</span>
                  </button>

                </div>

                <zui-code-field
                  #zeropsSetupCodeFieldRef
                  class="__code-field  __code-field--bigger"
                  [editorOptions]="{
                    quickSuggestions: {
                      other: true,
                      comments: false,
                      strings: true
                    }
                  }"
                  [editorModel]="{
                    language: 'yaml',
                    fileName: 'zerops-yml'
                  }"
                  [ngrxFormControlState]="state?.controls.zeropsYaml"
                  [ngrxEnableFocusTracking]="true">
                </zui-code-field>
              </zef-form-field>
            </div>

            <zui-separator
              class="__separator"
              [position]="'start'"
              [spacing]="'mini'"
              [size]="'medium'"
            />

            <!-- override secret envs -->
            <div>
              <zef-form-field class="__pipeline-form-code-field">

                <zef-form-label><strong>Optional:</strong> Update secret environment variables for the next deploy</zef-form-label>

                  <zui-code-field
                    class="__code-field"
                    [editorOptions]="{
                      language: 'ini',
                      wordWrap: true
                    }"
                    [ngrxFormControlState]="state.controls.userDataEnvFile"
                    [ngrxEnableFocusTracking]="true">
                  </zui-code-field>

                </zef-form-field>
            </div>

          }

        </div>
      </div>

    </zui-form-section>

  </zef-form>
}
