<ng-template #locationRef let-location let-showstack="showStack" let-type="type">
  <zui-routing-location-item
    pathTextAlign="right"
    class="c-http-routing-item-locations"
    disableLink
    showDomainPlaceholder
    [serviceStackName]="location?.serviceStackInfo?.serviceStackName"
    [serviceStackId]="location?.serviceStackId"
    [type]="type"
    [port]="location?.port"
    [path]="location?.path">
  </zui-routing-location-item>
</ng-template>

<div
  *ngIf="!hideOutOfSync && !data.isSynced"
  class="__out-of-sync">
  <div class="__out-of-sync_update" *ngIf="!data.isSynced && !data.deleteOnSync && !!data.lastSync">
    <span>Publish changes to Propagate Updates</span> <mat-icon class="__out-of-sync_icon">sync</mat-icon>
  </div>
  <div class="__out-of-sync_deletion" *ngIf="!data.isSynced && data.deleteOnSync && !!data.lastSync">
    <span>Publish Changes to Confirm Deletion</span> <mat-icon class="__out-of-sync_icon">delete_outline</mat-icon>
  </div>
  <div class="__out-of-sync_new" *ngIf="!data.isSynced && !data.lastSync">
    <span>Publish Changes to Add Domain Access</span> <mat-icon class="__out-of-sync_icon">add</mat-icon>
  </div>
</div>

<div class="__grid">

  <div>
    <div class="__domains">
      <div *ngFor="let domain of data.domains; trackBy: trackBy; let index = index;">
        <span
          class="__domain"
          [class.is-not-dns-active]="domain.dnsCheckStatus === dnsStatuses.Failed"
          [class.is-active]="domain.dnsCheckStatus === dnsStatuses.Ok && (domain.sslStatus === sslStatuses.Active || !data.sslEnabled)">

          <!-- Domain name -->
          <div>
            <h4 class="__domain_name">
              <span class="__domain_name-text">{{ domain.domainName }}</span>
            </h4>

            <div class="__domain-info">

              <div class="__domain-info-row">
                <span class="__domain-info-row_label">DNS:</span>
                <span class="__domain-info-row_desc">
                  <span
                    class="__domain-info-row_status-icon"
                    [class.is-active]="domain.dnsCheckStatus === dnsStatuses.Ok">
                    <mat-icon class="__domain-info-row_status-icon-icon">{{ domain.dnsCheckStatus === dnsStatuses.Ok ? 'check' : 'priority_high' }}</mat-icon>
                  </span>
                  <span class="__domain-info-row_desc-text">{{ translations.dnsStatuses[domain.dnsCheckStatus] }}</span>
                </span>
              </div>

              <div class="__domain-info-row">
                <span class="__domain-info-row_label">SSL:</span>
                <span class="__domain-info-row_desc">
                  <span
                    class="__domain-info-row_status-icon"
                    [class.is-active]="domain.sslStatus === sslStatuses.Active">
                    <mat-icon class="__domain-info-row_status-icon-icon">{{ domain.sslStatus === sslStatuses.Active ? 'check' : 'priority_high' }}</mat-icon>
                  </span>
                  <span class="__domain-info-row_desc-text">{{ translations.sslStatuses[domain.sslStatus] }}</span>
                </span>
              </div>

              @if (data.cdnEnabled) {
                <div class="__domain-info-row">
                  <span class="__domain-info-row_label">CDN:</span>
                  <span class="__domain-info-row_desc">
                    <span
                      class="__domain-info-row_status-icon"
                      [class.is-active]="domain.cdnStatus === cdnStatuses.Active">
                      <mat-icon class="__domain-info-row_status-icon-icon">{{ domain.cdnStatus === cdnStatuses.Active ? 'check' : 'priority_high' }}</mat-icon>
                    </span>
                    <span class="__domain-info-row_desc-text">
                      {{
                        domain.dnsCheckStatus === dnsStatuses.Ok && domain.cdnStatus === cdnStatuses.BeingInstalled
                          ? translations.cdnStatuses[cdnStatuses.WaitingForDns]
                          : translations.cdnStatuses[domain.cdnStatus]
                      }}
                    </span>
                  </span>
                </div>
              }

            </div>

          </div>

        </span>
      </div>
    </div>
  </div>

  <div>
    <div class="__locations-wrap">

      <!-- main locations -->
      <div
        class="__locations-item-wrap   __locations-item-wrap--main"
        *ngFor="let location of stackLocations; trackBy: trackBy;">
        <ng-container *ngTemplateOutlet="locationRef; context: { $implicit: location, type: 'stack' }"></ng-container>
      </div>

      <!-- other locations -->
      <div
        class="__locations-item-wrap"
        *ngFor="let location of otherLocations; trackBy: trackBy;">
        <ng-container *ngTemplateOutlet="locationRef; context: { $implicit: location, type: 'other' }"></ng-container>
      </div>

    </div>
  </div>

</div>
