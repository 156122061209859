import { NgModule } from '@angular/core';
import { RollbarLibModule } from './rollbar-lib';
import { UserBaseModule } from './user-base';
import { SettingsBaseModule } from './settings-base';
import { ProjectBaseModule } from './project-base';
import { ServiceStackBaseModule } from './service-stack-base';
import { BillingBaseModule } from './billing-base';
import { NotificationBaseModule } from './notification-base';
import { UserDataBaseModule } from './user-data-base';
import { HttpRoutingBaseModule } from './http-routing-base';
import { PortRoutingBaseModule } from './port-routing-base';
import { AppVersionBaseModule } from './app-version-base';
import { ClientUserBaseModule } from './client-user-base';
import { ResourceStatisticsBaseModule } from './resource-statistics-base';
import { ServiceBaseModule } from './service-base';
import { SubscriptionBaseModule } from './subscription-base';
import { TransactionDebitBaseModule } from './transaction-debit-base';
import { TransactionCreditBaseModule } from './transaction-credit-base';
import { ClientBaseModule } from './client-base';
import { ProcessBaseModule } from './process-base';
import { ClientStatusBaseModule } from './client-status-base';
import { ProjectStatusBaseModule } from './project-status-base';
import { ContainerBaseModule } from './container-base';
import { PaymentBaseModule } from './payment-base/payment-base.module';

@NgModule({
  imports: [
    UserBaseModule,
    ServiceBaseModule,
    ProjectBaseModule,
    PaymentBaseModule,
    SettingsBaseModule,
    ResourceStatisticsBaseModule,
    SubscriptionBaseModule,
    BillingBaseModule,
    ServiceStackBaseModule,
    TransactionDebitBaseModule,
    TransactionCreditBaseModule,
    NotificationBaseModule,
    UserDataBaseModule,
    HttpRoutingBaseModule,
    PortRoutingBaseModule,
    AppVersionBaseModule,
    ProcessBaseModule,
    ClientUserBaseModule,
    ClientBaseModule,
    ClientStatusBaseModule,
    ProjectStatusBaseModule,
    RollbarLibModule,
    ContainerBaseModule
  ]
})
export class CoreModule {}
