<zef-form
  [state]="state"
  (submit)="submit.emit()">

  <zui-form-section separator="none">

    <span *zuiFormSectionTitle>
      Recurring Top-up Configuration
    </span>

    <div *zuiFormSectionDesc>
      Your saved card will be charged automatically when you start running low on credits.
      The amount charged will be estimated based on your actual consumption,
      while respecting your maximum charge limit for the selected period (week or month).
      Minimum charge is $10.
    </div>

    <div class="fx:pt-0-5">

      <zef-form-field
        last
        class="__radio-form-field"
        [appearance]="'radio'">

        <zef-form-label>Select auto top-up period for maximum charge limit:</zef-form-label>

        <mat-radio-group
          class="__radio-group"
          [ngrxEnableFocusTracking]="true"
          [ngrxFormControlState]="state?.controls.autoChargePeriod">

          <mat-radio-button class="__radio" [value]="'DISABLE'">
            Disable
          </mat-radio-button>

          <mat-radio-button class="__radio" [value]="'WEEK'">
            Week
          </mat-radio-button>

          <mat-radio-button class="__radio" [value]="'MONTH'">
            Month
          </mat-radio-button>

        </mat-radio-group>

      </zef-form-field>

      @if (state.value.autoChargePeriod !== 'DISABLE') {

        <zui-separator
          class="fx:pl-0-5"
          [size]="'mini'"
          [position]="'start'"
          [spacing]="'mini'"
        />

        <zef-form-field class="__max-amount-form-field">
          <mat-form-field color="accent">
            <mat-label>Maximum charge amount</mat-label>
            <input
              matInput
              type="number"
              [placeholder]="'Won\'t charge more than this per selected period..'"
              [ngrxEnableFocusTracking]="true"
              [ngrxFormControlState]="state?.controls.maximumChargeAmount"
            />
          </mat-form-field>
        </zef-form-field>
      }

    </div>

  </zui-form-section>
</zef-form>
