export const LARAVEL_JETSTREAM_PROD_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: laravel-jetstream-prod
  corePackage: SERIOUS
  tags:
    - zerops-recipe
    - production

services:
  - hostname: app
    type: php-nginx@8.4
    buildFromGit: https://github.com/zeropsio/recipe-laravel-jetstream
    enableSubdomainAccess: true
    minContainers: 2
    envSecrets:
      APP_NAME: ZeropsLaravelJetstreamProd
      APP_DEBUG: false
      APP_ENV: production
      APP_KEY: <@generateRandomString(<32>)>
    verticalAutoscaling:
      minRam: 0.25
      minFreeRamGB: 0.125

  - hostname: db
    type: postgresql@16
    mode: HA
    priority: 10

  - hostname: redis
    type: valkey@7.2
    mode: HA
    priority: 10

  - hostname: storage
    type: object-storage
    objectStorageSize: 2
    objectStoragePolicy: public-read
    priority: 10`;
