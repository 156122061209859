import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  LOCALE_ID
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withJsonpSupport,
  withInterceptors
} from '@angular/common/http';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS
} from '@angular/material/legacy-form-field';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxStripeModule } from 'ngx-stripe';
import { TimeagoModule } from 'ngx-timeago';
import { MarkdownModule } from 'ngx-markdown';
import { translocoConfig, provideTransloco } from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { PROJECTINFO_ENDPOINT } from '@zerops/zui/project-info';
import { ZuiRecipesModule } from '@zerops/zui/recipes';
import { ZefIntercomModule } from '@zerops/zef/intercom';
import { environment } from '@zerops/zerops/env';
import { appRoutes } from './app.routes';
import { appReducers, metaReducers } from './app.reducer';
import { AppEffect } from './app.effect';
import { CoreModule } from '@zerops/zerops/core';
import { ZefLibModule } from '../core/zef-lib';
import { apiBaseInterceptor } from '../interceptors';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateFnsDatetimeModule } from '@zerops/zef/datetimepicker';
import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS
} from '@angular/material/legacy-tooltip';
import { TerminalModule } from '../feature/terminal';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top'
      })
    ),
    provideHttpClient(
      withInterceptors([apiBaseInterceptor]),
      withJsonpSupport()
    ),
    importProvidersFrom(
      NgxStripeModule.forRoot(environment.stripePublishableKey),
      StoreModule.forRoot(appReducers, { metaReducers }),
      EffectsModule.forRoot([ AppEffect ]),
      TimeagoModule.forRoot(),
      MarkdownModule.forRoot(),
      ZefIntercomModule.forRoot({
        updateOnRouterChange: true,
        app_id: environment.intercomAppId
      }),
      ZuiRecipesModule.forRoot(environment.webApiUrl),
      CoreModule,
      ZefLibModule,
      MatDateFnsDatetimeModule,
      TerminalModule.forRoot({
        forceSecuredEndpoint: environment.forceSecuredWebsocket
      })
    ),
    provideTransloco({
      config: translocoConfig({
        availableLangs: ['en'],
        defaultLang: 'en',
        prodMode: !isDevMode()
      })
    }),
    provideTranslocoMessageformat({
      locales: 'en-US'
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US'
      }
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always'
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true
      }
    },
    {
        provide: LOCALE_ID,
        useValue: 'cs'
    },
    {
      provide: MAT_DATE_LOCALE,
      useExisting: LOCALE_ID,
    },
    {
      provide: PROJECTINFO_ENDPOINT,
      useValue: environment.projectInfoUrl
    }
  ]
};
