<ng-container *zefMessageFormat="let mf">

  <zui-form-section
    [isZen]="isZen"
    [separator]="'none'">

    <span *zuiFormSectionTitle>
      {{ translations?.hostnameTitle }}
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.hostnameDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

    <zef-form-field>
      <z-hostname-field
        [label]="translations?.hostnameLabel"
        [placeholder]="translations?.hostnamePlaceholder"
        [control]="state?.controls.name">
      </z-hostname-field>
    </zef-form-field>

  </zui-form-section>

  <zui-separator
    [orientation]="'vertical'"
    [size]="'medium'"
    [spacing]="'large'"
    [position]="'center'"
  />

  <!-- DEPRECATED: start command moved to zerops.yml -->
  <!-- <zui-form-section [separatorSpacing]="'huge'">

    <span *zuiFormSectionTitle>
      Start Command
    </span>

    <div *zuiFormSectionDesc>
      A command that should start your service will be triggered after each deployment.
    </div>

    <zef-form-field>
      <mat-form-field color="accent" class="__single-field">

        <mat-label>Command</mat-label>

        <input
          matInput
          type="text"
          autocomplete="off"
          class="c-start-command-input"
          [placeholder]="'e.g. npm start, yarn, node dist/index.js'"
          [ngrxFormControlState]="state?.controls.startCommand"
          [ngrxEnableFocusTracking]="true"
        />

      </mat-form-field>
    </zef-form-field>

  </zui-form-section> -->

  <zui-form-section
    [isZen]="isZen"
    [separator]="'none'"
    class="__user-data-field">

    <span *zuiFormSectionTitle>
      {{ translations?.envVariablesTitle }}
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.envVariablesDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

  </zui-form-section>

  <zef-form-field>
    <zui-code-field
      class="__code-field"
      [editorOptions]="{ language: 'ini' }"
      [ngrxFormControlState]="state?.controls.userData"
      [ngrxEnableFocusTracking]="true">
    </zui-code-field>
  </zef-form-field>

  <zui-separator
    [orientation]="'vertical'"
    [size]="'medium'"
    [spacing]="'large'"
    [position]="'center'"
  />

  <zui-form-section
    [isZen]="isZen"
    [separator]="'none'"
    class="__scaling-section">

    <span *zuiFormSectionTitle>
      Automatic Scaling
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.scalingDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

    <zef-form-field>
      <zui-autoscaling-form-field
        [serviceStackType]="serviceStackType"
        [singleMode]="serviceStackType === serviceStackTypes.Docker"
        [ngrxFormControlState]="state?.controls.customAutoscaling"
        [defaultHorizontalAutoscaling]="defaultHorizontalAutoscaling"
        [defaultVerticalAutoscaling]="defaultVerticalAutoscaling">
      </zui-autoscaling-form-field>
    </zef-form-field>

  </zui-form-section>

  <zui-separator
    [orientation]="'vertical'"
    [size]="'medium'"
    [spacing]="'large'"
    [position]="'center'"
  />

  <zui-form-section
    class="__start-empty-section"
    [isZen]="isZen"
    [separator]="'none'">

    <span *zuiFormSectionTitle>
      Want some immediate action?
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.startEmptyDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

    <!-- start without code -->
    <div class="__toggle-group-wrap">

      <zef-form-field
        class="__start-empty-toggle"
        last
        [appearance]="'slide-toggle'">
        <mat-slide-toggle
          [ngrxFormControlState]="state?.controls.startWithoutCode"
          [ngrxEnableFocusTracking]="true">
          <strong>Start an empty {{ serviceStackTypeName }} service without requiring code first</strong>
        </mat-slide-toggle>
      </zef-form-field>

      <p class="__toggle-group-desc">
        This will start an empty {{ serviceStackTypeName }} that you can root access through web terminal or CLI + SSH.
        This gives you a chance to install and test the dependencies and configurations before forming your final deterministic pipeline configuration in zerops.yml.
      </p>

    </div>

    <!-- build from git -->
    <div
      [class.is-active]="state?.controls.buildFromGitToggle.value === true"
      class="__toggle-group-wrap  __toggle-group-wrap--build-from-git">

      <zef-form-field
        class="__start-empty-toggle"
        last
        [appearance]="'slide-toggle'">
        <mat-slide-toggle
          [ngrxFormControlState]="state?.controls.buildFromGitToggle"
          [ngrxEnableFocusTracking]="true">
          <strong>Trigger a pipeline from a git repo containing zerops.yml once</strong>
        </mat-slide-toggle>
      </zef-form-field>

      <p class="__toggle-group-desc">
        You can paste an URL of a public repository containing a zerops.yml file. This will trigger
        a pipeline once and create a service stack based on the code from the repository and the
        instructions in the zerops.yml file.
      </p>

      @if (state?.controls.buildFromGitToggle.value === true) {
        <div class="__toggle-group-wrap_content">

          <div class="__toggle-group-wrap-narrow-fields">

            <zef-form-field last class="__git-repo-form-field">
              <mat-form-field color="accent">

                <mat-label>Any publicly accessible git repository</mat-label>

                <input
                  matInput
                  type="text"
                  [placeholder]="'e.g. https://github.com/zeropsio/recipe-nodejs'"
                  [ngrxEnableFocusTracking]="true"
                  [ngrxFormControlState]="state?.controls.buildFromGit"
                />

              </mat-form-field>
            </zef-form-field>

            <div class="fx:pt-1">
              <zef-form-field last class="__git-repo-form-field">
                <mat-form-field color="accent">

                  <mat-label><strong>Optional:</strong> Which zerops.yml <strong>`setup`</strong> to use (if `setup` in repo's zerops.yml differs from hostname)</mat-label>

                  <input
                    matInput
                    type="text"
                    [disabled]="state?.controls.buildFromGitSetup.isDisabled"
                    [placeholder]="'e.g. api'"
                    [ngrxEnableFocusTracking]="true"
                    [ngrxFormControlState]="state?.controls.buildFromGitSetup"
                  />

                </mat-form-field>
              </zef-form-field>
            </div>

          </div>

          <div class="fx:pt-1">
            <zef-form-field class="__git-repo-form-code-field">

              <zef-form-label><strong>Optional:</strong> Override the zerops.yml configuration in the repository</zef-form-label>

              <zui-code-field
                class="__code-field  __code-field--bigger"
                [editorOptions]="{
                  quickSuggestions: {
                    other: true,
                    comments: false,
                    strings: true
                  }
                }"
                [editorModel]="{
                  language: 'yaml',
                  fileName: 'zerops-yml'
                }"
                [ngrxFormControlState]="state?.controls.buildFromGitYaml"
                [ngrxEnableFocusTracking]="true">
              </zui-code-field>

            </zef-form-field>
          </div>

        </div>
      }

    </div>

    <!-- zerops yaml-->
    <div
      [class.is-active]="state?.controls.zeropsYamlToggle.value === true"
      class="__toggle-group-wrap  __toggle-group-wrap--zerops-setup">

      <zef-form-field
        class="__start-empty-toggle"
        last
        [appearance]="'slide-toggle'">
        <mat-slide-toggle
          [ngrxFormControlState]="state?.controls.zeropsYamlToggle"
          [ngrxEnableFocusTracking]="true">
          <strong>Write a pipeline setup to trigger once, after the service is added</strong>
        </mat-slide-toggle>
      </zef-form-field>

      <p class="__toggle-group-desc">
        You can write a custom pipeline setup to trigger once, after the service is added, this doesn't require
        any code (which can then be fetched manually inside the pipeline process).
      </p>

      @if (state?.controls.zeropsYamlToggle.value === true) {
        <div class="__toggle-group-wrap_content">

          <div class="fx:pb-0-5  fx:text-center">
            <button
              mat-flat-button
              type="button"
              (click)="prefillZeropsSetup()"
              class="c-button--smaller">
              <mat-icon fontSet="material-icons-outlined">post_add</mat-icon>&nbsp;<span>Prefill with basic outline of a pipeline configuration</span>
            </button>
          </div>

          <zef-form-field>
            <zui-code-field
              #zeropsSetupCodeFieldRef
              class="__code-field  __code-field--bigger"
              [editorOptions]="{
                quickSuggestions: {
                  other: true,
                  comments: false,
                  strings: true
                }
              }"
              [editorModel]="{
                language: 'yaml',
                fileName: 'zerops-yml'
              }"
              [ngrxFormControlState]="state?.controls.zeropsYaml"
              [ngrxEnableFocusTracking]="true">
            </zui-code-field>
          </zef-form-field>
        </div>
      }

    </div>

  </zui-form-section>

  @if (state?.controls.zeropsYamlToggle.value === true || state?.controls.buildFromGitToggle.value === true) {

    <zui-separator
      [orientation]="'vertical'"
      [size]="'medium'"
      [spacing]="'large'"
      [position]="'center'"
    />

    <zui-form-section
      [isZen]="isZen"
      [separator]="'none'">

      <span *zuiFormSectionTitle>
        Enable Zerops subdomain access for previews?
      </span>

      <div *zuiFormSectionDesc>
        <zui-docs-text>
          Since you've defined a pipeline to run after the service is added,
          you can optionally enable Zerops subdomain access for previews
          immediately after the pipeline is finished.
        </zui-docs-text>
      </div>

      <zef-form-field
        class="__subdomain-toggle"
        last
        [appearance]="'slide-toggle'">
        <mat-slide-toggle
          [ngrxFormControlState]="state?.controls.enableSubdomainAccess"
          [ngrxEnableFocusTracking]="true">
          <strong>Enable subdomain after pipeline finishes</strong>
        </mat-slide-toggle>
      </zef-form-field>

    </zui-form-section>
  }

</ng-container>
