import {
  Component,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ServiceStackTypes } from '@zerops/models/service-stack';
import { ServiceStackHorizontalAutoscaling, ServiceStackVerticalAutoscaling } from '@zerops/models/settings';
import { ZefFormElementDirective } from '@zerops/zef/forms';
import {
  CommonStandardServiceStackFormTranslations
} from './common-standard-service-stack-form.translations';

@Component({
  selector: 'z-common-standard-service-stack-form',
  templateUrl: './common-standard-service-stack-form.component.html',
  styleUrls: [ './common-standard-service-stack-form.component.scss' ]
})
export class CommonStandardServiceStackFormComponent {
  serviceStackTypes = ServiceStackTypes;

  @Input()
  state: any;

  @Input()
  isZen: boolean;

  @Input()
  serviceStackType: ServiceStackTypes;

  @Input()
  serviceStackTypeName: string;

  @Input()
  defaultHorizontalAutoscaling: ServiceStackHorizontalAutoscaling;

  @Input()
  defaultVerticalAutoscaling: ServiceStackVerticalAutoscaling;

  @Input()
  translations: CommonStandardServiceStackFormTranslations;

  @ViewChildren(ZefFormElementDirective)
  formElementsRefs: QueryList<ZefFormElementDirective>;
}
