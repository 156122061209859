@if (state?.controls) {
  <zef-form
    [state]="state"
    (submit)="submit.emit()">

    <zui-form-section class="__form-section" separator="none">

      <span *zuiFormSectionTitle>
        Retrigger the last pipeline
      </span>

      <div class="fx:pt-1">
        <zef-form-field class="__form-code-field">

          <zef-form-label><strong>Optional:</strong> Update secret environment variables for the next deploy (will upsert)</zef-form-label>

          <div class="fx:pb-0-5  fx:pt-0-25  fx:text-center">
            <button
              mat-flat-button
              type="button"
              (click)="prefillEnvs.emit()"
              class="c-button--smaller  __prefill-button">
              <mat-icon fontSet="material-icons-outlined">post_add</mat-icon>&nbsp;<span>Fill with the current secret variables</span>
            </button>
          </div>

          <zui-code-field
            class="__code-field"
            [editorOptions]="{
              language: 'ini',
              wordWrap: true
            }"
            [ngrxFormControlState]="state.controls.userDataEnvFile"
            [ngrxEnableFocusTracking]="true">
          </zui-code-field>

        </zef-form-field>
      </div>

    </zui-form-section>
  </zef-form>
}
