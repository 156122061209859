import { Component, ChangeDetectionStrategy, Input, forwardRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ZEROPS_HOURLY_RESOURCE_PRICES } from '@zerops/models/resource-statistics';
import { ServiceStackModes, ServiceStackTypes, SERVICE_STACK_AUTOSCALING_CONFIG } from '@zerops/models/service-stack';
import { ServiceStackCpuModes, ServiceStackHorizontalAutoscaling, ServiceStackVerticalAutoscaling } from '@zerops/models/settings';
import { AdjustAutoscalingOutput } from './autoscaling-form-field.model';
import { DynamicPopAnchorDirective } from '../dynamic-pop-anchor';
import { NouisliderComponent } from 'ng2-nouislider';
import clamp from 'lodash-es/clamp';
import { mergeTooltips } from './autoscaling-form-field.utils';

enum Modes {
  Add = 'add',
  Customization = 'customization'
}

enum ScalingTypes {
  Cpu = 'CPU',
  Disc = 'DISC',
  Ram = 'RAM',
  Container = 'CONTAINER'
}

interface ScalingDialogData {
  open: boolean;
  type: ScalingTypes;
  min: number;
  max: number;
  minFree: number;
  minFreePct: number;
  startCount?: number;
}

@Component({
  selector: 'zui-autoscaling-form-field',
  templateUrl: './autoscaling-form-field.component.html',
  styleUrls: [ './autoscaling-form-field.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoscalingFormFieldComponent),
      multi: true
    }
  ]
})
export class AutoscalingFormFieldComponent {

  modes = Modes;
  cpuModesConfirmTempVal: ServiceStackCpuModes;
  scalingDialogData: ScalingDialogData = {
    open: false,
    type: undefined,
    min: undefined,
    max: undefined,
    minFree: undefined,
    minFreePct: undefined,
    startCount: undefined
  };
  serviceStackTypes = ServiceStackTypes;
  serviceStackAutoscalingConfig = SERVICE_STACK_AUTOSCALING_CONFIG;
  scalingTypes = ScalingTypes;
  activeCurrency = { id: 'USD', symbol: '$', displaySymbolBefore: true, roundDigits: 2 };
  prices = ZEROPS_HOURLY_RESOURCE_PRICES;
  showAdvancedSettings = false;
  defaultMinMax = { defaultMin: 0, defaultMax: 0 };
  scalingModel: number[] = [ 0, 0 ];
  scalingModelSingle = 0;
  scalingTypeSuffixMap = {
    [ScalingTypes.Cpu]: 'Cores',
    [ScalingTypes.Disc]: 'GB',
    [ScalingTypes.Ram]: 'GB'
  };
  scalingButtonvalues = {
    [ScalingTypes.Container]: {
      buttons: [ 1 ]
    },
    [ScalingTypes.Cpu]: {
      buttons: [ 1 ]
    },
    [ScalingTypes.Disc]: {
      buttons: [ 0.5, 5 ]
    },
    [ScalingTypes.Ram]: {
      buttons: [ 0.125, 0.5 ]
    }
  };
  cpuFormatter = {
    to: (value: number) => {
      return (`${value} core`) + (value > 1 ? 's' : '')
    },

    from: (value: string) => {
      return parseFloat(value.split(' ')[0]);
    }
  };
  ramFormatter = {
    to: (value: number) => {
      const fixed3 = value.toFixed(3);
      const lastDigit = fixed3.charAt(fixed3.length - 1);

      const result = lastDigit === '0'
        ? value.toFixed(2)
        : value.toFixed(3);

      return `${result} GB`;
    },

    from: (value: string) => {
      return parseFloat(value.split(' ')[0]);
    }
  };
  containerFormatter = {
    to: (value: number) => {
      return (`${value} container`) + (value > 1 ? 's' : '')
    },

    from: (value: string) => {
      return parseFloat(value.split(' ')[0]);
    }
  };
  minToggleButtonValue: number = undefined;
  maxToggleButtonValue: number = undefined;

  @Input()
  containerLabel: string;

  @Input()
  singleMode = false;

  @Input()
  serviceStackType: ServiceStackTypes;

  @Input()
  defaultHorizontalAutoscaling: ServiceStackHorizontalAutoscaling;

  @Input()
  defaultVerticalAutoscaling: ServiceStackVerticalAutoscaling;

  @Input()
  set value(v) {
    this._value = { ...this.value, ...v };

    this._propagateChange(this.value);
  }

  get value() {
    return this._value;
  }

  @Input()
  set serviceStackMode(v: ServiceStackModes) {
    if (!!v && !!this.value) {
      this.setMode(v);
    }
  }

  @Input()
  mode: Modes = Modes.Add;

  @ViewChild(NouisliderComponent)
  noUiSliderRef: NouisliderComponent;

  @Output()
  adjustScaling = new EventEmitter<AdjustAutoscalingOutput>()

  private _value: any;

  writeValue(v: any) {
    if (v || (!v && this._value)) {
      this.value = v;
    }
  }

  registerOnChange(fn: any) {
    this._propagateChange = fn;
  }

  registerOnTouched(_: any) {
    return;
  }

  openScalingDialog(
    type: ScalingTypes,
    min: number,
    max: number,
    minFree?: number,
    minFreePct?: number,
    startCount?: number
  ) {

    this.setToggleButtonsValue(this.scalingButtonvalues[type].buttons[0]);

    if (this.defaultHorizontalAutoscaling || this.defaultVerticalAutoscaling) {
      this.defaultMinMax = this._getDefaultMinMax(type, this.defaultHorizontalAutoscaling, this.defaultVerticalAutoscaling);
    }

    // currently always hiding initially
    // this.showAdvancedSettings = minFree !== undefined || minFreePct !== undefined;
    this.showAdvancedSettings = false;

    this.scalingModelSingle = min;

    this.scalingModel = [
      min,
      max
    ];

    setTimeout(() => {
      if (this.noUiSliderRef) {
        mergeTooltips(this.noUiSliderRef.slider, 20, ' — ');
      }
    });

    this.scalingDialogData = {
      open: true,
      type,
      min,
      max,
      minFree,
      minFreePct,
      startCount
    };

  }

  closeScalingDialog() {
    this.scalingDialogData = {
      ...this.scalingDialogData,
      open: false
    };
  }

  setMode(mode: 'HA' | 'NON_HA', emit = false) {
    this.value = {
      ...this.value,
      mode
    };

    if (emit) {
      this.adjustScaling.emit(this.value);
    }
  }

  setCpuMode(cpuMode: ServiceStackCpuModes, emit = false) {
    this.value = {
      ...this.value,
      verticalAutoscaling: {
        ...this.value.verticalAutoscaling,
        cpuMode
      }
    };

    if (emit) {
      this.adjustScaling.emit(this.value);
    }
  }

  confirmCpuMode(cpuMode: ServiceStackCpuModes, dynamicPopRef: DynamicPopAnchorDirective) {
    this.cpuModesConfirmTempVal = cpuMode;
    dynamicPopRef.open();
  }

  setToHa() {
    this.value = {
      ...this.value,
      mode: 'HA',
      horizontalAutoscaling: {
        ...this.value.horizontalAutoscaling,
        minContainerCount: 2,
        maxContainerCount: this.value.horizontalAutoscaling?.maxContainerCount < 2 ? 2 : this.value.horizontalAutoscaling?.maxContainerCount
      }
    };

    if (this.mode === Modes.Customization) {
      this.adjustScaling.emit(this.value);
    }
  }

  applyScalingChanges() {
    if (this.scalingDialogData.type === ScalingTypes.Cpu) {
      this.value = {
        ...this.value,
        verticalAutoscaling: {
          ...this.value.verticalAutoscaling,
          minResource: {
            ...this.value.verticalAutoscaling?.minResource,
            cpuCoreCount: this._calculateRealResource(
              this.singleMode,
              this.scalingModel[0],
              this.scalingModel[1],
              this.scalingModelSingle,
              'min'
            )
          },
          maxResource: {
            ...this.value.verticalAutoscaling?.maxResource,
            cpuCoreCount: this._calculateRealResource(
              this.singleMode,
              this.scalingModel[0],
              this.scalingModel[1],
              this.scalingModelSingle,
              'max'
            )
          },
          minFreeResource: {
            ...this.value.verticalAutoscaling?.minFreeResource,
            ...(
              this.value.verticalAutoscaling?.cpuMode === 'DEDICATED'
                ? {
                cpuCoreCount: this.scalingDialogData.minFree !== null && this.scalingDialogData.minFree !== undefined
                  ? this.scalingDialogData.minFree / 100
                  : undefined,
                cpuCorePercent: this.scalingDialogData.minFreePct !== null && this.scalingDialogData.minFreePct !== undefined
                  ? this.scalingDialogData.minFreePct
                  : undefined
                }
                : {}
            )
          },
          startCpuCoreCount: this.scalingDialogData.startCount
            ? this.singleMode
              ? null
              : clamp(
                this.scalingDialogData.startCount,
                this.scalingModel[0],
                this.scalingModel[1]
              )
            : null
          },
      };
    }

    if (this.scalingDialogData.type === ScalingTypes.Ram) {

      this.value = {
        ...this.value,
        verticalAutoscaling: {
          ...this.value.verticalAutoscaling,
          minResource: {
            ...this.value.verticalAutoscaling?.minResource,
            memoryGBytes: this._calculateRealResource(
              this.singleMode,
              this.scalingModel[0],
              this.scalingModel[1],
              this.scalingModelSingle,
              'min'
            )
          },
          maxResource: {
            ...this.value.verticalAutoscaling?.maxResource,
            memoryGBytes: this._calculateRealResource(
              this.singleMode,
              this.scalingModel[0],
              this.scalingModel[1],
              this.scalingModelSingle,
              'max'
            )
          },
          minFreeResource: {
            ...this.value.verticalAutoscaling?.minFreeResource,
            memoryGBytes: this.scalingDialogData.minFree !== null && this.scalingDialogData.minFree !== undefined
              ? this.scalingDialogData.minFree
              : undefined,
            memoryPercent: this.scalingDialogData.minFreePct !== null && this.scalingDialogData.minFree !== undefined
              ? this.scalingDialogData.minFreePct
              : undefined
          }
        }
      };
    }

    if (this.scalingDialogData.type === ScalingTypes.Disc) {
      this.value = {
        ...this.value,
        verticalAutoscaling: {
          ...this.value.verticalAutoscaling,
          minResource: {
            ...this.value.verticalAutoscaling?.minResource,
            diskGBytes: this._calculateRealResource(
              this.singleMode,
              this.scalingDialogData.min,
              this.scalingDialogData.max,
              this.scalingDialogData.max,
              'min'
            )
          },
          maxResource: {
            ...this.value.verticalAutoscaling?.maxResource,
            diskGBytes: this._calculateRealResource(
              this.singleMode,
              this.scalingDialogData.min,
              this.scalingDialogData.max,
              this.scalingDialogData.max,
              'max'
            )
          }
        }
      };
    }

    if (this.scalingDialogData.type === ScalingTypes.Container) {
      this.value = {
        ...this.value,
        horizontalAutoscaling: {
          minContainerCount: this.scalingModel[0],
          maxContainerCount: this.scalingModel[1]
        }
      };
    }

    this.adjustScaling.emit(this.value);
    this.closeScalingDialog();

  }

  setToggleButtonsValue(value: number) {
    this.minToggleButtonValue = value;
    this.maxToggleButtonValue = value;
  }

  private _propagateChange = (_: any) => {
    return;
  };

  private _getDefaultMinMax(
    type: ScalingTypes,
    defaultHorizontalAutoscaling: ServiceStackHorizontalAutoscaling,
    defaultVerticalAutoscaling: ServiceStackVerticalAutoscaling
  ): { defaultMin: number; defaultMax: number; } {

    switch (type) {
      case ScalingTypes.Container:
        return { defaultMin: defaultHorizontalAutoscaling.minContainerCount, defaultMax: defaultHorizontalAutoscaling.maxContainerCount };

      case ScalingTypes.Cpu: {
        const defaultMin = defaultVerticalAutoscaling.minResource.cpuCoreCount;
        const defaultMax = defaultVerticalAutoscaling.maxResource.cpuCoreCount

        return { defaultMin, defaultMax };
      }
      case ScalingTypes.Ram: {
        const defaultMin = defaultVerticalAutoscaling.minResource.memoryGBytes;
        const defaultMax = defaultVerticalAutoscaling.maxResource.memoryGBytes;

        return { defaultMin, defaultMax };
      }
      case ScalingTypes.Disc:
        return { defaultMin: defaultVerticalAutoscaling.minResource.diskGBytes, defaultMax: defaultVerticalAutoscaling.maxResource.diskGBytes };
      default:
        return { defaultMin: 0, defaultMax: 0 };
    }

  }

  private _calculateRealResource(
    isSingle: boolean,
    min: number,
    max: number,
    single: number,
    type: 'min' | 'max'
  ) {
    if (isSingle) {
      return single;
    } else {
      return type === 'min' ? min : max;
    }
  }

}
