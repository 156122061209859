import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import {
  FormControlState,
  FormGroupState,
  FormState,
  disable,
  enable,
  setErrors,
  setValue,
  validate
} from 'ngrx-forms';
import { FEATURE_NAME } from './pipeline-trigger-dialog.constant';
import { required, requiredTrue } from 'ngrx-forms/validation';

export interface PipelineTriggerFormValue {
  buildFromGit?: string;
  buildFromGitSetup?: string;
  buildFromGitYaml?: string;
  zeropsYaml?: string;
  buildFromGitToggle?: boolean;
  zeropsYamlToggle?: boolean;
  userDataEnvFile?: string;
  appVersionId?: string;
}

export type PipelineTriggerFormState = FormState<PipelineTriggerFormValue>;

const makeRequiredWhenNothingIsToggled = (s: FormControlState<boolean>, ps: FormGroupState<PipelineTriggerFormValue>) => {
  if (ps?.controls?.buildFromGitToggle.value === false && ps?.controls?.zeropsYamlToggle.value === false) {
    return enable(validate(s, requiredTrue));
  }
};

@Injectable({ providedIn: 'root' })
export class PipelineTriggerForm extends ZefForm<PipelineTriggerFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        buildFromGit: '',
        buildFromGitSetup: '',
        buildFromGitYaml: '',
        zeropsYaml: '',
        buildFromGitToggle: false,
        zeropsYamlToggle: false,
        userDataEnvFile: ''
      },
      {
        buildFromGit: (s: FormControlState<string>, ps: FormGroupState<PipelineTriggerFormValue>) => {
          if (ps?.controls?.buildFromGitToggle.value === true) {
            return validate(s, required);
          }

          return setErrors(s, {});
        },
        zeropsYaml: (s: FormControlState<string>, ps: FormGroupState<PipelineTriggerFormValue>) => {
          if (ps?.controls?.zeropsYamlToggle.value === true) {
            return validate(s, required);
          }
          return setErrors(s, {});
        },
        buildFromGitToggle: (s: FormControlState<boolean>, ps: FormGroupState<PipelineTriggerFormValue>) => {
          // make required when nothing is toggled
          const baseValidation = makeRequiredWhenNothingIsToggled(s, ps);
          if (!!baseValidation) { return baseValidation; }

          if (ps?.controls?.zeropsYamlToggle.value === true) {
            return setErrors(setValue(disable(s), false), {});
          } else if (s.isDisabled) {
            return setErrors(enable(s), {});
          }

          return setErrors(s, {});
        },
        zeropsYamlToggle: (s: FormControlState<boolean>, ps: FormGroupState<PipelineTriggerFormValue>) => {
          // make required when nothing is toggled
          const baseValidation = makeRequiredWhenNothingIsToggled(s, ps);
          if (!!baseValidation) { return baseValidation; }

          if (ps?.controls?.buildFromGitToggle.value === true) {
            return setErrors(setValue(disable(s), false), {});
          } else if (s.isDisabled) {
            return setErrors(enable(s), {});
          }

          return setErrors(s, {});
        }
      }
    );
  }
}
