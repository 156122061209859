import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { firstAvailable } from '@zerops/zef/core';
import { zefDialogClose } from '@zerops/zef/dialog';
import {
  onWebsocketMessageDispatchAddRemoveEntities,
  onWebsocketMessageDispatchUpdateEntities
} from '@zerops/zef/entities';
import { ZefSnackService } from '@zerops/zef/snack';
import { ADDON_ACTIVATION_DIALOG_FEATURE_NAME } from '@zerops/zerops/feature/addon-activation-dialog';
import { of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  switchMap
} from 'rxjs/operators';
import { billingInfo } from '../client-base';
import { PaymentEntity } from '../payment-base';
import { loadCountryList } from '../settings-base';
import { UserEntity } from '../user-base';
import {
  availableAddons,
  BillingBaseActionUnion,
  paymentRequest, paymentRequestFail,
  paymentRequestSuccess,
  paymentSources,
  paymentSourcesFail,
  paymentSourcesSuccess,
  availableAddonsFail,
  availableAddonsSuccess,
  removePaymentSource,
  removePaymentSourceSuccess,
  removePaymentSourceFail,
  modifyAddon,
  modifyAddonSuccess,
  modifyAddonFail,
  confirmPayment,
  confirmPaymentCancel,
  autoChargeUpdate,
  autoChargeUpdateSuccess,
  autoChargeUpdateFail
} from './billing-base.action';
import { BillingBaseApi } from './billing-base.api';
import { zefRemoveProgress } from '@zerops/zef/progress';
import { zefRemoveError } from '@zerops/zef/errors';

@Injectable()
export class BillingBaseEffect {
  // # Deps
  #snack = inject(ZefSnackService);
  #userEntity = inject(UserEntity);
  #paymentEntity = inject(PaymentEntity);
  #actions$ = inject<Actions<BillingBaseActionUnion>>(Actions);
  #api = inject(BillingBaseApi);

  // # Streams
  #activeClientId$ = this.#userEntity.activeClientId$.pipe(filter((d) => !!d));
  #onModifyAddonSuccess$ = this.#actions$.pipe(ofType(modifyAddonSuccess));

  onPaymentRequestInfo$ = createEffect(() => this.#actions$.pipe(
    ofType(paymentRequest),
    switchMap((action) => this.#userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this.#api
        .paymentRequest$(
          action.data,
          clientId
        )
        .pipe(
          map((res) => paymentRequestSuccess(res, action)),
          catchError((err) => of(paymentRequestFail(err, action)))
        )
      )
    )
  )));

  onPaymentRequestInfoFailCancelConfirmPayment$ = createEffect(() => this.#actions$.pipe(
    ofType(paymentRequestFail),
    map(() => confirmPaymentCancel())
  ));

  onConfirmPaymentCancel$ = createEffect(() => this.#actions$.pipe(
    ofType(confirmPaymentCancel),
    delay(0),
    mergeMap(() => [
      zefRemoveError(confirmPayment.type),
      zefRemoveProgress(confirmPayment.type)
    ])
  ));

  onRemovePaymentSource$ = createEffect(() => this.#actions$.pipe(
    ofType(removePaymentSource),
    switchMap((action) => this.#userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this.#api
        .removePaymentSource$(clientId, action.data)
        .pipe(
          map((res) => removePaymentSourceSuccess(res, action)),
          catchError((err) => of(removePaymentSourceFail(err, action)))
        )
      )
    ))
  ));

  onRemovePaymentSourceSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    ofType(removePaymentSourceSuccess),
    switchMap(() => this.#snack.success$({ text: 'Card was removed successfully' }))
  ), { dispatch: false });

  onPaymentSources$ = createEffect(() => this.#actions$.pipe(
    ofType(paymentSources),
    switchMap((action) => this.#userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this.#api
        .paymentSources$(clientId)
        .pipe(
          map((res) => paymentSourcesSuccess(res, action)),
          catchError((err) => of(paymentSourcesFail(err, action)))
        )
      )
    )
  )));

  onActiveClientIdLoadAvailableAddons$ = createEffect(() => this.#activeClientId$.pipe(
     map(() => availableAddons())
  ));

  onAvailableAddonsRequest$ = createEffect(() => this.#actions$.pipe(
    ofType(availableAddons),
    switchMap((action) => this.#userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this.#api
        .availableAddons$(clientId)
        .pipe(
          map((res) => availableAddonsSuccess(res, action)),
          catchError((err) => of(availableAddonsFail(err, action)))
        )
      )
    )
  )));

  onModifyAddon$ = createEffect(() => this.#actions$.pipe(
    ofType(modifyAddon),
    switchMap((action) => this.#userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this.#api
        .modifyAddon$(action.data?.id, clientId, action.data?.recurringEnabled, action.data?.enabled)
        .pipe(
          map((res) => modifyAddonSuccess(res, action)),
          catchError((err) => of(modifyAddonFail(err, action)))
        )
      )
    )
  )));

  onUpdateAutocharge$ = createEffect(() => this.#actions$.pipe(
    ofType(autoChargeUpdate),
    switchMap((action) => this.#userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((clientId) => this.#api
        .autoChargeUpdate$(clientId, action.data)
        .pipe(
          map((res) => autoChargeUpdateSuccess(res, action)),
          catchError((err) => of(autoChargeUpdateFail(err, action)))
        )
      )
    )
  )));

  onUpdateAutochargeSuccessNotification$ = createEffect(() => this.#actions$.pipe(
    ofType(autoChargeUpdateSuccess),
    switchMap(() => this.#snack.success$({ text: `Recurring Top-up configuration updated` }))
  ), { dispatch: false });

  onModifyAddonSuccessLoadAvailableAddons$ = createEffect(() => this.#onModifyAddonSuccess$.pipe(
    map(() => availableAddons())
  ));

  onModifyAddonSuccessCloseDialog$ = createEffect(() => this.#onModifyAddonSuccess$.pipe(
    map(() => zefDialogClose({ key: ADDON_ACTIVATION_DIALOG_FEATURE_NAME }))
  ));

  onActiveClientIdLoadCountryList$ = createEffect(() => this.#activeClientId$.pipe(
    map(() => loadCountryList())
  ));

  onActiveClientIdLoadBillingInfo$ = createEffect(() => this.#activeClientId$.pipe(
    map(() => billingInfo())
  ));

  // payment list / update
  setupPaymentListStream$ = createEffect(() => this.#activeClientId$.pipe(
    map((clientId) => this.#paymentEntity.listSubscribe(
      clientId
    ))
  ));

  onPaymentAddRemoveMessage$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(
      this.#paymentEntity
    )
  ));

  setupUpdateStreamSubscription$ = createEffect(() => this.#activeClientId$.pipe(
    map((clientId) => this.#paymentEntity.updateSubscribe(clientId)
  )));

  onUpdateStreamMessage$ = createEffect(() => this.#actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this.#paymentEntity)
  ));

}
