/**
 * Transforms all empty string values in an object to null values.
 * Works recursively for nested objects and arrays.
 *
 * Claude.ai wrote this function.
 *
 * @param data The object to transform
 * @returns A new object with empty strings replaced with null
 */
export function replaceEmptyStringsWithNull<T>(data: T): T {
  // Handle null/undefined case
  if (data === null || data === undefined) {
    return data;
  }

  // Handle array case
  if (Array.isArray(data)) {
    return data.map(item => replaceEmptyStringsWithNull(item)) as unknown as T;
  }

  // Handle object case
  if (typeof data === 'object') {
    const result = {} as T;
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        result[key] = replaceEmptyStringsWithNull(data[key]);
      }
    }
    return result;
  }

  // Handle string case
  if (typeof data === 'string' && data.trim() === '') {
    return null as unknown as T;
  }

  // Return as is for other primitive types
  return data;
}
