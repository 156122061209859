import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ZefFormBase, ZefFormsModule } from '@zerops/zef/forms';
import { PipelineRetriggerFormState } from '../pipeline-retrigger-dialog.form';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { AppVersion } from '@zerops/models/app-version';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'z-pipeline-retrigger-form',
  templateUrl: './pipeline-retrigger-form.component.html',
  styleUrl: './pipeline-retrigger-form.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ZefFormsModule,
    MaterialFormStackModule,
    FormSectionModule,
    CodeFieldModule,
    MatButtonModule,
    MatIconModule,
  ]
})
export class PipelineRetriggerFormComponent extends ZefFormBase<PipelineRetriggerFormState> {
  activeAppVersion = input<AppVersion>();
  prefillEnvs = output();
}
