<div
  class="__wrap"
  [class.is-inactive]="serviceStackStatus === serviceStackStatuses.Stopped || !(((!!path && isSynced && dnsCheckStatus === dnsCheckStatuses.Ok && (!sslEnabled || (sslEnabled && activeSsl))) || (routingType === 'port' && isSynced))) && !disableLink">

  <div class="__grid">

    <div
      [class.u-text--left]="pathTextAlign === 'left'"
      [class.u-text--right]="pathTextAlign === 'right'">

      <zui-status-icon-base
        *ngIf="!disableLink"
        [tooltip]="serviceStackStatus === serviceStackStatuses.Stopped ? 'Service is stopped' : ((((!!path && isSynced && dnsCheckStatus === dnsCheckStatuses.Ok && (!sslEnabled || (sslEnabled && activeSsl))) || (routingType === 'port' && isSynced))) && !disableLink)
          ? 'Active'
          : (((routingType === 'port' && !isSynced) || (path && dnsCheckStatus === dnsCheckStatuses.Ok && !isSynced))
            ? 'Out of sync'
            : (path && dnsCheckStatus !== dnsCheckStatuses.Ok && isSynced)
              ? 'Not correctly routed yet'
              : (path && dnsCheckStatus === dnsCheckStatuses.Ok && isSynced && sslEnabled && !activeSsl)
                ? 'SSL is not active'
                : 'Out of sync and not correctly routed yet')"
        [status]="serviceStackStatus === serviceStackStatuses.Stopped ? 'STOPPED' : ((((!!path && isSynced && dnsCheckStatus === dnsCheckStatuses.Ok && (!sslEnabled || (sslEnabled && activeSsl))) || (routingType === 'port' && isSynced))) && !disableLink)
          ? 'ACTIVE'
          : (((routingType === 'port' && !isSynced) || (path && dnsCheckStatus === dnsCheckStatuses.Ok && !isSynced))
            ? 'UNSYNCED'
            : (path && dnsCheckStatus !== dnsCheckStatuses.Ok && isSynced)
              ? 'NOT_ROUTED'
              : (path && dnsCheckStatus === dnsCheckStatuses.Ok && isSynced && sslEnabled && !activeSsl)
                ? 'SSL_NOT_ACTIVE'
                : 'UNSYNCED_SSL_NOT_ACTIVE')"
        class="__icon-base"
        [size]="12">
      </zui-status-icon-base>

      <div class="__path-wrap c-routing-location-item-path-wrap">

        <zef-copy-to-clipboard
          *ngIf="((((!!path && isSynced && dnsCheckStatus === dnsCheckStatuses.Ok && (!sslEnabled || (sslEnabled && activeSsl)))
            || routingType === 'port' && isSynced))) && serviceStackStatus !== serviceStackStatuses.Stopped && !disableLink; else noLinkPathRef"
          class="__copy  c-routing-location-item-copy"
          [copyData]="(!!path && routingType === 'http') ? safeHrefRaw : path"
          [tooltip]="copyToClipboardTooltipTranslation">
          <a
            class="__path is-link  c-routing-location-item-path"
            [href]="safeHref"
            target="_blank">
            {{ (!!path && routingType === 'http') ? (safeHrefRaw | middleEllipsis : 27) : (path | middleEllipsis : 27) }}
          </a>
        </zef-copy-to-clipboard>

        <ng-template #noLinkPathRef>
          <span class="__path  c-routing-location-item-path">
            <span class="__path_domain-placeholder" [innerHTML]="safeDomainPlaceholder" *ngIf="showDomainPlaceholder"></span>
            <span [class.__path_path]="showDomainPlaceholder">
              {{ (!!path && routingType === 'http') ? (safeHrefRaw | middleEllipsis : 27) : (path | middleEllipsis : 27) }}
            </span>
          </span>
        </ng-template>


      </div>

    </div>

    <ng-container *ngIf="port">

      <div class="u-text--center  c-routing-location-item-arrow">
        <mat-icon class="__icon">arrow_right_alt</mat-icon>
      </div>

      <div
        class="c-routing-location-item-port"
        [class.u-text--left]="portTextAlign === 'left'"
        [class.u-text--right]="portTextAlign === 'right'">
        <span
          [matTooltip]="serviceStackTypeFullname"
          [matTooltipDisabled]="!serviceStackTypeFullname"
          class="__port">

          <ng-container *ngIf="type === 'other' && !!serviceStackName && !!serviceStackId">
            <a class="c-internal-link" [routerLink]="[ '/service-stack', serviceStackId, 'routing' ]">{{ serviceStackName | middleEllipsis : 11 }}</a>:<!--
       --></ng-container><!--

       --><ng-container *ngIf="type !== 'other' && !serviceStackName">{{ internalProtocol + '://:'}}</ng-container><!--

       --><ng-container *ngIf="type === 'stack' && !!serviceStackName">{{ serviceStackName | middleEllipsis : 11 }}:</ng-container><!--

       -->{{ port }}

        </span>
      </div>

    </ng-container>

  </div>
</div>
