import { Component, ChangeDetectionStrategy, Input, ViewChildren, QueryList, viewChild } from '@angular/core';
import { ServiceStackTypes } from '@zerops/models/service-stack';
import { ServiceStackHorizontalAutoscaling, ServiceStackVerticalAutoscaling } from '@zerops/models/settings';
import { CommonUserServiceStackFormTranslations } from './common-user-service-stack-form.translations';
import { ZefFormElementDirective } from '@zerops/zef/forms';
import { CodeFieldComponent } from '@zerops/zui/code-field/code-field.component';
import { basicZeropsYmlOutline } from '@zerops/zef/core';

@Component({
  selector: 'z-common-user-service-stack-form',
  templateUrl: './common-user-service-stack-form.component.html',
  styleUrls: [ './common-user-service-stack-form.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonUserServiceStackFormComponent {

  serviceStackTypes = ServiceStackTypes;

  @Input()
  state: any;

  @Input()
  projectName: string;

  @Input()
  isZen: boolean;

  @Input()
  serviceStackType: ServiceStackTypes;

  @Input()
  serviceStackTypeVersionName: string;

  @Input()
  serviceStackTypeName: string;

  @Input()
  defaultHorizontalAutoscaling: ServiceStackHorizontalAutoscaling;

  @Input()
  defaultVerticalAutoscaling: ServiceStackVerticalAutoscaling;

  @Input()
  translations: CommonUserServiceStackFormTranslations;

  @ViewChildren(ZefFormElementDirective)
  formElementsRefs: QueryList<ZefFormElementDirective>;

  zeropsSetupCodeFieldRef = viewChild<CodeFieldComponent>('zeropsSetupCodeFieldRef');

  prefillZeropsSetup() {
    this.zeropsSetupCodeFieldRef().writeValue(basicZeropsYmlOutline(
      this.state?.value?.name,
      this.serviceStackTypeVersionName
    ));
  }
}
