import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { SatPopover } from '@zerops/zef/popover';

@Component({
  selector: 'zui-confirm-pop',
  templateUrl: './confirm-pop.component.html',
  styleUrls: [ './confirm-pop.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmPopComponent {

  @Input()
  confirmButtonText: string;

  @Input()
  confirmButtonIcon: string;

  @Input()
  confirmColor = 'accent';

  @Input()
  closeButtonText: string;

  @Input()
  heading: string;

  @Input()
  subtitle: string;

  @Input()
  verticalAlign = 'below';

  @Input()
  horizontalAlign = 'center';

  @Output()
  confirm = new EventEmitter<void>();

  @Output()
  closeTrigger = new EventEmitter<void>();

  @ViewChild('popRef', { static: true })
  popRef: SatPopover;
}
