import { ApplicationRef, enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from './environments/environment';
import { appConfig, AppContainer } from './modules/app';

interface IWindow {
  ngRef: ApplicationRef;
}

if (environment.production) {
  enableProdMode();
}

window.MonacoEnvironment = {
  getWorkerUrl: function (_, label) {
    if (label === 'yaml') {
      return './assets/monaco-workers/yaml.worker.bundle.js';
    }
    if (label === 'json') {
      return './assets/monaco-workers/json.worker.bundle.js';
    }
    return './assets/monaco-workers/editor.worker.bundle.js';
  }
};

bootstrapApplication(AppContainer, appConfig)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    const nwWindow = (window as unknown) as IWindow;
    if (nwWindow.ngRef) {
      nwWindow.ngRef?.destroy();
    }
    nwWindow.ngRef = ref;
  })
  .catch((err) =>
    // Otherwise, log the boot error
    console.error(err)
  );
