<zef-form
  [autoScroll]="autoscroll"
  [state]="state"
  (submit)="submit.emit()">

  <zui-form-section
    class="__based-form-section"
    [class.is-filled]="!!state?.controls.invoiceAddressCountryId.value"
    separator="none">

    <span *zuiFormSectionTitle>
      Where are you based?
    </span>

    <span *zuiFormSectionDesc>
      We need this information to determine if<br/> Value Added Tax (VAT) applies to your transactions.
    </span>

    <zef-form-field class="__country-form-field" last>
      <mat-form-field class="__country-mat-form-field" color="accent">

        <mat-select
          class="__country"
          [placeholder]="'Select country'"
          [ngrxEnableFocusTracking]="true"
          [ngrxFormControlState]="state?.controls.invoiceAddressCountryId">

          <mat-option
            [value]="item.id"
            *ngFor="let item of countryList">
            {{ item.name }}
          </mat-option>
        </mat-select>

      </mat-form-field>
    </zef-form-field>

  </zui-form-section>

  @if (!!state?.controls.invoiceAddressCountryId.value) {

    <zui-form-section separator="top">

      <span *zuiFormSectionTitle>
        How much Zerops credit do you need?
      </span>

      <span *zuiFormSectionDesc>
        @if (_countryEuMap[state?.controls.invoiceAddressCountryId.value]) {
          Since you are from the EU, we won't require any additional <br />information for transactions under $350.
        }
      </span>

      <ng-content></ng-content>

    </zui-form-section>

    <div
      class="__additional-info"
      [class.is-outside-eu-or-limit]="!_countryEuMap[state?.controls.invoiceAddressCountryId.value] || amount > 350">

      <!-- countries outside EU and transaction over 350 are required to include billing details -->
      @if (_countryEuMap[state?.controls.invoiceAddressCountryId.value] && amount < 350) {
        <zef-form-field>
          <mat-slide-toggle
            class="__slide"
            [ngrxEnableFocusTracking]="true"
            [ngrxFormControlState]="state?.controls.vatPayer">
            I'm a VAT payer or require my information on the invoice
          </mat-slide-toggle>
        </zef-form-field>
      }

      @if (!!state?.controls.vatPayer.value || !_countryEuMap[state?.controls.invoiceAddressCountryId.value] || (_countryEuMap[state?.controls.invoiceAddressCountryId.value] && amount > 350)) {

        <zui-form-section [separator]="(!_countryEuMap[state?.controls.invoiceAddressCountryId.value] || amount > 350) ? 'top' : 'none'">

          @if (!_countryEuMap[state?.controls.invoiceAddressCountryId.value] || (_countryEuMap[state?.controls.invoiceAddressCountryId.value] && amount > 350)) {

            <span *zuiFormSectionTitle>
              To whom should we address the invoice?
            </span>

            <span *zuiFormSectionDesc>
              We are legally required to collect your invoicing information, <br/>please fill in your data truthfuly
            </span>

          }

          <div [class.__addition-info-fields]="_countryEuMap[state?.controls.invoiceAddressCountryId.value] && amount < 350">

            <!-- company name -->
            <zef-form-field>
              <mat-form-field color="accent">

                <mat-label>Company name or your name</mat-label>

                <input
                  matInput
                  type="text"
                  [ngrxEnableFocusTracking]="true"
                  [ngrxFormControlState]="state?.controls.companyName"
                />

              </mat-form-field>
            </zef-form-field>

            <!-- CIN -->
            <zef-form-field>
              <mat-form-field color="accent">

                <mat-label>IN / Company identification number <strong>(optional)</strong></mat-label>

                <input
                  matInput
                  type="text"
                  [ngrxEnableFocusTracking]="true"
                  [ngrxFormControlState]="state?.controls.companyNumber"
                />

              </mat-form-field>
            </zef-form-field>

            <!-- city -->
            <zef-form-field>
              <mat-form-field color="accent">

                <mat-label>City</mat-label>

                <input
                  matInput
                  type="text"
                  [ngrxEnableFocusTracking]="true"
                  [ngrxFormControlState]="state?.controls.invoiceAddressCity"
                />

              </mat-form-field>
            </zef-form-field>

            <!-- street -->
            <zef-form-field>
              <mat-form-field color="accent">

                <mat-label>Street</mat-label>

                <input
                  matInput
                  type="text"
                  [ngrxEnableFocusTracking]="true"
                  [ngrxFormControlState]="state?.controls.invoiceAddressStreet"
                />

              </mat-form-field>
            </zef-form-field>

            <!-- post code -->
            <zef-form-field last>
              <mat-form-field color="accent">

                <mat-label>Post code</mat-label>

                <input
                  matInput
                  type="text"
                  [ngrxEnableFocusTracking]="true"
                  [ngrxFormControlState]="state?.controls.invoiceAddressPostcode"
                />

              </mat-form-field>
            </zef-form-field>

          </div>

        </zui-form-section>

        @if (_countryEuMap[state?.controls.invoiceAddressCountryId.value] || (_countryEuMap[state?.controls.invoiceAddressCountryId.value] && amount < 350)) {
          <zui-form-section separator="top">

            <span *zuiFormSectionTitle>
              Are you a VAT payer?
            </span>

            <span *zuiFormSectionDesc>
              @if (state?.controls.invoiceAddressCountryId.value !== 'CZ') {
                Your VAT number will be checked against VIES <br/>and if valid no value added tax will be added.
              }
            </span>

            <zef-form-field>
              <mat-form-field class="__vat-form-field" color="accent">

                <mat-label>VAT number</mat-label>

                <input
                  matInput
                  type="text"
                  [ngrxEnableFocusTracking]="true"
                  [ngrxFormControlState]="state?.controls.vatNumber"
                />

              </mat-form-field>
            </zef-form-field>

          </zui-form-section>
        }

      }

    </div>
  }

</zef-form>
