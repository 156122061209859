export const PHOENIX_IMPORT_YAML = `#yamlPreprocessor=on
project:
  name: recipe-phoenix
  tags:
    - zerops-recipe

services:
  - hostname: app
    type: elixir@1.16
    buildFromGit: https://github.com/zeropsio/recipe-phoenix
    enableSubdomainAccess: true
    envSecrets:
      SECRET_KEY_BASE: <@generateRandomString(<64>)>
    verticalAutoscaling:
      minRam: 0.25
      minFreeRamGB: 0.125

  - hostname: db
    type: postgresql@16
    mode: NON_HA
`;
