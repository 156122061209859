import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentBaseApi {

  constructor(private _http: HttpClient) {}

  downloadCreditConsumption$(clientId: string, month: string): Observable<{
    file: ArrayBuffer,
    contentType: string,
    contentDisposition: string
  }> {
    return this._http.post(
      `/api/billing/client/${clientId}/credit-export`,
      { month },
      { responseType: 'arraybuffer', observe: 'response' }
    ).pipe(
      map((data) => ({
        file: data.body,
        contentType: data.headers.get('Content-Type'),
        contentDisposition: data.headers.get('Content-Disposition')
      }))
    );
  }

}
