<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxWidth: '440px', width: '100%' }"
  (closeTrigger)="onClose$.next()">
  <div class="fx:relative">
    @if (!state.billingInfo?.current?.invoiceAddressCountryId) {

      <zui-form-section separator="none">
        <span *zuiFormSectionTitle>
          Recurring Top-up Configuration
        </span>
      </zui-form-section>

      <div class="fx:pt-0-5">
        <button
          mat-stroked-button
          color="accent"
          type="button"
          class="__billing-info-setup-button  c-button--full"
          [zefNgrxDialogOpen]="billingInfoEditDialogKey"
          [zefNgrxDialogOpenMeta]="{
            firstAddition: true
          }">
          <mat-icon fontSet="material-icons-outlined">assignment_late</mat-icon>&nbsp;Full billing info required first, complete now?
        </button>
      </div>

      <button
        type="button"
        class="__dialog-close-button"
        mat-icon-button
        (click)="onClose$.next()">
        <mat-icon>close</mat-icon>
      </button>

    } @else {

      <zef-scrollbar
        class="__scroll"
        maxHeight="calc(100vh - 140px)"
        height="auto">
        <div class="__content">
          <z-recurring-top-up-config-form
            (submit)="onSubmit$.next()"
            [state]="state.formState" />
        </div>
      </zef-scrollbar>

      <div class="__buttons">
        <div class="__buttons-grid">

          <div>
            <zef-progress-error full [key]="autoChargeUpdateKey">
              <button
                class="c-button--full"
                color="accent"
                mat-raised-button
                type="button"
                (click)="topUpFormRef()?.formRef?.triggerSubmit()">
                Update configuration
              </button>
            </zef-progress-error>
          </div>

          <div>
            <button
              (click)="onClose$.next()"
              class="c-button--full"
              type="button"
              mat-button>
              <span transloco="general.close"></span>
            </button>
          </div>

        </div>
      </div>

    }
  </div>
</zef-dialog>
