import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { RecurringTopUpConfigDialogFeature } from './recurring-top-up-config-dialog.feature';
import { RecurringPaymentConfigDialogEffect } from './recurring-top-up-config-dialog.effect';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { RecurringTopUpConfigFormComponent } from './components/recurring-top-up-config-form.component';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { TranslocoModule } from '@ngneat/transloco';
import { FormSectionModule } from '@zerops/zui/form-section';

@NgModule({
  declarations: [ RecurringTopUpConfigDialogFeature ],
  imports: [
    CommonModule,
    MaterialStackModule,
    EffectsModule.forFeature([ RecurringPaymentConfigDialogEffect ]),
    ZefDialogModule,
    ZuiSeparatorComponent,
    ZefScrollbarComponent,
    RecurringTopUpConfigFormComponent,
    ZefProgressErrorModule,
    TranslocoModule,
    FormSectionModule
  ],
  exports: [ RecurringTopUpConfigDialogFeature ]
})
export class RecurringTopUpConfigDialogModule {

}
