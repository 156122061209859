import { NgModule } from '@angular/core';
import { ZefTranslationsService } from '@zerops/zef/translations';
import { FEATURE_NAME } from './http-routing-item.constant';

export enum DnsCheckStatuses {
  Pending = 'PENDING',
  Checking = 'CHECKING',
  Ok = 'OK',
  Failed = 'FAILED',
  Ignored = 'IGNORED'
}

const en = {
  statuses: {
    SYNCED: 'Synchronized',
    UNSYNCED: 'Not synchronized',
    BEING_DELETED: 'Being deleted & Not synchronized'
  },
  sslEnabled: 'Automatic SSL Installation on All Domains Enabled.',
  sslDisabled: 'Automatic SSL Installation NOT Enabled.',
  location: 'Location',
  onOtherServiceStacks: 'on other Services …',
  sslStatuses: {
    INACTIVE: 'Not Enabled',
    BEING_INSTALLED: 'Installing certificate',
    WAITING_FOR_DNS: 'Waiting for DNS',
    ACTIVE: 'Active',
    INSTALLATION_FAILED: 'Installation Failed'
  },
  cdnStatuses: {
    DISABLED: 'Disabled',
    BEING_INSTALLED: 'Enabling',
    ACTIVE: 'Active',
    WAITING_FOR_DNS: 'Waiting for DNS',
    BEING_DISABLED: 'Disabling'
  },
  dnsStatuses: {
    PENDING: 'Check Pending',
    CHECKING: 'Lookup Running',
    OK: 'OK',
    FAILED: 'Not pointing to project\'s IP',
    IGNORE: 'Check Ignored'
  }
};

export type HttpRoutingItemTranslations = typeof en;

@NgModule()
export class HttpRoutingItemTranslationsModule {
  constructor(private _translationsService: ZefTranslationsService) {
    this._translationsService.setTranslations(FEATURE_NAME, { en });
  }
}
