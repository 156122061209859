<zef-form-field *ngIf="mode === HttpRoutingFieldsModes.Normal">
  <zef-autocomplete-tag-field
    [showPanel]="false"
    [hint]="translations.hostHint"
    [label]="translations.hostLabel"
    [disableAutocomplete]="true"
    [blacklist]="domainsBlacklist"
    (optionAdded)="domainSelected.emit($event)"
    (blacklisted)="domainBlacklisted.emit($event)"
    [tagSearchPlaceholder]="translations.hostPlaceholder"
    [ngrxFormControlState]="domainsControl">
  </zef-autocomplete-tag-field>
</zef-form-field>

<div class="fx:pb-1">

  <zef-form-field appearance="slide-toggle" *ngIf="mode === HttpRoutingFieldsModes.Normal">
    <div class="__ssl-toggle-wrap">
      <mat-slide-toggle
        [ngrxFormControlState]="sslControl"
        class="__ssl-toggle">
        Automatically install SSL Certificates
      </mat-slide-toggle>
    </div>
  </zef-form-field>

  <zef-form-field appearance="slide-toggle" *ngIf="mode === HttpRoutingFieldsModes.Normal">

    <div class="__cdn-toggle-wrap">
      <mat-slide-toggle
        [ngrxFormControlState]="cdnControl"
        class="__cdn-toggle">
        Enable CDN for static files
      </mat-slide-toggle>
    </div>

    <zef-form-hints class="__cdn-toggle-hint">
      <span>staticCdnUrl</span> environment varible will be added on project level, use it as path for your static files, e.g. <span [innerHTML]="'${staticCdnUrl}/example.com/uploads/image.png'"></span>
    </zef-form-hints>

  </zef-form-field>

</div>

<span class="__location-control-label">Location rules</span>

<div
  class="__location-control"
  *ngFor="let control of locationsControl;
    trackBy: trackBy;
    let index = index;">

    <div class="__location-control-grid">

      <div class="__location-control-grid-item">
        <zef-form-field>
          <mat-form-field
            color="accent"
            class="__location-form-field">
            <mat-label>Public Path</mat-label>

            <span matPrefix>{{ '{domain}' }}</span>

            <input
              matInput
              zuiAutofocus
              [ngrxFormControlState]="control.controls.path"
              [ngrxEnableFocusTracking]="true"
              type="text"
            />
          </mat-form-field>
        </zef-form-field>
      </div>

      <div class="u-text--center">
        <mat-icon class="__location-arrow-icon">arrow_right_alt</mat-icon>
      </div>

      <div class="__location-control-grid-item">
        <div class="__port-wrap">

          <zef-form-field>
            <mat-form-field
              color="accent"
              class="__location-form-field">

              <mat-label>Service + Port</mat-label>

              <mat-select
                [ngrxFormControlState]="control.controls.portData"
                [compareWith]="compareFn">
                <mat-optgroup
                  [label]="item[0]?.serviceStackName"
                  *ngFor="let item of portsWithServiceStackInfo; trackBy: trackBy;">
                  <mat-option
                    *ngFor="let portItem of item; trackBy: trackBy;"
                    [value]="portItem">
                    {{ portItem.serviceStackName }}:{{ portItem.port }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>

            </mat-form-field>
          </zef-form-field>

          <button
            *ngIf="locationsControl?.length > 1"
            class="__remove-location-button"
            type="button"
            (click)="removeLocation.emit(index)"
            mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>

        </div>
      </div>

    </div>

</div>
