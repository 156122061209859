import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ZefFormBase, ZefFormsModule } from '@zerops/zef/forms';
import { PipelineTriggerFormState } from '../pipeline-trigger-dialog.form';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { CodeFieldModule } from '@zerops/zui/code-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { AppVersion } from '@zerops/models/app-version';

@Component({
  selector: 'z-pipeline-trigger-form',
  templateUrl: './pipeline-trigger-form.component.html',
  styleUrl: './pipeline-trigger-form.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ZefFormsModule,
    MaterialFormStackModule,
    FormSectionModule,
    CodeFieldModule,
    MatButtonModule,
    MatIconModule,
    ZuiSeparatorComponent
  ]
})
export class PipelineTriggerFormComponent extends ZefFormBase<PipelineTriggerFormState> {
  isZen = input<boolean>();
  activeAppVersion = input<AppVersion>();
  enableLoadRecipeInfo = input<boolean>();
  recipeInfoLoading = input<boolean>();
  prefillBuildFromGitYamlFromLastDeploy = output();
  prefillBuildFromGitYamlFromGitRepo = output();
  prefillZeropsYmlFromLastDeploy = output();
  prefillZeropsYmlOutline = output();
  prefillEnvs = output();
}
