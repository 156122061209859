import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { box } from 'ngrx-forms';
import { zefDialogOpen, zefDialogClose } from '@zerops/zef/dialog';
import { successOf } from '@zerops/zef/entities';
import { HttpRouting } from '@zerops/models/http-routing';
import {
  HttpRoutingEntity,
  extractUniqDomainNamesFromDomains,
  transformLocationToFormLocations
} from '@zerops/zerops/core/http-routing-base';
import { HttpRoutingForm } from '@zerops/zui/http-routing-form';
import { ZefSnackService } from '@zerops/zef/snack';
import { map, filter, switchMap } from 'rxjs/operators';
import { httpRoutingEditDialogOpen } from './http-routing-edit-dialog.action';
import { FEATURE_NAME } from './http-routing-edit-dialog.constant';

@Injectable()
export class HttpRoutingEditDialogEffect {

  private _onDialogOpen$ = this._actions$.pipe(
    ofType(zefDialogOpen),
    filter((action) => action?.key === FEATURE_NAME)
  );

  // open dialog
  private _onHttpRoutingEditDialogOpen$ = createEffect(() => this._actions$.pipe(
    ofType(httpRoutingEditDialogOpen),
    map((data) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: data
    }))
  ));

  // on dialog opened set form value
  private _onDialogOpenSetFormValue$ = createEffect(() => this._onDialogOpen$.pipe(
    map(({ meta: { httpRouting: {
      id,
      sslEnabled,
      cdnEnabled,
      domains,
      locations
    } } }) => this._httpRoutingForm.setValue({
      id,
      sslEnabled,
      cdnEnabled,
      domains: box(extractUniqDomainNamesFromDomains(domains)),
      locations: transformLocationToFormLocations(locations)
    }))
  ));

  private _onUpdateSuccess$ = this._actions$.pipe(
    successOf<HttpRouting>(this._httpRoutingEntity.updateOne)
  );

  private _onUpdateSuccessDialogClose$ = createEffect(() => this._onUpdateSuccess$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  private _onDialogCloseResetForm$ = createEffect(() => this._actions$.pipe(
    ofType(zefDialogClose),
    filter((action) => action?.key === FEATURE_NAME),
    switchMap(() => [
      this._httpRoutingForm.reset(),
      this._httpRoutingForm.setDefaultValues()
    ])
  ));

  // private _onUpdateSuccessNotification$ = createEffect(() => this._onUpdateSuccess$.pipe(
  //   switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.updateSuccess` }))
  // ), { dispatch: false });

  constructor(
    private _actions$: Actions,
    private _httpRoutingEntity: HttpRoutingEntity,
    private _httpRoutingForm: HttpRoutingForm,
    private _snack: ZefSnackService
  ) { }
}
