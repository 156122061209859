import { AppVersionLight } from '@zerops/models/app-version';
import { UserData } from '@zerops/models/user-data';
import { Project } from '@zerops/models/project';
import { ServiceStackCpuModes } from '../settings';

export type ServiceStackTypeVersionStatus = 'ACTIVE' | 'DISABLED';

export class ServiceStackType {
  category: string;
  created: string;
  description: string;
  id: ServiceStackTypes;
  lastUpdate: string;
  name: string;
  serviceStackTypeVersionList: ServiceStackTypeVersion[];
}

export class ServiceStackNextDefaultValue {
  type: string;
  name: string;
  version: string;
  mode?: 'HA' | 'NON_HA';
  startCommand?: string;
  documentRoot?: string;
  diskGBytes?: number;
  rawPolicy?: string;
  policy?: string;
  nginxConfig?: string;
  _versionName?: string;
  // TODO: interface
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ports?: any[];
  userData?: { key: string; content: string; }[];
  customAutoscaling: ServiceStackAutoscaling;
}

export class ServiceStackTypeWithNextDefaultValue extends ServiceStackType {
  nextDefaultValue: ServiceStackNextDefaultValue;
}

export class ServiceStackTypeVersion {
  id: string;
  status: 'ACTIVE' | 'DISABLED';
  serviceStackTypeId: ServiceStackTypes;
  name: string;
  versionNumber: string;
  description: string;
  driverId: string;
  created: string;
  lastUpdate: string;
  releaseDate: string;
  config?: ServiceStackConfig;
}

export class ServiceStackAutoscaling {
  horizontalAutoscaling?: {
    minContainerCount: number;
    maxContainerCount: number;
  };
  verticalAutoscaling?: {
    cpuMode: ServiceStackCpuModes;
    startCpuCoreCount?: number;
    minFreeResource?: {
      cpuCoreCount?: number;
      cpuCorePercent?: number;
      memoryGBytes?: number;
      memoryPercent?: number;
    };
    maxResource: {
      cpuCoreCount: number;
      diskGBytes: number;
      memoryGBytes: number;
    };
    minResource: {
      cpuCoreCount: number;
      diskGBytes: number;
      memoryGBytes: number;
    };
  }
}

export class ServiceStackConfig extends ServiceStackAutoscaling {
  defaultWebserverConfig: string;
}

export enum ServiceStackStatuses {
  New = 'NEW',
  Creating = 'CREATING',
  Stopped = 'STOPPED',
  Active = 'ACTIVE',
  Deleting = 'DELETING',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Stopping = 'STOPPING',
  Starting = 'STARTING',
  Restarting = 'RESTARTING',
  Reloading = 'RELOADING',
  Upgrading = 'UPGRADING',
  Deploying = 'DEPLOYING',
  ActionFailed = 'ACTION_FAILED',
  ReadyToDeploy = 'READY_TO_DEPLOY',
  Repairing = 'REPAIRING',
  ContainerFailed = 'CONTAINER_FAILED',
  MovingContainer = 'MOVING_CONTAINER',
  // Statuses are artificial and not real from API
  NewBuildPending = 'NEW_BUILD_PENDING',
  ActiveBuildPending = 'ACTIVE_BUILD_PENDING',
  NewBuildRunning = 'NEW_BUILD_RUNNING',
  ActiveBuildRunning = 'ACTIVE_BUILD_RUNNING',
  NewAndInstalling = 'NEW_AND_INSTALLING'
}

export interface ServiceStackPort {
  port: number;
  protocol: string;
  description: string;
  scheme: 'tcp' | 'udp' | 'http' | 'https' | 'redis' | 'rabbitmq' | 'mysql';
  serviceId: string;
  publicRouting: boolean;
}

export enum ServiceStackModes {
  Ha = 'HA',
  NonHa = 'NON_HA'
}

// TODO: refactor ServiceStack interface
export class ServiceStack {
  id: string;
  project: Project;
  projectId: string;
  cdnEnabled?: boolean;
  subdomainAccess: boolean;
  activeAppVersion: AppVersionLight;
  serviceStackType: ServiceStackType;
  serviceStackTypeId: ServiceStackTypes;
  serviceStackTypeVersion: ServiceStackTypeVersion;
  serviceStackTypeVersionId: string;
  serviceStackTypeInfo: ServiceStackTypeInfo;
  hasUnsyncedPublicHttpRoutingRecord: boolean;
  hasUnsyncedPublicPortRecord: boolean;
  hasUnsyncedUserDataRecord: boolean;
  hasPublicPortRoutingAccess: boolean;
  hasPublicHttpRoutingAccess: boolean;
  versionNumber: string;
  status: ServiceStackStatuses;
  nginxConfig?: string;
  name: string;
  created: string;
  mode: ServiceStackModes;
  isSystem: boolean;
  lastUpdate: string;
  ports: ServiceStackPort[];
  requestedPorts?: {
    isActive: boolean;
    requestedPorts: ServiceStackPort[];
  };
  githubIntegration: ServiceStackGithubIntegration;
  // TODO: interface
  gitlabIntegration: ServiceStackGithubIntegration;
  connectedStacks: ConnectedStacks[];
  reloadAvailable: boolean;
  userData: UserData[];
  parameterList: Array<{
    parameter: string;
    content: string;
  }>;
  coreService: {
    id: string;
    currentActiveContainerCount: number;
    targetContainerCount :number;
  };
  customAutoscaling: ServiceStackAutoscaling;
}

export class ServiceStackLight {
  id: string;
  serviceStackTypeId: ServiceStackTypes;
  serviceStackTypeVersionId: string;
  name: string;
  serviceStackTypeInfo: ServiceStackTypeInfo;
  isSystem: boolean;
}

export class ConnectedStacks {
  id: string;
  status: ServiceStackStatuses;
  serviceStack: ServiceStackLight;
}

export class ServiceStackGithubIntegration {
  authorizationRequired: boolean;
  branchName: string;
  eventType: string;
  isActive: boolean;
  repositoryFullName: string;
  userId: string;
  webhookId: number;
}

export class ServiceStackFuse extends ServiceStack {
  _serviceStackTypeName: string;
  _serviceStackVersionNumber: string;
}

interface ServiceStackBackupMetadata {
  File_name: string;
  Stack_name: string;
}

export interface ServiceStackBackup {
  metadata: ServiceStackBackupMetadata;
  name: string;
  path: string;
  size: number;
}

export enum ObjecStorageAcls {
  None = 'none',
  Private = 'private',
  PublicRead = 'public-read',
  PublicObjectsRead = 'public-objects-read',
  PublicReadWrite = 'public-read-write',
  PublicWrite = 'public-write',
  AuthenticatedRead = 'authenticated-read',
  Custom = 'custom'
}

export enum ServiceStackTypeCategories {
  Core = 'CORE',
  Build = 'BUILD',
  Prepare = 'PREPARE_RUNTIME',
  HttpL7Balancer = 'HTTP_L7_BALANCER',
  Standard = 'STANDARD',
  User = 'USER',
  SharedStorage = 'SHARED_STORAGE',
  ObjectStorage = 'OBJECT_STORAGE'
}

export enum ServiceStackTypes {
  Elasticsearch = 'elasticsearch',
  Meilisearch = 'meilisearch',
  Typesense = 'typesense',
  Redis = 'keydb',
  Valkey = 'valkey',
  Qdrant = 'qdrant',
  Nats = 'nats',
  Kafka = 'kafka',
  MariaDb = 'mariadb',
  RabbitMq = 'rabbitmq',
  MongoDb = 'mongodb',
  PhpNginx = 'php_nginx',
  PhpApache = 'php_apache',
  Nginx = 'nginx',
  NodeJs = 'nodejs',
  Ubuntu = 'ubuntu',
  Alpine = 'alpine',
  Docker = 'docker',
  Postgres = 'postgresql',
  Clickhouse = 'clickhouse',
  Golang = 'golang',
  ObjectStorage = 'object_storage',
  SharedStorage = 'shared_storage',
  DotNet = 'dotnet',
  Rust = 'rust',
  Python = 'python',
  Static = 'static',
  Bun = 'bun',
  Deno = 'deno',
  Java = 'java',
  Elixir = 'elixir',
  Gleam = 'gleam'
}

export class ServiceStackTypeInfo {
  serviceStackTypeName: string;
  serviceStackTypeVersionName: string;
  serviceStackTypeCategory: ServiceStackTypeCategories;
}

export class ServiceStackTypesWithServiceStackStatuses {
  id: string;
  data: {
    name: string;
    serviceStackTypeName: string;
    serviceStackTypeVersionName: string;
    serviceStackType: ServiceStackType;
    serviceStackTypeVersion: ServiceStackTypeVersion;
  };
  statuses: {
    amount: number;
    label: string;
  }[];
}

export class ServiceStackUpdateData {
  startCommand?: string;
  initCommands?: string[];
  nginxConfig?: string;
}

export class TriggerPipelinePayload {

}

export type MetricTypes = 'cpu' | 'vCpu' | 'ramGBytes' | 'diskGBytes' | 'spend';

export enum MetricTypesEnum {
  Cpu = 'cpu',
  VCpu = 'vCpu',
  Ram = 'ramGBytes',
  Disc = 'diskGBytes'
}

export const defaultMetricTypes = [ 'cpu', 'ramGBytes', 'diskGBytes' ];
const phpMetricTypes = [ 'cpu', 'ramGBytes', 'diskGBytes' ];

export const SERVICE_STACK_AUTOSCALING_CONFIG = {
  [ServiceStackTypes.Elasticsearch]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.Meilisearch]: {
    horizontal: false,
    vertical: true,
    mode: false,
  },
  [ServiceStackTypes.Typesense]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.Redis]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 2,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.Postgres]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 2
    }
  },
  [ServiceStackTypes.Clickhouse]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.Valkey]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.Qdrant]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.Nats]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.Kafka]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.RabbitMq]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 2
    }
  },
  [ServiceStackTypes.MariaDb]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 3,
      freeContainers: 2
    }
  },
  [ServiceStackTypes.MongoDb]: {
    horizontal: false,
    vertical: true,
    mode: true
  },
  [ServiceStackTypes.PhpNginx]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.PhpApache]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Nginx]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Static]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.NodeJs]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Bun]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Deno]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Java]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Ubuntu]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Alpine]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Docker]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Golang]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Rust]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Elixir]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Gleam]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.SharedStorage]: {
    horizontal: false,
    vertical: true,
    mode: true,
    HAContainers: {
      appContainers: 2,
      freeContainers: 0
    }
  },
  [ServiceStackTypes.ObjectStorage]: {
    horizontal: false,
    vertical: false,
    mode: false
  },
  [ServiceStackTypes.DotNet]: {
    horizontal: true,
    vertical: true,
    mode: false
  },
  [ServiceStackTypes.Python]: {
    horizontal: true,
    vertical: true,
    mode: false
  }
};

export const SERVICE_STACK_METRIC_TYPES_MAP = {
  [ServiceStackTypes.Elasticsearch]: {
    elasticsearch_v8_16: defaultMetricTypes
  },
  [ServiceStackTypes.Meilisearch]: {
    meilisearch_v1_10: defaultMetricTypes
  },
  [ServiceStackTypes.Typesense]: {
    typesense_v27_1: defaultMetricTypes
  },
  [ServiceStackTypes.Redis]: {
    keydb_v6: defaultMetricTypes
  },
  [ServiceStackTypes.RabbitMq]: {
    rabbitmq_v3: defaultMetricTypes
  },
  [ServiceStackTypes.MariaDb]: {
    mariadb_v10_3: defaultMetricTypes,
    mariadb_v10_4: defaultMetricTypes,
    mariadb_v10_6: defaultMetricTypes
  },
  [ServiceStackTypes.MongoDb]: {
    mongodb_v4: defaultMetricTypes
  },
  [ServiceStackTypes.Postgres]: {
    postgresql_v12: defaultMetricTypes,
    postgresql_v14: defaultMetricTypes,
    postgresql_v16: defaultMetricTypes
  },
  [ServiceStackTypes.Clickhouse]: {
    clickhouse_v24_8: defaultMetricTypes,
  },
  [ServiceStackTypes.Valkey]: {
    valkey_v7_2: defaultMetricTypes
  },
  [ServiceStackTypes.Qdrant]: {
    qdrant_v1_10: defaultMetricTypes,
    qdrant_v1_12: defaultMetricTypes
  },
  [ServiceStackTypes.Nats]: {
    nats_v2_10: defaultMetricTypes
  },
  [ServiceStackTypes.Kafka]: {
    kafka_v3_8: defaultMetricTypes
  },
  [ServiceStackTypes.PhpNginx]: {
    php_nginx_v7_3: phpMetricTypes,
    php_v7_4_nginx_v1_20: phpMetricTypes,
    php_v7_4_nginx_v1_22: phpMetricTypes,
    php_v8_0_nginx_v1_20: phpMetricTypes,
    php_v8_0_nginx_v1_22: phpMetricTypes,
    php_v8_1_nginx_v1_20: phpMetricTypes,
    php_v8_1_nginx_v1_22: phpMetricTypes,
    php_v8_2_nginx_v1_22: phpMetricTypes,
    php_v8_3_nginx_v1_22: phpMetricTypes,
    php_v8_4_nginx_v1_22: phpMetricTypes
  },
  [ServiceStackTypes.PhpApache]: {
    php_apache_v7_3: phpMetricTypes,
    php_v7_4_apache_v2_4: phpMetricTypes,
    php_v8_0_apache_v2_4: phpMetricTypes,
    php_v8_1_apache_v2_4: phpMetricTypes,
    php_v8_2_apache_v2_4: phpMetricTypes,
    php_v8_3_apache_v2_4: phpMetricTypes,
    php_v8_4_apache_v2_4: phpMetricTypes
  },
  [ServiceStackTypes.Nginx]: {
    nginx_v1_16: defaultMetricTypes,
    nginx_v1_18: defaultMetricTypes,
    nginx_v1_20: defaultMetricTypes,
    nginx_v1_22: defaultMetricTypes,
    nginx_v1_24: defaultMetricTypes
  },
  [ServiceStackTypes.Static]: {
    static: defaultMetricTypes
  },
  [ServiceStackTypes.NodeJs]: {
    nodejs_v10: defaultMetricTypes,
    nodejs_v12: defaultMetricTypes,
    nodejs_v13: defaultMetricTypes,
    nodejs_v14: defaultMetricTypes,
    nodejs_v16: defaultMetricTypes,
    nodejs_v18: defaultMetricTypes,
    nodejs_v20: defaultMetricTypes,
    nodejs_v22: defaultMetricTypes
  },
  [ServiceStackTypes.Ubuntu]: {
    ubuntu_v22: defaultMetricTypes,
    ubuntu_v24: defaultMetricTypes
  },
  [ServiceStackTypes.Alpine]: {
    alpine_v3_17: defaultMetricTypes,
    alpine_v3_18: defaultMetricTypes,
    alpine_v3_19: defaultMetricTypes,
    alpine_v3_20: defaultMetricTypes
  },
  [ServiceStackTypes.Golang]: {
    golang_v1: defaultMetricTypes
  },
  [ServiceStackTypes.Docker]: {
    docker_v26_1: defaultMetricTypes
  },
  [ServiceStackTypes.Rust]: {
    rust_v1: defaultMetricTypes,
    rust_v1_78: defaultMetricTypes,
    rust_v1_80: defaultMetricTypes,
    rust_nightly: defaultMetricTypes
  },
  [ServiceStackTypes.ObjectStorage]: {
    object_storage_v1: defaultMetricTypes
  },
  [ServiceStackTypes.SharedStorage]: {
    shared_storage_v1: defaultMetricTypes,
    moosefs_v3: defaultMetricTypes
  },
  [ServiceStackTypes.DotNet]: {
    dotnet_v6: defaultMetricTypes,
    dotnet_v7: defaultMetricTypes,
    dotnet_v8: defaultMetricTypes,
    dotnet_v9: defaultMetricTypes
  },
  [ServiceStackTypes.Python]: {
    python_v3_8: defaultMetricTypes,
    python_v3_9: defaultMetricTypes,
    python_v3_10: defaultMetricTypes,
    python_v3_11: defaultMetricTypes,
    python_v3_12: defaultMetricTypes
  },
  [ServiceStackTypes.Bun]: {
    bun_v1_1: defaultMetricTypes,
    bun_v1_1_34: defaultMetricTypes,
    bun_v1_2: defaultMetricTypes,
    bun_nightly: defaultMetricTypes,
    bun_canary: defaultMetricTypes
  },
  [ServiceStackTypes.Deno]: {
    deno_v1: defaultMetricTypes,
    deno_v2: defaultMetricTypes
  },
  [ServiceStackTypes.Java]: {
    java_v17: defaultMetricTypes,
    java_v21: defaultMetricTypes
  },
  [ServiceStackTypes.Elixir]: {
    elixir_v1_16: defaultMetricTypes
  },
  [ServiceStackTypes.Gleam]: {
    gleam_v1_5: defaultMetricTypes
  },
};

export const SERVICE_TYPES_INTERNAL_PORTS_CONFIG = {
  [ServiceStackTypes.NodeJs]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Bun]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Deno]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Java]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Golang]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.RabbitMq]: {
    visible: true,
    editable: false
  },
  [ServiceStackTypes.Nginx]: {
    visible: true,
    editable: false
  },
  [ServiceStackTypes.Static]: {
    visible: true,
    editable: false
  },
  [ServiceStackTypes.PhpNginx]: {
    visible: true,
    editable: false
  },
  [ServiceStackTypes.PhpApache]: {
    visible: true,
    editable: false
  },
  [ServiceStackTypes.Ubuntu]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Alpine]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Elixir]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Gleam]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.DotNet]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Rust]: {
    visible: true,
    editable: true
  },
  [ServiceStackTypes.Python]: {
    visible: true,
    editable: true
  }
};
export const RABBIT_MQ_WEB_MANAGEMENT_PORT = 15672;
export const RABBIT_MQ_WS1_PORT = 15674;
export const RABBIT_MQ_WS2_PORT = 15675;
export const RABBIT_MQ_SERVICE_STACK_TYPE_NAME = 'RabbitMQ';
