@if (activeMode() === 'PIPELINE') {
  <div
    class="__pipeline-wrap"
    [style.maxWidth]="maxWidthDetail">

    @if (hasBackbutton) {
      <div class="__back-buttons">
        <a
          class="__back-link-button"
          mat-icon-button
          [routerLink]="[ '/service-stack', state.appVersion?.serviceStackId, 'deploy' ]">
          <mat-icon>arrow_back</mat-icon>
        </a>
      </div>
    }

    <zui-pipeline-desc
      class="__intro-desc"
      [serviceName]="state.serviceStack?.name"
      [serviceType]="state.serviceStack?.serviceStackTypeInfo?.serviceStackTypeName"
      [serviceTypeId]="state.serviceStack?.serviceStackTypeId"
      [projectName]="state.serviceStack?.project.name"
      [serviceId]="state.serviceStack?.id"
      [projectId]="state.serviceStack?.projectId"
      (linkClicked)="linkClicked.emit()"
      [hasHardBreakAfterService]="true"
      [appVersion]="state?.appVersion">
    </zui-pipeline-desc>

    <div class="__created">

      <div class="__created-person-wrap">

        <span class="fx:display-inline-block  fx:vertical-align-middle">Created by</span>

        <zef-avatar
          class="__created-avatar"
          [size]="24"
          [name]="state.appVersion?.createdByUser?.fullName"
          [email]="state.appVersion?.createdByUser?.email"
          [image]="state.appVersion?.createdByUser?.avatar?.smallAvatarUrl"
          [externalImage]="state.appVersion?.createdByUser?.avatar?.externalAvatarUrl"
          [borderWidth]="1"
          [backgroundColor]="'#ccc'">
        </zef-avatar>

        <strong class="__created-name">{{ state.appVersion?.createdByUser?.firstName || state.appVersion?.createdByUser?.fullName }}</strong>&nbsp;<span class="__created-at">at</span>&nbsp;<strong class="__created-date">{{ state.appVersion?.created | date: 'short' }}</strong>

        @if (state.appVersion?.build?.pipelineStart || state?.appVersion?.prepareCustomRuntime?.containerCreationStart) {
          &nbsp;<span class="__created-at">—</span>&nbsp;

          <span class="fx:display-inline-block  fx:vertical-align-middle">Started</span>

          <strong class="__created-date">
            <ng-container *ngIf="!!state.appVersion?.build">
              {{ state.appVersion?.build?.pipelineStart | date: 'short' }}
            </ng-container>
            <ng-container *ngIf="!state.appVersion?.build && !!state.appVersion?.prepareCustomRuntime">
              {{ state?.appVersion?.prepareCustomRuntime?.containerCreationStart | date: 'short' }}
            </ng-container>
          </strong>
        }

      </div>

    </div>

    <zui-separator
      class="__buttons-separator"
      [orientation]="'vertical'"
      [position]="'start'"
      [size]="'small'"
      [spacing]="'small'"
    />

    <div class="__calculating" *ngIf="state.pipelineState.INIT_BUILD_CONTAINER === buildProcessStates.Waiting">
      <zui-three-dots /> Calculating steps from zerops.yml
    </div>

    <ng-container *ngIf="state.pipelineState.INIT_BUILD_CONTAINER !== buildProcessStates.Waiting">

      <!-- buttons -->
      <div class="__buttons  fx:pb-0-5">
        <div class="fx:inline-grid  fx:grid-auto-flow-column  fx:col-gap-2  fx:align-items-center">

          <button
            mat-flat-button
            class="__yaml-config-button  c-button--smaller"
            type="button"
            (click)="onOpenCopyCodeDialog$.next()">
            <mat-icon fontSet="material-icons-outlined">source</mat-icon>&nbsp;<span>Show used <strong>zerops.yml</strong> pipeline configuration</span>
          </button>

          <button
            mat-flat-button
            [satPopoverAnchor]="debugOptionsPopRef"
            (click)="debugOptionsPopRef.open()"
            class="__yaml-config-button  c-button--smaller"
            type="button">
            <mat-icon fontSet="material-icons-outlined">bug_report</mat-icon>&nbsp;<span>Pipeline debug options</span>
          </button>

        </div>
      </div>

      @if (!!state?.appVersion?.build || !!state?.appVersion?.prepareCustomRuntime) {
        <div class="__buttons">
          <div class="fx:inline-grid  fx:grid-auto-flow-column  fx:col-gap-2  fx:align-items-center">

            @if (!!state?.appVersion?.build) {
              <div>
                <button
                  mat-flat-button
                  type="button"
                  (click)="activeMode.set('BUILD_LOG'); modeChanged.emit(activeMode());"
                  [class.is-running]="state.buildProcessState === buildProcessStates.Running"
                  [class.is-waiting]="state.buildProcessState === buildProcessStates.Waiting"
                  [class.is-finished]="state.buildProcessState === buildProcessStates.Finished"
                  [class.is-failed]="state.buildProcessState === buildProcessStates.Failed"
                  [class.is-cancelled]="state.buildProcessState === buildProcessStates.Cancelled"
                  [class.is-activating]="state.buildProcessState === buildProcessStates.Activating"
                  class="__log-button  c-button--smaller">
                  <zui-run-build-step
                    class="__log-button-step-state"
                    [appVersion]="state?.appVersion"
                    [hideText]="true" />&nbsp;<span class="fx:align-middle">Show build log</span>
                </button>
              </div>
            }

            @if (!!state?.appVersion?.prepareCustomRuntime) {
              <div>
                <button
                  type="button"
                  (click)="activeMode.set('PREPARE_LOG'); modeChanged.emit(activeMode());"
                  mat-flat-button
                  [disabled]="state.prepareProcessState === buildProcessStates.Cancelled"
                  [class.is-running]="state.prepareProcessState === buildProcessStates.Running"
                  [class.is-waiting]="state.prepareProcessState === buildProcessStates.Waiting"
                  [class.is-finished]="state.prepareProcessState === buildProcessStates.Finished"
                  [class.is-failed]="state.prepareProcessState === buildProcessStates.Failed"
                  [class.is-cancelled]="state.prepareProcessState === buildProcessStates.Cancelled"
                  [class.is-activating]="state.prepareProcessState === buildProcessStates.Activating"
                  class="__log-button  c-button--smaller">
                  <zui-run-prepare-step
                    class="__log-button-step-state"
                    [hideText]="true"
                    [appVersion]="state?.appVersion" />&nbsp;<span class="fx:align-middle">Show runtime prepare log</span>
                </button>
              </div>
            }

          </div>
        </div>
      }

      <!-- content -->
      <div class="__content">
        <div class="__pipeline-timeline">

          <!-- full pipeline -->
          <div
            *ngIf="!!state?.appVersion?.build"
            class="__pipeline-timeline_datetime"
            [class.is-running]="state?.appVersion?.build?.pipelineStart && !state?.appVersion?.activationDate"
            [class.is-finished]="state?.appVersion?.activationDate"
            [class.is-failed]="state?.appVersion?.build?.pipelineFailed">

            <div>

              <mat-icon class="__pipeline-timeline_icon">schedule</mat-icon>

              <span>{{
                state?.appVersion?.build?.pipelineFailed
                  ? state?.appVersion?.status === appVersionStatuses.Cancelled
                    ? 'Cancelled after'
                    : 'Failed after'
                  : state?.appVersion?.build?.pipelineFinish
                    ? 'Finished in'
                    : 'Running for'
              }}&nbsp;</span>

              <strong class="__pipeline-timeline_duration">
                <zui-duration
                  [start]="state?.appVersion?.build?.pipelineStart"
                  [end]="state?.appVersion?.build?.pipelineFailed ? state?.appVersion?.build?.pipelineFailed : state?.appVersion?.build?.pipelineFinish">
                </zui-duration>
              </strong><span
                class="__pipeline-timeline-end-date"
                *ngIf="state?.appVersion?.build?.pipelineFailed || state?.appVersion?.build?.pipelineFinish">&nbsp;<span class="__created-at">at</span> {{ (state?.appVersion?.build?.pipelineFailed ? state?.appVersion?.build?.pipelineFailed : state?.appVersion?.build?.pipelineFinish) | date: 'medium' }}
              </span>

            </div>

            <button
              *ngIf="[
                appVersionStatuses.Building,
                appVersionStatuses.WaitingToBuild,
                appVersionStatuses.Uploading
              ].includes(state?.appVersion?.status)"
              (click)="onBuildCancel$.next(state?.appVersion?.id)"
              mat-flat-button
              class="[ c-button  c-button--smaller ]  __cancel-build-button"
              type="button"
              [disabled]="!!state?.buildProcess?.cancelRequested">

              <ng-container *ngIf="!state?.buildProcess?.cancelRequested">
                <mat-icon fontSet="material-icons-outlined" class="__cancel-build-button_icon">cancel</mat-icon>&nbsp;<span class="__cancel-build-button_text">Cancel build</span>
              </ng-container>

              <div class="__cancel-build-button_cancel-request" *ngIf="!!state?.buildProcess?.cancelRequested">
                Canceling <zui-three-dots />
              </div>

            </button>

            <zui-separator class="__pipeline-timeline_top-separator" [position]="'start'" [size]="'small'" [spacing]="'none'"></zui-separator>

          </div>

          <!-- deploy with prepare -->
          <div *ngIf="!!state?.appVersion?.prepareCustomRuntime && !state?.appVersion?.build">
            <div
              class="__pipeline-timeline_datetime"
              [class.is-running]="state?.appVersion?.prepareCustomRuntime?.containerCreationStart && !state?.appVersion?.activationDate"
              [class.is-finished]="state?.appVersion?.activationDate"
              [class.is-failed]="state?.appVersion?.status === 'PREPARING_RUNTIME_FAILED'">

              <mat-icon class="__pipeline-timeline_icon">schedule</mat-icon>

              <span>{{
                state?.appVersion?.status === 'PREPARING_RUNTIME_FAILED'
                  ? 'Failed after'
                  : state?.appVersion?.activationDate
                    ? 'Finished in'
                    : 'Running for'
              }}&nbsp;</span>

              <strong class="__pipeline-timeline_duration">
                <zui-duration
                  [start]="state?.appVersion?.prepareCustomRuntime?.containerCreationStart"
                  [end]="state?.appVersion?.status === 'PREPARING_RUNTIME_FAILED' ? state?.appVersion?.prepareCustomRuntime?.endDate : state?.appVersion?.activationDate">
                </zui-duration>
              </strong><span
                class="__pipeline-timeline-end-date"
                *ngIf="state?.appVersion?.status === 'PREPARING_RUNTIME_FAILED' || state?.appVersion?.activationDate">&nbsp;<span class="__created-at">at</span> {{ (state?.appVersion?.status === 'PREPARING_RUNTIME_FAILED' ? state?.appVersion?.prepareCustomRuntime?.endDate : state?.appVersion?.activationDate) | date: 'medium' }}
              </span>

              <zui-separator class="__pipeline-timeline_top-separator" [position]="'start'" [size]="'small'" [spacing]="'none'"></zui-separator>

            </div>
          </div>

          <div class="__pipeline-timeline_steps">

            <div class="__steps-wrap">

              <ng-container *ngIf="!!state?.appVersion?.build">
                <!-- BUILD CONTAINER INIT -->
                <div class="__step">

                  <div class="__step-title-wrap">

                    <zui-init-build-step [appVersion]="state?.appVersion"></zui-init-build-step>

                    <zui-duration
                      class="__step-duration"
                      [start]="state?.appVersion?.build?.pipelineStart"
                      [end]="state?.appVersion?.build?.pipelineFailed
                        ? state.appVersion?.build?.startDate
                          ? state?.appVersion?.build?.startDate
                          : state.appVersion?.build?.pipelineFailed
                        : state.appVersion?.build?.startDate">
                    </zui-duration>

                    @if (state.pipelineState.INIT_BUILD_CONTAINER !== buildProcessStates.Running
                      && state.pipelineState.RUN_BUILD_COMMANDS !== buildProcessStates.Running
                    ) {

                      <div class="fx:pl-0-5">
                        <zui-separator [orientation]="'horizontal'" [spacing]="'none'" [size]="'small'" />
                      </div>

                      <div>
                        <button
                          (click)="forceShowBuildContainer.set(!forceShowBuildContainer())"
                          mat-button
                          class="c-button--smaller">
                          Container info <mat-icon>{{ forceShowBuildContainer() ? 'expand_less' : 'expand_more' }}</mat-icon>
                        </button>
                      </div>
                    }

                  </div>

                  @if (state.pipelineState.INIT_BUILD_CONTAINER === buildProcessStates.Running
                    || state.pipelineState.RUN_BUILD_COMMANDS === buildProcessStates.Running
                    || forceShowBuildContainer()
                  ) {
                    <div class="__build-containers-wrap">
                      <div class="__container-wrap  __container-wrap--build">
                        <div class="__container-grid">

                          <div>
                            <zui-container-base-info
                              [middleEllipsisLength]="28"
                              [hideBadge]="true"
                              [hostname]="state.appVersion?.build?.serviceStackName + '.zerops'"
                              [status]="state.buildContainerStatus"
                              [type]="'build'"
                              [statusText]="state.generalTranslations?.containerStatus[state.buildContainerStatus]"
                            />
                          </div>

                          <div>

                            <button
                              matTooltip="Logs"
                              (click)="activeMode.set('BUILD_LOG'); modeChanged.emit(activeMode());"
                              [disabled]="state.pipelineState.INIT_BUILD_CONTAINER !== buildProcessStates.Finished"
                              mat-icon-button>
                              <mat-icon class="__container-icon" fontSet="material-icons-outlined">list_alt</mat-icon>
                            </button>

                            <button
                              matTooltip="Remote web terminal"
                              (click)="activeMode.set('BUILD_TERMINAL'); modeChanged.emit(activeMode());"
                              [disabled]="!(state.pipelineState.INIT_BUILD_CONTAINER === buildProcessStates.Finished && state.pipelineState.RUN_BUILD_COMMANDS === buildProcessStates.Running)"
                              mat-icon-button>
                              <mat-icon class="__container-icon" fontSet="material-icons-outlined">terminal</mat-icon>
                            </button>

                          </div>

                        </div>

                      </div>
                    </div>
                  }
                </div>

                <!-- BUILD COMMANDS RUN -->
                <div class="__step">
                  <div
                    class="__step-title-wrap"
                    [ngStyle]="{ 'display': !!state?.pipelineErrors && state.pipelineState?.RUN_BUILD_COMMANDS === buildProcessStates.Failed ? 'inline' : undefined }">

                    <zui-run-build-step
                      [ngStyle]="{ 'padding-bottom.px': !!state?.pipelineErrors && state.pipelineState?.RUN_BUILD_COMMANDS === buildProcessStates.Failed ? 5 : undefined }"
                      [appVersion]="state?.appVersion"
                      [pipelineErrors]="state?.pipelineErrors">
                    </zui-run-build-step>

                    <zui-duration
                      class="__step-duration"
                      [ngStyle]="{ 'padding-left.px': !!state?.pipelineErrors && state.pipelineState?.RUN_BUILD_COMMANDS === buildProcessStates.Failed ? 30 : undefined }"
                      [start]="state.appVersion?.build?.startDate"
                      [end]="state.appVersion?.build?.pipelineFailed ? state.appVersion?.build?.pipelineFailed : state.appVersion?.build?.endDate">
                    </zui-duration>

                  </div>
                </div>

              </ng-container>

              <ng-container *ngIf="!!state?.appVersion?.prepareCustomRuntime">
                <!-- PREPARE CONTAINER INIT -->
                <div class="__step">

                  <div class="__step-title-wrap">

                    <zui-init-prepare-step [appVersion]="state?.appVersion"></zui-init-prepare-step>

                    <ng-container *ngIf="state.pipelineState.INIT_PREPARE_CONTAINER !== buildProcessStates.Cancelled">

                      <!-- full pipeline -->
                      <ng-container *ngIf="!!state?.appVersion?.build">
                        <zui-duration
                          class="__step-duration"
                          [start]="state.appVersion?.build?.endDate"
                          [end]="state.appVersion?.build?.pipelineFailed ? state.appVersion?.build?.pipelineFailed : state.appVersion?.prepareCustomRuntime.startDate">
                        </zui-duration>
                      </ng-container>

                      <!-- deploy with prepare -->
                      <ng-container *ngIf="!state?.appVersion?.build">
                        <zui-duration
                          class="__step-duration"
                          [start]="state?.appVersion?.prepareCustomRuntime?.containerCreationStart"
                          [end]="state.appVersion?.prepareCustomRuntime.startDate">
                        </zui-duration>
                      </ng-container>

                      @if (state.pipelineState.RUN_BUILD_COMMANDS === buildProcessStates.Finished
                        && state.pipelineState.INIT_PREPARE_CONTAINER !== buildProcessStates.Running
                        && state.pipelineState.RUN_PREPARE_COMMANDS !== buildProcessStates.Running
                      ) {

                        <div class="fx:pl-0-5">
                          <zui-separator [orientation]="'horizontal'" [spacing]="'none'" [size]="'small'" />
                        </div>

                        <div>
                          <button
                            (click)="forceShowRunPrepareContainer.set(!forceShowRunPrepareContainer())"
                            mat-button
                            class="c-button--smaller">
                            Container info <mat-icon>{{ forceShowRunPrepareContainer() ? 'expand_less' : 'expand_more' }}</mat-icon>
                          </button>
                        </div>
                      }

                    </ng-container>

                  </div>

                  @if (state.pipelineState.INIT_PREPARE_CONTAINER === buildProcessStates.Running
                    || state.pipelineState.RUN_PREPARE_COMMANDS === buildProcessStates.Running
                    || forceShowRunPrepareContainer()
                  ) {
                    <div class="__build-containers-wrap">
                      <div class="__container-wrap  __container-wrap--build">
                        <div class="__container-grid">

                          <div>
                            @if (!state.appVersion?.prepareCustomRuntime?.serviceStackName) {
                              <div class="__build-containers-preparing-wrap">
                                <span class="__containers-preparing-label">Preparing to create container</span> <zui-three-dots  class="__containers-preparing-dots" />
                              </div>
                            } @else {
                              <zui-container-base-info
                                [middleEllipsisLength]="28"
                                [hideBadge]="true"
                                [hostname]="state.appVersion?.prepareCustomRuntime?.serviceStackName + '.zerops'"
                                [status]="state.runPrepareContainerStatus"
                                [type]="'build'"
                                [statusText]="state.generalTranslations?.containerStatus[state.runPrepareContainerStatus]"
                              />
                            }
                          </div>

                          <div>

                            <button
                              matTooltip="Logs"
                              (click)="activeMode.set('PREPARE_LOG'); modeChanged.emit(activeMode());"
                              [disabled]="state.pipelineState.INIT_PREPARE_CONTAINER !== buildProcessStates.Finished"
                              mat-icon-button>
                              <mat-icon class="__container-icon" fontSet="material-icons-outlined">list_alt</mat-icon>
                            </button>

                            <button
                              matTooltip="Remote web terminal"
                              (click)="activeMode.set('PREPARE_TERMINAL'); modeChanged.emit(activeMode());"
                              [disabled]="!(state.pipelineState.INIT_PREPARE_CONTAINER === buildProcessStates.Finished && state.pipelineState.RUN_PREPARE_COMMANDS === buildProcessStates.Running)"
                              mat-icon-button>
                              <mat-icon class="__container-icon" fontSet="material-icons-outlined">terminal</mat-icon>
                            </button>

                          </div>

                        </div>

                      </div>
                    </div>
                  }
                </div>

                <!-- PREPARE COMMANDS RUN -->
                <div class="__step">
                  <div
                    class="__step-title-wrap"
                    [ngStyle]="{ 'display': !!state?.pipelineErrors && state.pipelineState?.RUN_PREPARE_COMMANDS === buildProcessStates.Failed ? 'inline' : undefined }">

                    <zui-run-prepare-step
                      [ngStyle]="{ 'padding-bottom.px': !!state?.pipelineErrors && state.pipelineState?.RUN_PREPARE_COMMANDS === buildProcessStates.Failed ? 5 : undefined }"
                      [appVersion]="state?.appVersion"
                      [pipelineErrors]="state?.pipelineErrors">
                    </zui-run-prepare-step>

                    <zui-duration
                      class="__step-duration"
                      [ngStyle]="{ 'padding-left.px': !!state?.pipelineErrors && state.pipelineState?.RUN_PREPARE_COMMANDS === buildProcessStates.Failed ? 30 : undefined }"
                      [start]="state.appVersion?.prepareCustomRuntime.startDate"
                      [end]="state.appVersion?.build?.pipelineFailed ? state.appVersion?.build?.pipelineFailed : state.appVersion?.prepareCustomRuntime.endDate">
                    </zui-duration>

                  </div>
                </div>

              </ng-container>

              <!-- DEPLOY -->
              <div class="__step">

                <div
                  class="__step-title-wrap"
                  [ngStyle]="{ 'display': !!state?.pipelineErrors && state.pipelineErrors.length > 0 && state.pipelineState?.DEPLOY === buildProcessStates.Failed ? 'inline' : undefined }">

                  <zui-deploy-step
                    [ngStyle]="{ 'max-width': '550px', 'padding-bottom.px': !!state?.pipelineErrors && state.pipelineErrors.length > 0 && state.pipelineState?.DEPLOY === buildProcessStates.Failed ? 5 : undefined }"
                    [runtimeServiceName]="state.serviceStack?.name"
                    [runtimeServiceType]="state.serviceStack?.serviceStackTypeInfo?.serviceStackTypeName"
                    [appVersion]="state?.appVersion"
                    [pipelineErrors]="state?.pipelineErrors">
                  </zui-deploy-step>

                  <zui-duration
                    class="__step-duration"
                    [ngStyle]="{ 'padding-left.px': !!state?.pipelineErrors && state.pipelineErrors.length > 0 && state.pipelineState?.DEPLOY === buildProcessStates.Failed ? 30 : undefined }"
                    [start]="state.appVersion?.prepareCustomRuntime?.endDate || (!state.appVersion?.prepareCustomRuntime ? state.appVersion?.build?.endDate : undefined)"
                    [end]="state.appVersion?.build?.pipelineFailed ? state.appVersion?.build?.pipelineFailed : state.appVersion?.activationDate">
                  </zui-duration>

                </div>

                @if (state.pipelineState?.DEPLOY === buildProcessStates.Running) {
                  <div class="__containers-grid-wrap">

                    <div class="fx:grid  fx:gap-1  fx:grid-cols-2">

                      @if (state.oldNewContainersMap?.old?.length) {
                        <div>

                          <h3 class="__containers-title">Current containers</h3>

                          @for (item of state.oldNewContainersMap.old; track item.id) {
                            <div class="__container-wrap">
                              <zui-container-base-info
                                [hostname]="item.hostname"
                                [containerId]="item.id"
                                [created]="item.created"
                                [hideBadge]="true"
                                [versionName]="state.activeAppVersion?.name"
                                [status]="item.status"
                                [type]="'container'"
                                [statusText]="state.generalTranslations?.containerStatus[item.status]"
                              />
                            </div>
                          }
                        </div>
                      }

                      <div>

                        @if (state.oldNewContainersMap?.old?.length) {
                          <h3 class="__containers-title">New containers</h3>
                        }

                        @if (state.oldNewContainersMap?.new?.length) {
                          @for (item of state.oldNewContainersMap.new; track item.id) {
                            <div class="__container-wrap">
                              <div class="__container-grid">

                                <div>
                                  <zui-container-base-info
                                    [hostname]="item.hostname"
                                    [containerId]="item.id"
                                    [created]="item.created"
                                    [hideBadge]="true"
                                    [versionName]="state.activeAppVersion?.name"
                                    [status]="item.status"
                                    [type]="'container'"
                                    [statusText]="state.generalTranslations?.containerStatus[item.status]"
                                  />
                                </div>

                                <div>

                                  <a
                                    (click)="linkClicked.emit()"
                                    [routerLink]="[ '/', 'service-stack', item.serviceStackId, 'log' ]"
                                    [queryParams]="{ containerId: item.id }"
                                    matTooltip="Runtime Logs"
                                    mat-icon-button>
                                    <mat-icon class="__container-icon" fontSet="material-icons-outlined">list_alt</mat-icon>
                                  </a>

                                  <a
                                    (click)="linkClicked.emit()"
                                    [routerLink]="[ '/', '/service-stack', item.serviceStackId, 'terminal' ]"
                                    [queryParams]="{ containerId: item.id }"
                                    [disabled]="item.status !== 'ACTIVE'"
                                    matTooltip="Remote web terminal"
                                    mat-icon-button>
                                    <mat-icon class="__container-icon" fontSet="material-icons-outlined">terminal</mat-icon>
                                  </a>

                                </div>

                              </div>
                            </div>
                          }
                        } @else {
                          <div class="__containers-preparing-wrap">
                            @if (state.oldNewContainersMap?.old?.length) {
                              <span class="__containers-preparing-label">Preparing upgrade</span> <zui-three-dots  class="__containers-preparing-dots" />
                            } @else {
                              <span class="__containers-preparing-label">Preparing to create first containers</span> <zui-three-dots class="__containers-preparing-dots" />
                            }
                          </div>

                        }

                      </div>

                    </div>

                  </div>
                }

                @if (state.pipelineState.DEPLOY === buildProcessStates.Failed) {
                  <!-- A BUTTON TO SWITCH TO THE PROJECT LOG WHEN FAILING -->
                  <div class="__switch-project-log-wrap">
                    <a
                      [routerLink]="['/project', state.serviceStack?.projectId, 'logging' ]"
                      [queryParams]="{ serviceStackId: state.serviceStack?.id, mode: 'range', from: state?.logFailedRange?.from, till: state?.logFailedRange?.till }"
                      class="c-button--smaller"
                      mat-button
                      color="primary"
                      (click)="linkClicked.emit()">
                      <span>Show runtime container error in full service log</span>&nbsp;<mat-icon>chevron_right</mat-icon>
                    </a>
                  </div>
                }

              </div>

            </div>

          </div>

        </div>
      </div>

    </ng-container>

  </div>
}

@if (activeMode() === 'BUILD_LOG' || activeMode() === 'PREPARE_LOG') {
  <div
    [style.maxWidth]="maxWidthLogs"
    class="__logs-wrap">

    <div class="__back-buttons">
      <button
        class="__back-button"
        type="button"
        (click)="modeChanged.emit('PIPELINE')"
        mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <!-- build log state -->
    @if (activeMode() === 'BUILD_LOG') {
      <div class="c-pipeline-detail-log-wrap">

        <div class="__log-info">

          <span class="__log-info_text">
            <zui-run-build-step class="__log-info_step" [hideText]="true" [appVersion]="state?.appVersion" />
            Build log for pipeline <strong>{{ state?.appVersion?.id }}</strong> for service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
          </span>

          <zui-duration
            class="__log-info_duration"
            [start]="state.appVersion?.build?.startDate"
            [end]="state.appVersion?.build?.pipelineFailed ? state.appVersion?.build?.pipelineFailed : state.appVersion?.build?.endDate">
          </zui-duration>

          <div class="__tag-toggle">
            <mat-slide-toggle
              [checked]="showOnlyBuildLogs()"
              (change)="showOnlyBuildLogs.set(!showOnlyBuildLogs())"
              labelPosition="before"
              [disabled]="disabled">
              Show only build logs
            </mat-slide-toggle>
          </div>
        </div>

        <z-trlog
          *ngIf="!!state.buildLogParams"
          [scrollHeight]="logsScrollHeight"
          virtualScrollKey="logBuildRef"
          [options]="{
            live: state.pipelineState.RUN_BUILD_COMMANDS === buildProcessStates.Running,
            liveCancellable: false,
            liveFollow: true
          }"
          [params]="state.buildLogParams">
        </z-trlog>

      </div>
    }

    <!-- prepare log state -->
    @if (activeMode() === 'PREPARE_LOG') {
      <div class="c-pipeline-detail-log-wrap">
        <div class="__log-info">

          <span class="__log-info_text">
            <zui-run-prepare-step class="__log-info_step" [hideText]="true" [appVersion]="state?.appVersion"></zui-run-prepare-step>
            Prepare runtime log for pipeline <strong>{{ state.appVersion?.id }}</strong> for service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
          </span>

          <zui-duration
            class="__log-info_duration"
            [start]="state.appVersion?.prepareCustomRuntime.startDate"
            [end]="state.appVersion?.build?.pipelineFailed ? state.appVersion?.build?.pipelineFailed : state.appVersion?.prepareCustomRuntime.endDate">
          </zui-duration>
        </div>

        <z-trlog
          *ngIf="!!state.prepareLogParams"
          [scrollHeight]="logsScrollHeight"
          virtualScrollKey="logBuildPrepareRef"
          [options]="{
            live: state.pipelineState.RUN_PREPARE_COMMANDS === buildProcessStates.Running,
            liveCancellable: false,
            liveFollow: true
          }"
          [params]="state.prepareLogParams">
        </z-trlog>

      </div>
    }

  </div>
}

@if (activeMode() === 'BUILD_TERMINAL' || activeMode() === 'PREPARE_TERMINAL') {
  <div
    [style.maxWidth]="maxWidthLogs"
    class="__terminal-wrap">

    <div class="__back-buttons">
      <button
        class="__back-button"
        type="button"
        (click)="modeChanged.emit('PIPELINE')"
        mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    @if (activeMode() === 'BUILD_TERMINAL') {
      <div class="__terminal-info">

        <span class="__terminal-info_text">
          <zui-run-build-step class="__terminal-info_step" [hideText]="true" [appVersion]="state?.appVersion" />
          Build container in pipeline <strong>{{ state?.appVersion?.id }}</strong> on service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
        </span>

        <zui-duration
          class="__terminal-info_duration"
          [start]="state.appVersion?.build?.startDate"
          [end]="state.appVersion?.build?.pipelineFailed ? state.appVersion?.build?.pipelineFailed : state.appVersion?.build?.endDate">
        </zui-duration>

      </div>

      <div class="__terminal">
        @defer {
          <z-terminal
            #buildTerminalRef
            [showFilters]="false"
            [serviceId]="state.appVersion?.build?.serviceStackId"
          />
        }
      </div>

    }

    @if (activeMode() === 'PREPARE_TERMINAL') {
      <div class="__terminal-info">

        <span class="__terminal-info_text">
          <zui-run-build-step class="__terminal-info_step" [hideText]="true" [appVersion]="state?.appVersion" />
          Runtime prepare container in pipeline <strong>{{ state?.appVersion?.id }}</strong> on service <ng-template [ngTemplateOutlet]="projectServiceLinkRef"></ng-template>
        </span>

        <zui-duration
          class="__terminal-info_duration"
          [start]="state.appVersion?.build?.startDate"
          [end]="state.appVersion?.build?.pipelineFailed ? state.appVersion?.build?.pipelineFailed : state.appVersion?.build?.endDate">
        </zui-duration>

      </div>

      <div class="__terminal">
        @defer {
          <z-terminal
            #prepareTerminalRef
            [showFilters]="false"
            [serviceId]="state.appVersion?.prepareCustomRuntime?.serviceStackId"
          />
        }
      </div>
    }

  </div>
}

<ng-template #projectServiceLinkRef>

  <ng-container *ngIf="!disableLink">
    <a (click)="linkClicked.emit()" class="c-internal-link" [routerLink]="[ '/project', state.serviceStack?.projectId ]">{{ state.serviceStack?.project.name }}</a>/<a (click)="linkClicked.emit()" class="c-internal-link" [routerLink]="[ '/service-stack', state.serviceStack?.id ]"><strong>{{ state.serviceStack?.name }}</strong></a>
  </ng-container>

  <ng-container *ngIf="disableLink">
    <span (click)="linkClicked.emit()" class="c-internal-link">{{ state.serviceStack?.project.name }}</span>/<span (click)="linkClicked.emit()" class="c-internal-link"><strong>{{ state.serviceStack?.name }}</strong></span>
  </ng-container>

</ng-template>

<!-- debug options -->
<sat-popover
  #debugOptionsPopRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  (backdropClicked)="debugOptionsPopRef.close()"
  [closeTransition]="'450ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1)'"
  [verticalAlign]="'below'"
  [horizontalAlign]="'center'">
  <mat-card class="__debug-pop-card  z-manual-elevation  c-soft-elevation">
    <div class="fx:pb-0-5">
      <zui-tip-card [icon]="'report_problem'" class="__debug-tip">Changing options won't affect the running pipeline process</zui-tip-card>
    </div>
    <z-debug-switcher [serviceId]="state.serviceStack?.id" />
  </mat-card>
</sat-popover>
