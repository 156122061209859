import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { PaymentBaseEffect } from './payment-base.effect';

@NgModule({
  imports: [
    EffectsModule.forFeature([ PaymentBaseEffect ])
  ]
})
export class PaymentBaseModule {}
