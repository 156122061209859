import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { onZefDialogClose, onZefDialogOpen, zefDialogClose, zefDialogOpen } from '@zerops/zef/dialog';
import { map, mergeMap } from 'rxjs/operators';
import { recurringTopUpConfigDialogActions } from './recurring-top-up-config-dialog.action';
import { FEATURE_NAME } from './recurring-top-up-config-dialog.constant';
import { RecurringTopUpConfigDialogForm } from './recurring-top-up-config-dialog.form';
import { autoChargeUpdateSuccess } from '@zerops/zerops/core/billing-base';

@Injectable()
export class RecurringPaymentConfigDialogEffect {

  // # Deps
  #actions$ = inject(Actions);
  #form = inject(RecurringTopUpConfigDialogForm);

  // # Effects
  onRecurringPaymentConfigDialogOpen$ = createEffect(() => this.#actions$.pipe(
    ofType(recurringTopUpConfigDialogActions.open),
    map(({ data }) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: data
    }))
  ));

  onDialogOpenSetForm$ = createEffect(() => this.#actions$.pipe(
    onZefDialogOpen(FEATURE_NAME),
    map(({ meta }) => this.#form.setValue({
      autoChargePeriod: meta.autoCharge === false
        ? 'DISABLE'
        : meta.autoChargePeriod,
      maximumChargeAmount: meta.maximumChargeAmount
    }))
  ));

  onAutoChargeUpdateSuccess$ = createEffect(() => this.#actions$.pipe(
    ofType(autoChargeUpdateSuccess),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onRecurringPaymentConfigDialogCloseResetForm$ = createEffect(() => this.#actions$.pipe(
    onZefDialogClose(FEATURE_NAME),
    mergeMap(() => [
      this.#form.reset(),
      this.#form.setDefaultValues()
    ])
  ));

}
