<zef-form
  [state]="state"
  (submit)="submit.emit(state)">

  <zui-http-routing-fields
    (removeLocation)="removeLocation.emit($event)"
    (domainSelected)="domainSelected.emit($event)"
    (domainBlacklisted)="domainBlacklisted.emit($event)"
    [domainsControl]="state?.controls.domains"
    [sslControl]="state?.controls.sslEnabled"
    [cdnControl]="state?.controls.cdnEnabled"
    [domainsBlacklist]="domainsBlacklist"
    [locationsControl]="state.controls.locations.controls"
    [portsWithServiceStackInfo]="portsWithServiceStackInfo"
    [mode]="mode"
    [translations]="httpRoutingFieldsTranslations">
  </zui-http-routing-fields>

  <ng-content></ng-content>

</zef-form>
