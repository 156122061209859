import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AddonsResponse,
  BillingAutochargeOptions,
  BillingPaymentRequestDto,
  BillingPaymentResponse,
  BillingPaymentSourcesResponse
} from '@zerops/models/billing';

@Injectable({ providedIn: 'root' })
export class BillingBaseApi {
  constructor(private _http: HttpClient) {}

  paymentRequest$(data: BillingPaymentRequestDto, clientId: string) {
    return this._http.post<BillingPaymentResponse>(
      `/api/billing/payment`,
      {
        ...data,
        clientId
      }
    );
  }

  paymentSources$(clientId: string) {
    return this._http.get<BillingPaymentSourcesResponse>(
      `/api/billing/client/${clientId}/payment-source`
    );
  }

  removePaymentSource$(clientId: string, sourceId: string) {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        sourceId
      },
    };

    return this._http.delete<{ success: boolean; }>(
      `/api/billing/client/${clientId}/payment-source`,
      options
    );
  }

  availableAddons$(clientId: string) {
    return this._http.get<AddonsResponse>(
      `/api/billing/client/${clientId}/available-addons`
    );
  }

  registeredAddons$(clientId: string) {
    return this._http.get<AddonsResponse>(
      `/api/billing/client/${clientId}/registered-addons`
    );
  }

  modifyAddon$(id: string, clientId: string, recurringEnabled: boolean, enabled: boolean) {
    return this._http.put<{ success: true }>(
      `/api/billing/addon/${id}`,
      {
        clientId,
        recurringEnabled,
        enabled
      }
    );
  }

  vatCheck$(vatNumber: string) {
    return this._http.post<{ success: true }>(
      `/api/client/check-vat-number`,
      { vatNumber }
    )
  }

  autoChargeUpdate$(clientId: string, options: BillingAutochargeOptions) {
    return this._http.put<{ success: true }>(
      `/api/billing/client/${clientId}/autocharge-options`,
      { ...options }
    )
  }

}
