import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { IpInfoModule } from '@zerops/zui/ip-info';
import { ZefCopyToClipboardModule } from '@zerops/zef/copy-to-clipboard';
import { ZuiDocsTextModule } from '@zerops/zui/docs-text';
import { IpInfoCardComponent } from './ip-info-card.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ IpInfoCardComponent ],
  imports: [
    CommonModule,
    MatCardModule,
    IpInfoModule,
    ZefCopyToClipboardModule,
    ZuiDocsTextModule,
    MatIconModule
  ],
  exports: [ IpInfoCardComponent ]
})
export class ZuiIpInfoCardModule { }
