import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, withLatestFrom, tap } from 'rxjs/operators';
import { ObservableInput } from 'observable-input';
import { ZefError, zefRemoveError } from '@zerops/zef/errors';
import { zefLogout } from '@zerops/zef/auth';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { loadUser } from '@zerops/zerops/core/user-base';

@Component({
  selector: 'z-dialog-error',
  templateUrl: './dialog-error.container.html',
  styleUrls: [ './dialog-error.container.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogErrorContainer extends ZefReactiveComponent {

  /**
   * Sync Data
   */
  showButton = false;
  userLoadKey = loadUser.type;

  /**
   * Event Streams
   */
  onLogout$ = new Subject<void>();
  onClose$ = new Subject<void>();

  @ObservableInput()
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  @Input('data')
  data$!: Observable<ZefError[]>;

  errors$ = this.data$.pipe(
    tap((errors) => {
      this.showButton = false;
      errors.forEach((error) => error.originalError?.status !== 403 && error.key !== this.userLoadKey ? this.showButton = true : undefined);
    })
  );

  /**
   * Action Streams
   */

  private _logoutAction$ = this.onLogout$.pipe(
    map(() => zefLogout())
  );

  private _closeAction$ = this.onClose$.pipe(
    withLatestFrom(this.errors$),
    mergeMap(([ _, errors ]) => errors.map((error) => zefRemoveError(error.key)))
  );

  constructor() {
    super();

    this.$dispatchActions([
      this._logoutAction$,
      this._closeAction$
    ]);
  }

}
