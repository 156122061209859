import { HashMap } from '@zerops/zef/core';

export class ObjectStoragesUsageState {
  data: HashMap<{
    used: number;
    rawPolicy: string;
    cdnEnabled?: boolean;
  }>;

  constructor() {
    this.data = {};
  }
}

export class ObjectStorageUsage {
  diskGBytesUsed: number;
  rawPolicy: string;
}
