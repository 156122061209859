import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import { validate, FormState } from 'ngrx-forms';
import { maxLength, pattern, required, greaterThan } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './object-storage-service-stack-form.constant';

export interface ObjectStorageServiceStackFormValue {
  name: string;
  diskGBytes: number;
  policy: string;
  rawPolicy?: string;
  cdnEnabled?: boolean;
}

export type ObjectStorageServiceStackFormState = FormState<ObjectStorageServiceStackFormValue>;

@Injectable({ providedIn: 'root' })
export class ObjectStorageServiceStackForm extends ZefForm<ObjectStorageServiceStackFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        name: '',
        diskGBytes: 2,
        cdnEnabled: false,
        policy: 'public-read',
        rawPolicy: `{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Principal": {
        "AWS": [
          "*"
        ]
      },
      "Action": [
        "s3:GetBucketLocation",
        "s3:ListBucket"
      ],
      "Resource": [
        "arn:aws:s3:::{{.BucketName}}"
      ]
    },
    {
      "Effect": "Allow",
      "Principal": {
        "AWS": [
          "*"
        ]
      },
      "Action": [
        "s3:GetObject"
      ],
      "Resource": [
        "arn:aws:s3:::{{.BucketName}}/*"
      ]
    }
  ]
}`
      },
      {
        name: validate(required, maxLength(25), pattern(/^[a-z0-9]*$/)),
        diskGBytes: validate(greaterThan(0)) as any
      }
    );
  }
}
