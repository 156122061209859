import { Component, EventEmitter, Input, Output, effect, inject, input, signal, viewChild } from '@angular/core';
import { AppVersion, AppVersionStatuses } from '@zerops/models/app-version';
import { ProcessStatuses } from '@zerops/models/process';
import { ZefReactiveComponent, distinctUntilKeysChanged } from '@zerops/zef/core';
import { ServiceStackEntity } from '@zerops/zerops/core/service-stack-base';
import { BuildProcessStates, getPipelineState } from '@zerops/zui/build-state-steps';
import { ObservableInput } from 'observable-input';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  scan,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { FEATURE_NAME } from './pipeline-detail.constant';
import { copyCodeDialogOpen } from '../copy-code-dialog';
import { buildCancel, ProcessEntity } from '@zerops/zerops/core/process-base';
import { PipelineDetailModes } from './pipeline-detail.model';
import { PipelineError } from '@zerops/models/error-backend';
import { Container, ContainerEntity, ContainerWithService } from '@zerops/zerops/core/container-base';
import { Actions } from '@ngrx/effects';
import { onWebsocketMessageDispatchAddRemoveEntities } from '@zerops/zef/entities';
import { GeneralTranslations } from '@zerops/zerops/app';
import { parseISO, isValid, isBefore, subSeconds } from 'date-fns/esm';
import { ServiceStackStatuses } from '@zerops/models/service-stack';
import { toObservable } from '@angular/core/rxjs-interop';
import { TerminalFeature } from '../terminal/terminal.feature';

const CREATED_BY_TYPE_PREFIX_MAP = {
  GITHUB: 'GitHub:',
  GITLAB: 'GitLab:',
  CLI: 'zcli:',
  USER: 'Zerops:'
};

@Component({
  selector: 'z-pipeline-detail',
  templateUrl: './pipeline-detail.feature.html',
  styleUrls: [ './pipeline-detail.feature.scss' ]
})
export class PipelineDetailFeature extends ZefReactiveComponent {

  // # Deps
  #serviceStackEntity = inject(ServiceStackEntity);
  #processEntity = inject(ProcessEntity);
  #containerEntity = inject(ContainerEntity);
  #actions$ = inject(Actions);

  // # Event Streams
  onOpenCopyCodeDialog$ = new Subject<void>();
  onBuildCancel$ = new Subject<string>();

  // # Data
  // -- sync
  buildProcessStates = BuildProcessStates;
  proccessStatuses = ProcessStatuses;
  appVersionStatuses = AppVersionStatuses;
  serviceStackStatuses = ServiceStackStatuses;
  forceShowBuildContainer = signal(false);
  forceShowRunPrepareContainer = signal(false);
  showOnlyBuildLogs = signal(true);
  activeMode = signal<PipelineDetailModes>('PIPELINE');

  // - angular
  @ObservableInput()
  @Input('appVersion')
  appVersion$!: Observable<AppVersion>;

  @ObservableInput()
  @Input('pipelineErrors')
  pipelineErrors$!: Observable<PipelineError[]>;

  @ObservableInput()
  @Input('baseKey')
  baseKey$!: Observable<string>;

  mode = input<PipelineDetailModes>('PIPELINE');
  buildTerminalRef = viewChild<TerminalFeature>('buildTerminalRef');
  prepareTerminalRef = viewChild<TerminalFeature>('prepareTerminalRef');

  modeEffect = effect(() => {
    if (this.mode()) {
      this.showOnlyBuildLogs.set(true);
    }

    this.activeMode.set(this.mode())

    if (this.mode() === 'BUILD_TERMINAL') {
      setTimeout(() => {
        this.buildTerminalRef()?.focus();
      }, 200);
    }

    if (this.mode() === 'PREPARE_TERMINAL') {
      setTimeout(() => {
        this.prepareTerminalRef()?.focus();
      }, 200);
    }

  }, { allowSignalWrites: true });

  @Input()
  maxWidthDetail: string;

  @Input()
  maxWidthLogs: string;

  @Input()
  hasBackbutton = false;

  @Input()
  backbuttonRoute: any[];

  @Input()
  logsScrollHeight = 'calc(85vh - 40px)'

  @Output()
  linkClicked = new EventEmitter<void>();

  @Output()
  modeChanged = new EventEmitter<PipelineDetailModes>();

  // -- async
  generalTranslations$ = this.translate$<GeneralTranslations>('general');
  serviceStackId$ = this.onInit$.pipe(
    tap(() => this.activeMode.set('PIPELINE')),
    switchMap(() => this.appVersion$.pipe(
      filter((d) => !!d),
      map(({ serviceStackId }) => serviceStackId),
      distinctUntilChanged()
    ))
  );
  pipelineServiceStackIds$ = this.onInit$.pipe(
    switchMap(() => this.appVersion$.pipe(
      filter((d) => !!d),
      map((d) => ({
        build: d.build?.serviceStackId,
        prepare: d.prepareCustomRuntime?.serviceStackId
      }))
    ))
  );
  serviceStack$ = this.serviceStackId$.pipe(
    switchMap((id) => this.#serviceStackEntity.entityById$(id)),
    filter((d) => !!d)
  );
  pipelineState$ = this.onInit$.pipe(
    switchMap(() => this.appVersion$.pipe(
      filter((d) => !!d),
      map((appVersion) => getPipelineState(appVersion))
    ))
  );
  logFailedRange$ = this.onInit$.pipe(
    switchMap(() => this.appVersion$.pipe(
      filter((d) => !!d?.build),
      map((d) => d.build.pipelineFailed),
      filter((pipelineFailed) => !!pipelineFailed),
      map((pipelineFailed) => {
        const failed = new Date(pipelineFailed);
        const beforeFailed = new Date(pipelineFailed);
        const afterFailed = new Date(pipelineFailed);
        beforeFailed.setMinutes(failed.getMinutes() - 1);
        afterFailed.setMinutes(failed.getMinutes() + 1);
        return { from: beforeFailed.toISOString(), till: afterFailed.toISOString()}
      })
    ))
  );
  emptyBaseKey$ = this.baseKey$.pipe(startWith(undefined as string));
  uniqBuildLogKey$ = this.pipelineServiceStackIds$.pipe(
    withLatestFrom(this.emptyBaseKey$),
    map(([ { build }, baseKey ]) => this._getKey(this._buildLogKey, baseKey, build))
  );
  uniqPrepareLogKey$ = this.pipelineServiceStackIds$.pipe(
    withLatestFrom(this.emptyBaseKey$),
    map(([ { prepare }, baseKey ]) => this._getKey(this._prepareLogKey, baseKey, prepare))
  );
  createdByTypePrefix$ = this.appVersion$.pipe(
    map((d) => CREATED_BY_TYPE_PREFIX_MAP[d?.createdByUser?.type])
  );
  containers$ = this.serviceStackId$.pipe(
    filter((id) => !!id),
    switchMap((id) => this.#containerEntity.listWithService$(
      { name: FEATURE_NAME, id },
      [ 'created' ],
      [ 'asc' ]
    )),
    scan((acc: ContainerWithService[], current: ContainerWithService[]) => {
      if (current.length > acc.length) {
        return current;
      }
      return acc;
    }, []),
  );
  buildContainerStatus$ = this.pipelineState$.pipe(
    map((d) => {
      if (d.INIT_BUILD_CONTAINER === this.buildProcessStates.Running) {
        return ServiceStackStatuses.Creating;
      }

      if (d.INIT_BUILD_CONTAINER === this.buildProcessStates.Failed) {
        return ServiceStackStatuses.Failed;
      }

      if (d.RUN_BUILD_COMMANDS === this.buildProcessStates.Finished) {
        return ServiceStackStatuses.Deleted;
      }

      if (d.RUN_BUILD_COMMANDS === this.buildProcessStates.Failed) {
        return ServiceStackStatuses.Deleted;
      }

      return ServiceStackStatuses.Active;

    })
  );
  runPrepareContainerStatus$ = this.pipelineState$.pipe(
    map((d) => {
      if (d.INIT_PREPARE_CONTAINER === this.buildProcessStates.Running) {
        return ServiceStackStatuses.Creating;
      }

      if (d.INIT_PREPARE_CONTAINER === this.buildProcessStates.Failed) {
        return ServiceStackStatuses.Failed;
      }

      if (d.RUN_PREPARE_COMMANDS === this.buildProcessStates.Finished) {
        return ServiceStackStatuses.Deleted;
      }

      if (d.DEPLOY === this.buildProcessStates.Failed) {
        return ServiceStackStatuses.Deleted;
      }

      return ServiceStackStatuses.Active;

    })
  );
  oldNewContainersMap$ = this.containers$.pipe(
    withLatestFrom(this.appVersion$),
    map(([containers, appVersion]) => {
      const pipelineStart = parseISO(appVersion.build.pipelineStart);
      return containers?.reduce((acc, container) => {
        const containerCreated = parseISO(container.created);

        if (!isValid(containerCreated)) {
          return acc;
        }
        const category = isBefore(containerCreated, pipelineStart) ? 'old' : 'new';
        acc[category].push(container);

        return acc;
      }, { old: [], new: [] } as { old: Container[]; new: Container[] }) || { old: [], new: [] };
    })
  );
  processes$ = this.#processEntity.list$();
  buildProcess$ = this.appVersion$.pipe(
    switchMap(({ id }) => this.processes$.pipe(
      filter((d) => !!d),
      map((processes) => processes.find((process) => process?.appVersion?.id === id))
    ))
  );
  buildLogParams$ = combineLatest([
    this.serviceStack$.pipe(
      filter((d) => !!d),
      map(({ projectId }) => projectId),
      distinctUntilChanged()
    ),
    this.pipelineServiceStackIds$,
    this.appVersion$.pipe(
      filter((d) => !!d),
      distinctUntilChanged(( prev, next ) => prev.id !== next.id)
    ),
    toObservable(this.showOnlyBuildLogs),
    this.pipelineState$.pipe(
      filter((d) => d?.RUN_BUILD_COMMANDS === BuildProcessStates.Running
        || d?.RUN_BUILD_COMMANDS === BuildProcessStates.Finished
        || d?.RUN_BUILD_COMMANDS === BuildProcessStates.Failed),
      take(1)
    )
  ]).pipe(
    map(([ projectId, ids, appVersion, showOnlyBuildLogs ]) => ({
      projectId,
      serviceStackId: ids.build,
      page: 5000,
      from: appVersion.build?.pipelineStart
        ? subSeconds(new Date(appVersion.build.pipelineStart), 5)
        : undefined,
      tags: appVersion?.id && showOnlyBuildLogs
        ? `zbuilder@${appVersion.id}`
        : undefined
    }))
  );
  prepareLogParams$ = combineLatest([
    this.serviceStack$.pipe(
      filter((d) => !!d),
      map(({ projectId }) => projectId),
      distinctUntilChanged()
    ),
    this.pipelineServiceStackIds$,
    this.pipelineState$.pipe(
      filter((d) => d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Running
        || d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Finished
        || d?.RUN_PREPARE_COMMANDS === BuildProcessStates.Failed),
      take(1)
    )
  ]).pipe(
    map(([ projectId, ids ]) => ({
      projectId,
      serviceStackId: ids.prepare,
      page: 5000
    }))
  );
  buildProcessState$ = this.pipelineState$.pipe(
    filter((d) => !!d),
    map((d) => d?.RUN_BUILD_COMMANDS)
  );
  deployRunning$ = this.pipelineState$.pipe(
    filter((d) => !!d),
    map((d) => d.DEPLOY === BuildProcessStates.Running),
    filter((d) => !!d)
  );
  prepareProcessState$ = this.pipelineState$.pipe(
    filter((d) => !!d),
    map((d) => d?.RUN_PREPARE_COMMANDS)
  );

   // # State resolver
   state = this.$connect({
    serviceStack: this.serviceStack$,
    appVersion: this.appVersion$,
    generalTranslations: this.generalTranslations$,
    pipelineErrors: this.pipelineErrors$,
    createdByTypePrefix: this.createdByTypePrefix$,
    pipelineState: this.pipelineState$,
    logFailedRange: this.logFailedRange$ || of({ from: '', till: '' }),
    uniqBuildLogKey: this.uniqBuildLogKey$,
    uniqPrepareLogKey: this.uniqPrepareLogKey$,
    buildProcess: this.buildProcess$,
    buildLogParams: this.buildLogParams$,
    prepareLogParams: this.prepareLogParams$,
    buildProcessState: this.buildProcessState$,
    prepareProcessState: this.prepareProcessState$,
    oldNewContainersMap: this.oldNewContainersMap$,
    buildContainerStatus: this.buildContainerStatus$,
    runPrepareContainerStatus: this.runPrepareContainerStatus$
  });

  private _buildLogKey = `${FEATURE_NAME}_BUILD`;
  private _prepareLogKey = `${FEATURE_NAME}_PREPARE`;

  private _openCopyCodeDialogAction$ = this.onOpenCopyCodeDialog$.pipe(
    withLatestFrom(this.appVersion$),
    map(([ _, appVersion ]) => copyCodeDialogOpen(appVersion?.configContent, 'Pipeline config (zerops.yml)'))
  );

  private _onBuildCancelAction$ = this.onBuildCancel$.pipe(
    map((id) => buildCancel({ id }))
  );

  private _setupContainerListStreamAction$ = this.deployRunning$.pipe(
    withLatestFrom(this.serviceStack$),
    map(([ _, d ]) => d),
    distinctUntilKeysChanged([ 'id' ]),
    map((serviceStack) => this.#containerEntity.listSubscribe(
      serviceStack.project.clientId,
      {
        name: FEATURE_NAME,
        id: serviceStack.id
      },
      {
        search: [
          {
            name: 'serviceStackId',
            operator: 'eq',
            value: serviceStack.id
          }
        ]
      }
    ))
  );

  private _setupAddRemoveContainerMessageAction$ = this.deployRunning$.pipe(
    withLatestFrom(this.serviceStackId$),
    map(([ _, d ]) => d),
    distinctUntilChanged(),
    switchMap(() => this.#actions$.pipe(
      onWebsocketMessageDispatchAddRemoveEntities(
        this.#containerEntity,
        FEATURE_NAME,
        undefined,
        this.serviceStackId$
      )
    ))
  );

  private _setupContainerUpdateStreamAction$ = this.deployRunning$.pipe(
    withLatestFrom(this.serviceStack$),
    map(([ _, d ]) => d),
    distinctUntilKeysChanged([ 'id' ]),
    map((serviceStack) => this.#containerEntity.updateSubscribe(
      serviceStack.project.clientId,
      {
        search: [
          {
            name: 'serviceStackId',
            operator: 'eq',
            value: serviceStack.id
          }
        ]
      }
    ))
  );

  constructor() {
    super();

    this.$dispatchActions([
      this._openCopyCodeDialogAction$,
      this._onBuildCancelAction$,
      this._setupContainerListStreamAction$,
      this._setupAddRemoveContainerMessageAction$,
      this._setupContainerUpdateStreamAction$
    ]);

  }

  private _getKey(key: string, baseKey: string, id: string) {
    const base = `${key}_${id}`;
    if (!!baseKey) { return `${baseKey}_${base}`; }
    return base;
  }

}
