import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ZefFormBase, ZefFormsModule } from '@zerops/zef/forms';
import { RecurringTopUpConfigDialogFormState } from '../recurring-top-up-config-dialog.form';
import { MaterialFormStackModule } from '@zerops/zerops/core/material-form-stack';
import { FormSectionModule } from '@zerops/zui/form-section';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';

@Component({
  selector: 'z-recurring-top-up-config-form',
  templateUrl: './recurring-top-up-config-form.component.html',
  styleUrl: './recurring-top-up-config-form.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ZefFormsModule,
    MaterialFormStackModule,
    FormSectionModule,
    ZuiSeparatorComponent
  ]
})
export class RecurringTopUpConfigFormComponent extends ZefFormBase<RecurringTopUpConfigDialogFormState> {
}
