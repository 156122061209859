import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { PipelineRetriggerDialogFeature } from './pipeline-retrigger-dialog.feature';
import { PipelineRetriggerDialogEffect } from './pipeline-retrigger-dialog.effect';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { PipelineRetriggerFormComponent } from './components/pipeline-retrigger-form.component';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { TranslocoModule } from '@ngneat/transloco';
import { FormSectionModule } from '@zerops/zui/form-section';

@NgModule({
  declarations: [ PipelineRetriggerDialogFeature ],
  imports: [
    CommonModule,
    MaterialStackModule,
    EffectsModule.forFeature([ PipelineRetriggerDialogEffect ]),
    ZefDialogModule,
    ZuiSeparatorComponent,
    ZefScrollbarComponent,
    PipelineRetriggerFormComponent,
    ZefProgressErrorModule,
    TranslocoModule,
    FormSectionModule
  ],
  exports: [ PipelineRetriggerDialogFeature ]
})
export class PipelineRetriggerDialogModule {

}
