import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import { validate, FormState, FormControlState, FormGroupState, disable, enable, setValue } from 'ngrx-forms';
import { maxLength, pattern, required } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './common-user-service-stack-form.constant';

export interface CommonUserServiceStackFormValue {
  name: string;
  userData?: string;
  mode?: 'HA' | 'NON_HA';
  startWithoutCode?: boolean;
  buildFromGit?: string;
  buildFromGitSetup?: string;
  buildFromGitYaml?: string;
  zeropsYaml?: string;
  buildFromGitToggle?: boolean;
  enableSubdomainAccess?: boolean;
  zeropsYamlToggle?: boolean;
  customAutoscaling?: {
    horizontalAutoscaling?: {
      minContainerCount: number;
      maxContainerCount: number;
    };
    verticalAutoscaling?: {
      cpuMode: 'SHARED' | 'DEDICATED';
      startCpuCoreCount?: number;
      minFreeResource?: {
        cpuCoreCount?: number;
        cpuCorePercent?: number;
        memoryGBytes?: number;
        memoryPercent?: number;
      };
      maxResource: {
        cpuCoreCount: number;
        diskGBytes: number;
        memoryGBytes: number;
      };
      minResource: {
        cpuCoreCount: number;
        diskGBytes: number;
        memoryGBytes: number;
      };
    };
  }
}

export type CommonUserServiceStackFormState = FormState<CommonUserServiceStackFormValue>;

@Injectable({ providedIn: 'root' })
export class CommonUserServiceStackForm extends ZefForm<CommonUserServiceStackFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        name: '',
        mode: 'NON_HA',
        userData: '# Add secret variables in .env format: KEY=VAL\n# use ${KEY} to reference other variables on this service\n# and ${hostname_KEY} to reference variables from other services\n# add "#zeropsPreprocessor=on" without quotes to the very top to enable\n# preprocessing (https://docs.zerops.io/references/importyml/pre-processor)',
        customAutoscaling: {},
        startWithoutCode: false,
        buildFromGit: '',
        buildFromGitSetup: '',
        buildFromGitYaml: '',
        zeropsYaml: '',
        buildFromGitToggle: false,
        zeropsYamlToggle: false,
      },
      {
        name: validate(required, maxLength(25), pattern(/^[a-z0-9]*$/)),
        startWithoutCode: (s: FormControlState<boolean>, ps: FormGroupState<CommonUserServiceStackFormValue>) => {
          if (ps?.controls?.buildFromGitToggle.value === true || ps?.controls?.zeropsYamlToggle.value === true) {
            return setValue(disable(s), false);
          } else {
            return enable(s);
          }
        },
        buildFromGitToggle: (s: FormControlState<boolean>, ps: FormGroupState<CommonUserServiceStackFormValue>) => {
          if (ps?.controls?.zeropsYamlToggle.value === true || ps?.controls?.startWithoutCode.value === true) {
            return setValue(disable(s), false);
          } else if (s.isDisabled) {
            return enable(s);
          }

          return s;
        },
        zeropsYamlToggle: (s: FormControlState<boolean>, ps: FormGroupState<CommonUserServiceStackFormValue>) => {
          if (ps?.controls?.buildFromGitToggle.value === true || ps?.controls?.startWithoutCode.value === true) {
            return setValue(disable(s), false);
          } else if (s.isDisabled) {
            return enable(s);
          }

          return s;
        }
      }
    );
  }
}
