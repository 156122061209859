import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import { FormState } from 'ngrx-forms';
import { FEATURE_NAME } from './pipeline-retrigger-dialog.constant';

export interface PipelineRetriggerFormValue {
  userDataEnvFile?: string;
}

export type PipelineRetriggerFormState = FormState<PipelineRetriggerFormValue>;

@Injectable({ providedIn: 'root' })
export class PipelineRetriggerForm extends ZefForm<PipelineRetriggerFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        userDataEnvFile: ''
      },
      {}
    );
  }
}
