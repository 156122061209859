import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs';
import {
  ServiceStack,
  ServiceStackAutoscaling,
  ServiceStackBackup,
  ServiceStackModes,
  ServiceStackPort,
  ServiceStackUpdateData,
  TriggerPipelinePayload
} from '@zerops/models/service-stack';
import { Process } from '@zerops/models/process';
import { ContainerFilesAccess, ContainerFile } from './service-stack-base.model';
import { PipelineDebugModes, PipelineDebugPhases } from './service-stack-base.constant';

@Injectable({ providedIn: 'root' })
export class ServiceStackBaseApi {

  private _getUrl(url: string) {
    return 'https://' + url.split('GET')[1].trim();
  }

  constructor(private _http: HttpClient) { }

  getContainerFile$(serviceStackId: string, containerId: string, filePath: string) {
    return this._http.post<ContainerFilesAccess>(
      `/api/service-stack/${serviceStackId}/file-browsing-access`,
      { containerId }
    ).pipe(
      switchMap(({ readFileUrl }) => this._http.get<ContainerFile>(
        `${this._getUrl(readFileUrl)}&containerId=${containerId}&path=${filePath}`
      ))
    )
  }

  add$(data: any) {
    return this._http.post<ServiceStack & { process: Process; }>(
      `/api/service-stack/${data.version}`,
      {
        ...data
      }
    );
  }

  enableSubdomainAccess$(id: string) {
    return this._http.put<Process>(
      `/api/service-stack/${id}/enable-subdomain-access`,
      {}
    );
  }

  disableSubdomainAccess$(id: string) {
    return this._http.put<Process>(
      `/api/service-stack/${id}/disable-subdomain-access`,
      {}
    );
  }

  // -- start, stop, restart, reload
  triggerServiceStackOperation$(id: string, type: string) {
    return this._http.put<Process>(
      `/api/service-stack/${id}/${type}`,
      {}
    );
  }

  // -- connect / disconnect shared storage
  connectDisconnectSharedStorage$(stackId: string, sharedStorageId: string, type: 'connect' | 'disconnect') {
    return this._http.put<Process>(
      `/api/service-stack/${stackId}/${type}-shared-storage`,
      {
        sharedStorageId
      }
    );
  }

  // -- internal ports
  modifyInternalPorts$(id: string, ports: ServiceStackPort[]) {
    return this._http.put<Process>(
      `/api/service-stack/${id}/ports`,
      {
        ports
      }
    );
  }

  // -- update object storage size
  updateObjectStorageSettings$(
    serviceStackId: string,
    diskGBytes: number,
    policy?: string,
    rawPolicy?: string,
    cdnEnabled?: boolean
  ) {
    return this._http.put<Process>(
      `/api/service-stack/${serviceStackId}/object-storage-size`,
      {
        diskGBytes,
        policy,
        rawPolicy,
        cdnEnabled
      }
    );
  }

  // -- connect / disconnect with external repository
  externalRepositoryIntegration$(id: string, data: {
    gitLabIntegration?: any;
    gitHubIntegration?: any;
  }) {
    return this._http.put<{ success: boolean; }>(
      `/api/service-stack/${id}/external-repository-integration`,
      data
    );
  }

  // -- stack update
  stackUpdate$(id: string, typeVersion: string, data: ServiceStackUpdateData) {
    return this._http.put<Process>(
      `/api/service-stack/${typeVersion}/${id}`,
      data
    );
  }

  serviceStackExport$(id: string) {
    return this._http.get<{ yaml: string; }>(
      `/api/service-stack/${id}/export`
    );
  }

  serviceStackImport$(yaml: string, projectId: string) {
    return this._http.post<{ projectId: string; projectName: string; serviceStacks: ServiceStack[]; }>(
      '/api/service-stack/import',
      {
        yaml,
        projectId
      }
    );
  }

  adjustAutoscaling$(serviceStackId: string, mode: ServiceStackModes, customAutoscaling: ServiceStackAutoscaling) {
    return this._http.put<{ process: Process }>(
      `/api/service-stack/${serviceStackId}/autoscaling`,
      {
        mode,
        customAutoscaling
      }
    );
  }

  deleteBuildCache$(id: string) {
    return this._http.delete<{ success: boolean; }>(
      `/api/service-stack/${id}/build-cache`
    );
  }

  deleteRuntimePrepareCache$(id: string) {
    return this._http.put<{ success: boolean; }>(
      `/api/service-stack/${id}/next-app-version`,
      { useCustomRuntime: false }
    );
  }

  getBackups$(id: string) {
    return this._http.get<{ files: ServiceStackBackup[]; }>(
      `/api/service-stack/${id}/backup`
    );
  }

  setBackupFrequency$(id: string, backupPeriod: string) {
    return this._http.put<{ process: Process; }>(
      `/api/service-stack/${id}/backup-config`,
      backupPeriod === '' ? {} : { backupPeriod }
    );
  }

  createBackup$(id: string) {
    return this._http.post<{ success: boolean; async: boolean; }>(
      `/api/service-stack/${id}/backup`,
      {}
    );
  }

  deleteBackup$(id: string, name: string) {
    return this._http.delete<{ success: boolean; }>(
      `/api/service-stack/${id}/backup/${name}`
    );
  }

  downloadBackup$(id: string, name: string) {
    return this._http.get<string>(
      `/api/service-stack/${id}/backup/download/${name}`,
      {
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    );
  }

  pipelineDebugMode$(id: string, phase: PipelineDebugPhases, mode: PipelineDebugModes) {
    return this._http.put<Record<PipelineDebugPhases, PipelineDebugModes>>(
      `/api/service-stack/${id}/debug`,
      { [phase]: mode }
    );
  }

  triggerRedeploy$(id: string, userDataEnvFile?: string) {
    return this._http.put<{ process: Process; }>(
      `/api/service-stack/${id}/trigger-external-repository-integration`,
      { userDataEnvFile }
    );
  }

  triggerPipeline$(id: string, data: TriggerPipelinePayload) {
    return this._http.put<{ process: Process; }>(
      `/api/service-stack/${id}/trigger-pipeline`,
      data
    );
  }

}
