import { Component, OnDestroy } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { HttpRoutingForm } from '@zerops/zui/http-routing-form';
import { AppState } from '@zerops/zerops/app';
import { selectZefDialogState, zefDialogOpen, zefDialogClose } from '@zerops/zef/dialog';
import { HttpRoutingEntity, getCategorizedHttpRoutings } from '@zerops/zerops/core/http-routing-base';
import { HttpRoutingFieldsTranslations, HTTP_ROUTING_FIELDS_FEATURE_NAME } from '@zerops/zui/http-routing-fields';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import {
  map,
  withLatestFrom,
  filter,
  switchMap,
  startWith,
  distinctUntilChanged,
  concatMap
} from 'rxjs/operators';
import { unbox, box } from 'ngrx-forms';
import { EXISTING_DIALOG_KEY, FEATURE_NAME } from './http-routing-add-dialog.constant';
import { ProjectEntity, getProjectPorts, requestIPv4 } from '@zerops/zerops/core/project-base';
import { selectAvailableProjectAddons } from '@zerops/zerops/core/billing-base';
import uniqBy from 'lodash-es/uniqBy';
import {
  DnsCheckStatuses,
  HttpRoutingItemTranslations,
  HTTP_ROUTING_ITEM_FEATURE_NAME
} from '@zerops/zui/http-routing-item';
import { ProcessActions } from '@zerops/models/process';
import { ProcessEntity } from '@zerops/zerops/core/process-base';
import { selectActiveCurrency } from '@zerops/zerops/core/settings-base';
import { environment } from '@zerops/zerops/env';
import uniq from 'lodash-es/uniq';
import { HTTP_ROUTING_LIST_FEATURE_NAME } from '../http-routing-list';
import isEqual from 'lodash-es/isEqual';
import { httpRoutingEditDialogOpen } from '../http-routing-edit-dialog';
import { HttpRoutingAddDialogTranslations } from './http-routing-add-dialog.translations';
import { HttpRoutingAddDialogOpenMeta } from './http-routing-add-dialog.model';
import { ADDON_ACTIVATION_DIALOG_FEATURE_NAME } from '../addon-activation-dialog';

@Component({
  selector: 'z-http-routing-add-dialog',
  templateUrl: './http-routing-add-dialog.container.html',
  styleUrls: [ './http-routing-add-dialog.container.scss' ]
})
export class HttpRoutingAddDialogContainer extends ZefReactiveComponent implements OnDestroy {

  // # Event Streams
  onAdd$ = new Subject<void>();
  onDomainSelected$ = new Subject<string>();
  onAddEmptyLocation$ = new Subject<void>();
  onRemoveLocation$ = new Subject<number>();
  onCloseDialog$ = new Subject<void>();
  onCloseExistingDialog$ = new Subject<void>();
  onOpenEditDialog$ = new Subject<void>();
  onOpenAddonActivationDialog$ = new Subject<void>();

  // # Forms
  formState$ = this._httpRoutingForm.state$;

  // # Data
  // -- sync
  dialogKey = FEATURE_NAME;
  addKey = this._httpRoutingEntity.addOne.type;
  existingDialogKey = EXISTING_DIALOG_KEY;
  requestIpv4Key = requestIPv4.type;
  sharedIpV4 = environment.sharedIPv4;

  // -- async
  dialogState$ = this._store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((data) => data.state));
  metaData$ = this.dialogState$.pipe(
    map((data) => data.meta as HttpRoutingAddDialogOpenMeta),
    filter((d) => !!d)
  );
  projectId$ = this.metaData$.pipe(
    map(({ projectId }) => projectId),
    distinctUntilChanged()
  );
  serviceStackId$ = this.metaData$.pipe(
    map(({ serviceStackId }) => serviceStackId),
    distinctUntilChanged()
  );
  project$ = this.projectId$.pipe(
    filter((id) => !!id),
    switchMap((id) => this._projectEntity.entityByIdWithServiceStacks$(id)),
    filter((id) => !!id)
  );
  projectHttpPorts$ = this.project$.pipe(
    withLatestFrom(this.serviceStackId$.pipe(startWith(undefined as string))),
    map(([ project, serviceStackId ]) => getProjectPorts(project, serviceStackId, true)
  ));
  allProjectHttpRoutings$ =  this.projectId$.pipe(
    // FIXME TODO: make sure this list is loaded,
    // add doesn't neccessarily need to be added from
    // the same place list is from
    switchMap((id) => this._httpRoutingEntity
      .list$({ name: HTTP_ROUTING_LIST_FEATURE_NAME, id })
      .pipe(filter((d) => !!d))
    )
  );
  allProjectHttpRoutingsWithoutSubdomains$ = this.allProjectHttpRoutings$.pipe(
    map((items) => items.filter((itm) => !!itm.isEditable))
  );
  hasDnsFailedHttpRouting$ = this.allProjectHttpRoutingsWithoutSubdomains$.pipe(
    map((items) => items.reduce((res, itm) => {

      if (!res && itm.domains.some((d) => d.dnsCheckStatus === DnsCheckStatuses.Failed || d.dnsCheckStatus === DnsCheckStatuses.Pending)) {
        res = true;
      }

      return res;
    }, false))
  );
  domainBlacklist$ = this.allProjectHttpRoutings$.pipe(
    map((items) => items.reduce((arr: string[], itm) => {
      arr = [ ...arr, ...itm.domains.map((dmn) => dmn.domainName) ];
      return arr;
    }, [])),
    distinctUntilChanged((a, b) => isEqual(a, b))
  );
  editingExistingDialogState$ = this._store.pipe(select(selectZefDialogState(this.existingDialogKey)));
  editingExistingDialogOpen$ = this.editingExistingDialogState$.pipe(map((d) => d.state));
  editingExistingDialogData$ = this.editingExistingDialogState$.pipe(map((d) => d.meta));
  httpRoutingFieldsTranslations$ = this.translate$<HttpRoutingFieldsTranslations>(HTTP_ROUTING_FIELDS_FEATURE_NAME);
  httpRoutingItemTranslations$ = this.translate$<HttpRoutingItemTranslations>(HTTP_ROUTING_ITEM_FEATURE_NAME);
  translations$ = this.translate$<HttpRoutingAddDialogTranslations>(FEATURE_NAME);
  ipv4Addon$ = combineLatest([
    this._store.pipe(select(selectAvailableProjectAddons)),
    this.projectId$
  ]).pipe(
    filter(([  d, id ]) => !!d && !!id),
    map(([ d, id ]) => d.find((addon) => addon.metricId === 'PROJECT_IPV4' && addon.projectId === id))
  );
  activeCurrency$ = this._store.pipe(select(selectActiveCurrency));
  hasIpV4AddonActivationProcess$ = this._processEntity.list$().pipe(
    withLatestFrom(this.projectId$),
    filter(([ d, id ]) => d && !!id),
    map(([ d, id ]) => d.some((p) => p.projectId === id && p.actionName === ProcessActions.ipV4AddonActivation))
  );

  // # Action Streams
  private _addAction$ = this.onAdd$.pipe(
    withLatestFrom(
      this.projectId$,
      this.formState$.pipe(map((s) => s.value))
    ),
    map(([ _, projectId, { domains, sslEnabled, locations } ]) => this._httpRoutingEntity.addOne({
      sslEnabled,
      // add first location as host and others as aliases
      domains: unbox(domains),
      locations: locations.map((loc) => ({
        ...loc,
        ...unbox(loc.portData)
      })),
      projectId
    }))
  );

  private _existingDomainEditDialogOpenAction$ = this.onDomainSelected$.pipe(
    withLatestFrom(this.allProjectHttpRoutings$),
    filter(([ _, httpRoutings ]) => !!httpRoutings && !!httpRoutings.length),
    map(([ domain, httpRoutings ]) => {
      const foundDomain = httpRoutings.find(
        (routing) => !!routing.domains.find((d) => d.domainName === domain)
      );
      if (foundDomain) {
        const data = getCategorizedHttpRoutings([ foundDomain ]);
        const res = [
          ...data.external,
          ...data.local
        ][0];
        return res;
      }
    }),
    concatMap((meta) => [
      zefDialogClose({ key: this.dialogKey, meta: { preventReset: true } }),
      zefDialogOpen({
        key: this.existingDialogKey,
        meta
      })
    ])
  );

  // FIXME TODO: put to utils
  private _mergeFormValueAction$ = this.onOpenEditDialog$.pipe(
    withLatestFrom(
      this.editingExistingDialogData$.pipe(filter((d) => !!d)),
      this.formState$.pipe(map((d) => d.value)),
      this.serviceStackId$,
      this.projectId$
    ),
    map(([
      _,
      { domains, sslEnabled, cdnEnabled, locations, id },
      formVal,
      serviceStackId,
      projectId
    ]) => {

      const uniqueDomains = uniq([
        ...domains.map((d: any) => d.domainName),
        ...unbox(formVal.domains)
      ]).map((domainName) => ({ domainName }));

      const uniqueLocations = uniqBy([
        ...locations.map((loc: any) => ({ ...loc, portData: { port: loc.port, serviceStackId: loc.serviceStackId } })),
        ...formVal
          .locations
          .filter((loc) => !!loc.path)
          .map((loc) => ({ ...loc, ...unbox(loc.portData) }))
      ], (itm) => `${itm.path}`);

      const data = {
        id,
        domains: uniqueDomains,
        sslEnabled,
        cdnEnabled,
        locations: uniqueLocations
      } as any;

      return httpRoutingEditDialogOpen(
        data,
        projectId,
        serviceStackId
      );

    }),
    // fill in edit form and close add dialog (prevent reset of shared http form)
    switchMap((action) => [
      action,
      zefDialogClose({ key: this.dialogKey, meta: { preventReset: true } }),
      zefDialogClose({ key: this.existingDialogKey })
    ])
  );

  private _closeExistingDialogAction$ = this.onCloseExistingDialog$.pipe(
    withLatestFrom(
      this.editingExistingDialogData$,
      this.formState$.pipe(map((s) => s.value))
    ),
    concatMap(([ _, { domain }, formVal ]) => [
      zefDialogOpen({ key: this.dialogKey }),
      this._httpRoutingForm.setValue({
        ...formVal,
        domains: box(unbox(formVal.domains).filter((d) => d !== domain))
      }),
      zefDialogClose({ key: this.existingDialogKey })
    ])
  );

  private _closeDialogAction$ = this.onCloseDialog$.pipe(
    map(() => zefDialogClose({ key: this.dialogKey }))
  );

  private _addEmptyLocationAction$ = this.onAddEmptyLocation$.pipe(
    withLatestFrom(this.projectHttpPorts$),
    map(([ _, httpPortsWithServiceStackInfo ]) => this._httpRoutingForm.addArrayControl(
      'locations',
      {
        path: '/',
        // if there's only one port in the first (active service) item
        // copy its port and serviceStackId
        portData: httpPortsWithServiceStackInfo?.[0].length === 1
          ? box(httpPortsWithServiceStackInfo[0][0])
          : undefined
      }
    ))
  );

  private _removeLocationAction$ = this.onRemoveLocation$.pipe(
    map((d) => this._httpRoutingForm.removeArrayControl('locations', d))
  );

  private _openAddonActivationDialogAction$ = this.onOpenAddonActivationDialog$.pipe(
    withLatestFrom(
      this.project$,
      this.ipv4Addon$
    ),
    map(([ _, { name }, addon ]) => zefDialogOpen({
      key: ADDON_ACTIVATION_DIALOG_FEATURE_NAME,
      meta: {
        addon: {
          ...addon,
          projectName: name
        }
      }
    }))
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    metaData: this.metaData$,
    project: this.project$,
    translations: this.translations$,
    formState: this.formState$,
    projectHttpPorts: this.projectHttpPorts$,
    domainBlacklist: this.domainBlacklist$,
    httpRoutingItemTranslations: this.httpRoutingItemTranslations$,
    editingExistingDialogOpen: this.editingExistingDialogOpen$,
    editingExistingDialogData: this.editingExistingDialogData$,
    httpRoutingFieldsTranslations: this.httpRoutingFieldsTranslations$,
    allProjectHttpRoutingsWithoutSubdomains: this.allProjectHttpRoutingsWithoutSubdomains$,
    hasDnsFailedHttpRouting: this.hasDnsFailedHttpRouting$,
    ipv4Addon: this.ipv4Addon$,
    hasIpV4AddonActivationProcess: this.hasIpV4AddonActivationProcess$
  });

  constructor(
    private _store: Store<AppState>,
    private _httpRoutingForm: HttpRoutingForm,
    private _httpRoutingEntity: HttpRoutingEntity,
    private _projectEntity: ProjectEntity,
    private _processEntity: ProcessEntity
  ) {
    super();

    // # Dispatcher
    this.$dispatchActions([
      this._addAction$,
      this._mergeFormValueAction$,
      this._addEmptyLocationAction$,
      this._closeDialogAction$,
      this._closeExistingDialogAction$,
      this._removeLocationAction$,
      this._existingDomainEditDialogOpenAction$,
      this._openAddonActivationDialogAction$
    ]);
  }

}
