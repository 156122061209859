import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import { FormControlState, FormGroupState, FormState, setErrors, validate } from 'ngrx-forms';
import { FEATURE_NAME } from './recurring-top-up-config-dialog.constant';
import { greaterThanOrEqualTo, required } from 'ngrx-forms/validation';

export interface RecurringTopUpConfigDialogFormValue {
  autoChargePeriod: 'WEEK' | 'MONTH' | 'DISABLE';
  maximumChargeAmount: number;
}

export type RecurringTopUpConfigDialogFormState = FormState<RecurringTopUpConfigDialogFormValue>;

const validateOnAutoChargePeriod = (s: FormControlState<number>, ps: FormGroupState<RecurringTopUpConfigDialogFormValue>) => {
  if (ps?.controls?.autoChargePeriod?.value !== 'DISABLE') {
    return validate<number>(required, greaterThanOrEqualTo(10))(s);
  } else {
    return setErrors(s, {})
  }
};

@Injectable({ providedIn: 'root' })
export class RecurringTopUpConfigDialogForm extends ZefForm<RecurringTopUpConfigDialogFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        autoChargePeriod: 'DISABLE',
        maximumChargeAmount: undefined
      },
      {
        maximumChargeAmount: validateOnAutoChargePeriod
      }
    );
  }
}
