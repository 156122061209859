<!--
  TODO:
  https://github.com/angular/angular/issues/36222
  contain <router-outlet> as well when issue resolved
-->
<div class="__app-bar-wrap" [class.is-compact]="state.isCompact">
  @if (state.authState !== authStates.Checking && state.activeClientUser) {
    <z-app-bar
      [active]="state.authState === this.authStates.Authorized"
      [logoTheme]="menuPanelOpen ? 'white' : 'black'"
      [menuState]="menuPanelOpen"
      class="__app-bar">
    </z-app-bar>
  }
</div>

<div class="u-position--relative">
  <div
    [@outerRouteAnimation]="prepareRoute(outlet)"
    class="__animation-wrap">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

<z-pop-error
  *zefPopErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance"
/>

<zui-snack-error
  *zefSnackErrorOutlet="let data = data; let instance = instance;"
  [data]="data"
  [instance]="instance"
/>

<zui-snack-success
  *zefSnackOutlet="let data = data; let instance = instance; type: 'success'"
  [data]="data"
  [instance]="instance"
/>

<zui-snack-warning
  *zefSnackOutlet="let data = data; let instance = instance; type: 'warning'"
  [data]="data"
  [instance]="instance"
/>

<zui-snack-info
  *zefSnackOutlet="let data = data; let instance = instance; type: 'info'"
  [data]="data"
  [instance]="instance"
/>

<z-dialog-error *zefDialogErrorOutlet="let data = data" [data]="data" />

<z-notification />

<zef-dialog-error />

<z-version-warning *ngIf="isProduction && !hideVersionWarning" />

<zef-recaptcha [siteKey]="googleSiteKey" />


<z-pipeline-detail-dialog />

<z-deletion-warning-dialog />

<z-http-routing-add-dialog />

<z-http-routing-edit-dialog />

<z-port-routing-add-dialog />

<z-project-cost-limit-dialog />

<z-port-routing-edit-dialog />

<z-user-data-add-dialog />

<z-project-env-add-dialog />

<z-user-data-edit-dialog />

<z-project-envs-edit-dialog />

<z-project-env-edit-dialog />

<z-env-format-dialog />

<z-copy-code-dialog />

<z-import-export-dialog />

<z-deploy-shutdown-dialog />

<z-addon-activation-dialog />

<z-log-dialog />

<z-sync-dialog />

<z-billing-info-edit-dialog />

<z-notifications-pop />

<z-processes-pop />

<z-top-up-dialog />

<zui-contextual-dialog [documentationPath]="documentationPath" />

<zui-web-notifications-pop />

<zui-web-public-routing-pop />

<zui-web-service-stack-active-app-version-pop />

<z-new-service-dialog />

<zui-docs-pop [documentationPath]="documentationPath" />

@if (state.authState === this.authStates.Authorized)  {
  <z-ws-reconnect
    *ngIf="state.wsReconnectShow"
    class="__ws-reconnect"
    [state]="state.wsReconnectState"
    [translations]="state.wsReconnectTranslations"
  />
}

<z-websocket-debugger />

<z-recurring-top-up-config-dialog />

<z-pipeline-trigger-dialog />

<z-pipeline-retrigger-dialog />
