<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{ maxWidth: '840px', width: '100%' }"
  (closeTrigger)="onClose$.next()">
  <div class="fx:relative">

    <zef-scrollbar
      class="__scroll"
      [showScrollButtonsV]="true"
      maxHeight="calc(100vh - 140px)"
      height="auto">
      <div class="__content">
        <z-pipeline-retrigger-form
          #formRef
          (submit)="onSubmit$.next()"
          (prefillEnvs)="onPrefillEnvs$.next()"
          [state]="state.formState"
          [activeAppVersion]="state.activeAppVersion"
        />
      </div>
    </zef-scrollbar>

    <div class="__buttons">
      <div class="__buttons-grid">

        <div>
          <zef-progress-error
            full
            [disabled]="!state.formState.isValid"
            [key]="actionKey">
            <button
              class="c-button--full"
              color="accent"
              [disabled]="!state.formState.isValid"
              mat-raised-button
              type="button"
              (click)="formRef?.formRef?.triggerSubmit()">
              Re-trigger pipeline
            </button>
          </zef-progress-error>
        </div>

        <div>
          <button
            (click)="onClose$.next()"
            class="c-button--full"
            type="button"
            mat-button>
            <span transloco="general.close"></span>
          </button>
        </div>

      </div>
    </div>

  </div>
</zef-dialog>
