import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { firstAvailable } from '@zerops/zef/core';
import { onWebsocketMessageDispatchAddRemoveEntities, onWebsocketMessageDispatchUpdateEntities } from '@zerops/zef/entities';
import { ZefSnackService } from '@zerops/zef/snack';
import { of } from 'rxjs';
import { replaceEmptyStringsWithNull } from './client-base.utils';
import {
  catchError,
  map,
  switchMap
} from 'rxjs/operators';
import { UserEntity } from '../user-base';
import {
  billingInfo,
  billingInfoFail,
  billingInfoSuccess,
  ClientBaseActionUnion,
  removeClientAvatar,
  removeClientAvatarFail,
  removeClientAvatarSuccess,
  setClientAvatar,
  setClientAvatarFail,
  setClientAvatarSuccess,
  updateAccountInfo,
  updateAccountInfoFail,
  updateAccountInfoSuccess,
  updateBillingInfo,
  updateBillingInfoFail,
  updateBillingInfoSuccess
} from './client-base.action';
import { ClientBaseApi } from './client-base.api';
import { FEATURE_NAME } from './client-base.constant';
import { ClientEntity } from './client-base.entity';

@Injectable()
export class ClientBaseEffect {

  private _setupListStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._clientEntity.listSubscribe({ name: 'id', clientId }))
  ));

  private _setupAddRemoveMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchAddRemoveEntities(this._clientEntity)
  ));

  private _setupUpdateStreamSubscription$ = createEffect(() => this._userEntity.activeClientId$.pipe(
    map((clientId) => this._clientEntity.updateSubscribe({ name: 'id', clientId })
  )));

  private _onUpdateStreamMessage$ = createEffect(() => this._actions$.pipe(
    onWebsocketMessageDispatchUpdateEntities(this._clientEntity)
  ));

  private _onBillingInfo$ = createEffect(() => this._actions$.pipe(
    ofType(billingInfo),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((id) => this._api
        .billingInfo$(id)
        .pipe(
          map((res) => billingInfoSuccess(res, action)),
          catchError((err) => of(billingInfoFail(err, action)))
        )
      )
    )
  )));

  private _onUpdateBillingInfo$ = createEffect(() => this._actions$.pipe(
    ofType(updateBillingInfo),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((id) => {
        // Transform the data before sending it to the API
        const transformedData = replaceEmptyStringsWithNull(action.data);
        if (transformedData.vatPayer === false) {
          transformedData.vatNumber = null;
        }

        return this._api
          .updateBillingInfo$(id, transformedData)
          .pipe(
            map((res) => updateBillingInfoSuccess(res, action, action.meta)),
            catchError((err) => of(updateBillingInfoFail(err, action)))
          );
      })
    )
  )));

  private _onUpdateAccountInfo$ = createEffect(() => this._actions$.pipe(
    ofType(updateAccountInfo),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((id) => this._api
        .updateAccountInfo$(id, action.data)
        .pipe(
          map((res) => updateAccountInfoSuccess(res, action)),
          catchError((err) => of(updateAccountInfoFail(err, action)))
        )
      )
    )
  )));

  private _onSetAvatar$ = createEffect(() => this._actions$.pipe(
    ofType(setClientAvatar),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((id) => this._api
        .setAvatar$(id, action.data)
        .pipe(
          map((res) => setClientAvatarSuccess(res, action)),
          catchError((err) => of(setClientAvatarFail(err, action)))
        )
      )
    ))
  ));

  private _onSetAvatarSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(setClientAvatarSuccess),
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.setAvatarSuccess` }))
  ), { dispatch: false });

  private _onRemoveAvatar$ = createEffect(() => this._actions$.pipe(
    ofType(removeClientAvatar),
    switchMap((action) => this._userEntity.activeClientId$.pipe(
      firstAvailable(),
      switchMap((id) => this._api
        .removeAvatar$(id)
        .pipe(
          map((res) => removeClientAvatarSuccess(res, action)),
          catchError((err) => of(removeClientAvatarFail(err, action)))
        )
      )
    ))
  ));

  private _onRemoveAvatarSuccessNotification$ = createEffect(() => this._actions$.pipe(
    ofType(removeClientAvatarSuccess),
    switchMap(() => this._snack.success$({ translation: `${FEATURE_NAME}.removeAvatarSuccess` }))
  ), { dispatch: false });

  constructor(
    private _userEntity: UserEntity,
    private _clientEntity: ClientEntity,
    private _actions$: Actions<ClientBaseActionUnion>,
    private _api: ClientBaseApi,
    private _snack: ZefSnackService
  ) { }

}
