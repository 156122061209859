import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ZefDialogModule } from '@zerops/zef/dialog';
import { MaterialStackModule } from '@zerops/zerops/core/material-stack';
import { PipelineTriggerDialogFeature } from './pipeline-trigger-dialog.feature';
import { PipelineTriggerDialogEffect } from './pipeline-trigger-dialog.effect';
import { ZuiSeparatorComponent } from '@zerops/zui/separator';
import { ZefScrollbarComponent } from '@zerops/zef/scrollbar';
import { PipelineTriggerFormComponent } from './components/pipeline-trigger-form.component';
import { ZefProgressErrorModule } from '@zerops/zef/progress-error';
import { TranslocoModule } from '@ngneat/transloco';
import { FormSectionModule } from '@zerops/zui/form-section';

@NgModule({
  declarations: [ PipelineTriggerDialogFeature ],
  imports: [
    CommonModule,
    MaterialStackModule,
    EffectsModule.forFeature([ PipelineTriggerDialogEffect ]),
    ZefDialogModule,
    ZuiSeparatorComponent,
    ZefScrollbarComponent,
    PipelineTriggerFormComponent,
    ZefProgressErrorModule,
    TranslocoModule,
    FormSectionModule
  ],
  exports: [ PipelineTriggerDialogFeature ]
})
export class PipelineTriggerDialogModule {

}
