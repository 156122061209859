import { createAction, union } from '@ngrx/store';
import { AppVersion } from '@zerops/models/app-version';
import { PipelineError } from '@zerops/models/error-backend';

export const pipelineDetailDialogOpen = createAction(
  '[@zerops/zerops/feature/pipeline-detail-dialog] open',
  (data: { appVersion: AppVersion; pipelineErrors?: PipelineError[]; }) => (data)
);

const all = union({ pipelineDetailDialogOpen });

export type PipelineDetailDialogActionUnion = typeof all;
