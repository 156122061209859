import { createAction, union } from '@ngrx/store';
import {
  zefActionPayload,
  zefActionFailPayload,
  zefActionSuccessPayload
} from '@zerops/zef/core';

/**
 * Download a file containing a monthly report of credit consumption.
 */
export const exportCreditConsumption = createAction(
  '[@zerops/zerops/core/payment-base] export-credit-consumption',
  zefActionPayload<{ month: string; }>()
);

export const exportCreditConsumptionSuccess = createAction(
  '[@zerops/zerops/core/payment-base] export-credit-consumption/success',
  zefActionSuccessPayload<{ file: ArrayBuffer, contentType: string, contentDisposition: string, month: string }>()
);

export const exportCreditConsumptionFail = createAction(
  '[@zerops/zerops/core/payment-base] export-credit-consumption/fail',
  zefActionFailPayload
);

const all = union({
  exportCreditConsumption,
  exportCreditConsumptionSuccess,
  exportCreditConsumptionFail
});

export type PaymentBaseActionUnion = typeof all;
