import { Component, OnDestroy } from '@angular/core';
import { ZefReactiveComponent } from '@zerops/zef/core';
import { AppState } from '@zerops/zerops/app';
import { HttpRoutingEntity } from '@zerops/zerops/core/http-routing-base';
import { HttpRoutingForm } from '@zerops/zui/http-routing-form';
import { selectZefDialogState, zefDialogClose } from '@zerops/zef/dialog';
import { MergeStrategy } from '@zerops/zef/entities';
import { formValueOnValid } from '@zerops/zef/forms';
import {
  ProjectEntity,
  getProjectPorts
} from '@zerops/zerops/core/project-base';
import { HTTP_ROUTING_LIST_FEATURE_NAME } from '@zerops/zerops/feature/http-routing-list';
import {
  HttpRoutingFieldsTranslations,
  HTTP_ROUTING_FIELDS_FEATURE_NAME
} from '@zerops/zui/http-routing-fields';
import { ZefSnackService } from '@zerops/zef/snack';
import { select, Store } from '@ngrx/store';
import { unbox, box } from 'ngrx-forms';
import { Subject } from 'rxjs';
import {
  map,
  filter,
  withLatestFrom,
  switchMap,
  distinctUntilChanged,
  takeUntil,
  startWith
} from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';
import { FEATURE_NAME } from './http-routing-edit-dialog.constant';
import { HttpRoutingEditDialogOpenMeta } from './http-routing-edit-dialog.model';
import { ThemeService } from '@zerops/zef/theme';

@Component({
  selector: 'z-http-routing-edit-dialog',
  templateUrl: './http-routing-edit-dialog.container.html',
  styleUrls: [ './http-routing-edit-dialog.container.scss' ]
})
export class HttpRoutingEditDialogContainer extends ZefReactiveComponent implements OnDestroy {

  // # Event Streams
  onAddEmptyLocation$ = new Subject<void>();
  onRemoveLocation$ = new Subject<number>();
  onDomainBlacklisted$ = new Subject<string>();
  onEdit$ = new Subject<void>();
  onClose$ = new Subject<void>();

  // # Forms
  formState$ = this._httpRoutingForm.state$;

  // # Data
  // -- sync
  dialogKey = FEATURE_NAME;
  editKey = this._httpRoutingEntity.updateOne.type;

  // -- async
  dialogState$ = this._store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((data) => data.state));
  metaData$ = this.dialogState$.pipe(
    map((data) => data.meta as HttpRoutingEditDialogOpenMeta),
    filter((d) => !!d)
  );
  projectId$ = this.metaData$.pipe(
    map(({ projectId }) => projectId),
    distinctUntilChanged()
  );
  serviceStackId$ = this.metaData$.pipe(
    map(({ serviceStackId }) => serviceStackId),
    distinctUntilChanged()
  );
  project$ = this.projectId$.pipe(
    filter((id) => !!id),
    switchMap((id) => this._projectEntity.entityByIdWithServiceStacks$(id)),
    filter((id) => !!id)
  );
  domainBlacklist$ = this.projectId$.pipe(
    switchMap((id) => this._httpRoutingEntity
      .list$({ name: HTTP_ROUTING_LIST_FEATURE_NAME, id })
      .pipe(
        filter((d) => !!d),
        map((items) => items.reduce((arr: string[], itm) => {
          arr = [ ...arr, ...itm.domains.map((dmn) => dmn.domainName) ];
          return arr;
        }, []))
      )
    ),
    distinctUntilChanged((a, b) => isEqual(a, b))
  );
  projectHttpPorts$ = this.project$.pipe(
    withLatestFrom(this.serviceStackId$.pipe(startWith(undefined as string))),
    map(([ project, serviceStackId ]) => getProjectPorts(project, serviceStackId, true)
  ));
  httpRoutingFieldsTranslations$ = this.translate$<HttpRoutingFieldsTranslations>(
    HTTP_ROUTING_FIELDS_FEATURE_NAME
  );

  // # State resolver
  state = this.$connect({
    open: this.open$,
    projectHttpPorts: this.projectHttpPorts$,
    domainBlacklist: this.domainBlacklist$,
    formState: this.formState$,
    httpRoutingFieldsTranslations: this.httpRoutingFieldsTranslations$,
    isZen: this._theme.isZen$
  });

  // # Action Streams
  private _editAction$ = this.onEdit$.pipe(
    formValueOnValid(this._httpRoutingForm),
    map((d) => this._httpRoutingEntity.updateOne(
      d.id,
      {
        domains: unbox(d.domains),
        locations: d.locations.map((loc) => ({
          ...loc,
          ...unbox(loc.portData)
        })),
        sslEnabled: d.sslEnabled,
        cdnEnabled: d.cdnEnabled
      },
      {
        zefEntityMergeStrategy: {
          aliases: MergeStrategy.KeepNew,
          locations: MergeStrategy.KeepNew
        }
      }
    ))
  );

  private _addEmptyLocationAction$ = this.onAddEmptyLocation$.pipe(
    withLatestFrom(this.projectHttpPorts$),
    map(([ _, httpPortsWithServiceStackInfo ]) => this._httpRoutingForm.addArrayControl(
      'locations',
      {
        path: '/',
        // if there's only one port in the first (active service) item
        // copy its port and serviceStackId
        portData: httpPortsWithServiceStackInfo?.[0].length === 1
          ? box(httpPortsWithServiceStackInfo[0][0])
          : undefined
      }
    ))
  );

  private _removeLocationAction$ = this.onRemoveLocation$.pipe(
    map((index) => this._httpRoutingForm.removeArrayControl('locations', index))
  );

  private _closeAction$ = this.onClose$.pipe(
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  );

  constructor(
    private _store: Store<AppState>,
    private _httpRoutingEntity: HttpRoutingEntity,
    private _httpRoutingForm: HttpRoutingForm,
    private _projectEntity: ProjectEntity,
    private _snackService: ZefSnackService,
    private _theme: ThemeService
  ) {
    super();

    this.onDomainBlacklisted$
      .pipe(
        switchMap((domain) => this._snackService.info$({
          text: `${domain} name already exists, it can not be added to another domain access.`,
          actionButtonText: 'Okay, close'
        })),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    this.$dispatchActions(
      [
        this._editAction$,
        this._addEmptyLocationAction$,
        this._removeLocationAction$,
        this._closeAction$
      ]
    );
  }

}
