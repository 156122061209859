import { createActionGroup, props } from '@ngrx/store';
import { FEATURE_NAME } from './recurring-top-up-config-dialog.constant';
import { ClientStatusPaymentInfo } from '@zerops/models/client-status';

export const recurringTopUpConfigDialogActions = createActionGroup({
  source: FEATURE_NAME,
  events: {
    'open': props<{ data: ClientStatusPaymentInfo; }>()
  }
});
