import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ZefReactiveComponent, escapeEnvContent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import {
  Subject,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { AppState } from '@zerops/zerops/app';
import { FEATURE_NAME } from './pipeline-retrigger-dialog.constant';
import { PipelineRetriggerForm } from './pipeline-retrigger-dialog.form';
import { formValueOnValid } from '@zerops/zef/forms';
import { ServiceStackEntity, triggerRedeploy } from '@zerops/zerops/core/service-stack-base';
import { AppVersionEntity } from '@zerops/zerops/core/app-version-base';
import { AppVersion } from '@zerops/models/app-version';
import { pipelineDetailDialogOpen } from '../pipeline-detail-dialog';
import { UserDataEntity } from '@zerops/zerops/core/user-data-base';

@Component({
  selector: 'z-pipeline-retrigger-dialog',
  templateUrl: './pipeline-retrigger-dialog.feature.html',
  styleUrl: './pipeline-retrigger-dialog.feature.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PipelineRetriggerDialogFeature extends ZefReactiveComponent {

  // # Deps
  #store = inject<Store<AppState>>(Store);
  #form = inject(PipelineRetriggerForm);
  #serviceStackEntity = inject(ServiceStackEntity);
  #appVersionEntity = inject(AppVersionEntity);
  #userDataEntity = inject(UserDataEntity);

  // # Event Streams
  onClose$ = new Subject<void>();
  onSubmit$ = new Subject<void>();
  onPrefillEnvs$ = new Subject<void>();
  onOpenPipelineDetailDialog$ = new Subject<{ appVersion: AppVersion; }>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  actionKey = triggerRedeploy.type;

  // -- async
  dialogState$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((data) => data?.state));
  id$ = this.dialogState$.pipe(map((data) => data?.meta));
  service$ = this.id$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    switchMap((d) => this.#serviceStackEntity.entityById$(d).pipe(filter((d) => !!d)))
  );
  activeAppVersionId$ = this.service$.pipe(
    map((d) => d.activeAppVersion?.id),
    filter((d) => !!d)
  );
  userData$ = this.service$.pipe(
    switchMap((service) => this.#userDataEntity.list$({
      name: this.key,
      id: service.id
    }).pipe(filter((d) => !!d?.length)))
  );
  activeAppVersion$ = this.activeAppVersionId$.pipe(
    withLatestFrom(this.service$),
    switchMap(([ id, service ]) => this.#appVersionEntity.list$({
      name: this.key,
      id: service.id
    }).pipe(
      filter((d) => !!d?.length),
      map((d) => d.find((itm) => itm.id === id)),
      filter((d) => !!d)
    ))
  );
  formState$ = this.#form.state$;
  formValue$ = this.#form.value$;

  // # State resolver
  state = this.$connect({
    open: this.open$,
    formState: this.formState$,
    activeAppVersion: this.activeAppVersion$
  });

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([

      this.onClose$.pipe(
        map(() => zefDialogClose({ key: FEATURE_NAME }))
      ),

      this.onSubmit$.pipe(
        formValueOnValid(this.#form),
        withLatestFrom(this.id$),
        map(([ data, id ]) => {
          return triggerRedeploy({
            id,
            env: data.userDataEnvFile
          }, { type: 'pop' });
        })
      ),

      this.onPrefillEnvs$.pipe(
        withLatestFrom(this.userData$, this.service$),
        map(([ _, userData, service ]) => {
          const value = userData
            ?.filter((itm) => itm.type === 'SECRET' && itm.serviceStackId === service.id)
            ?.reduce((str, itm) => {
              const formattedContent = escapeEnvContent(itm.content);
              return str + `${itm.key}=${formattedContent}\n`;
            }, '');
            if (!value) {
            return false as any;
          }
          return this.#form.setValue(
            'userDataEnvFile',
            value
          );
        }),
        filter((d) => !!d)
      ),

      this.onOpenPipelineDetailDialog$.pipe(
        map(({ appVersion }) => pipelineDetailDialogOpen({ appVersion }))
      )

    ]);
  }

}
