export const CUSTOM_ICONS = [
  'google',
  'github',
  'twitter',
  'gitlab',
  'discord',
  'youtube',
  'linkedin',
  'commit',
  'branch',
  'mariadb',
  'keydb',
  'opensearch',
  'python',
  'java',
  'visa',
  'stripe',
  'angular',
  'nestjs',
  'mongodb',
  'laravel',
  'nette',
  'react',
  'vuejs',
  'nodejs',
  'go',
  'php',
  'nginx',
  'mysql',
  'postgresql',
  'redis',
  'rabbitmq',
  'elasticsearch',
  'strapi',
  'nextjs',
  'nuxtjs',
  'express',
  'meteor',
  'adonisjs',
  'nx',
  'lxc',
  'mastercard',
  'american-express',
  'discover',
  'rust',
  'dotnet',
  'linux',
  'ubuntu',
  'alpine',
  'vue',
  'svelte',
  'remix',
  'astro',
  'solid',
  'umami',
  'django',
  'discourse',
  'x',
  'bun',
  'deno',
  'ghost',
  'payload',
  'contributte',
  'echo',
  'qwik',
  'telegram',
  'slack',
  'twitch',
  'whatsapp',
  'trello',
  'analog',
  'spring',
  'symfony',
  'gleam',
  'medusa',
  'elixir',
  'phoenix',
  'filament',
  'twill',
  'laravel-jetstream',
  'directus',
  'meilisearch',
  'nats',
  'qdrant',
  'sqlite',
  'valkey',
  'typesense'
];
