<sat-popover
  #popRef
  [horizontalAlign]="horizontalAlign"
  [verticalAlign]="verticalAlign"
  hasBackdrop
  backdropClass="zef-pop-light-backdrop"
  (backdropClicked)="popRef.close(); closeTrigger.emit();">
  <mat-card class="__pop  z-manual-elevation  c-soft-elevation">

    <h3
      class="__title"
      [innerHTML]="heading + (subtitle ? '<h5>' + subtitle + '</h5>': '')">
    </h3>

    <ng-content></ng-content>

    <div class="__actions-grid">

      <div>
        <button
          mat-raised-button
          [color]="confirmColor"
          type="button"
          class="__button"
          (click)="confirm.emit(); popRef.close();">
          <mat-icon *ngIf="confirmButtonIcon">{{ confirmButtonIcon }}</mat-icon>
          {{ confirmButtonText }}
        </button>
      </div>

      <div>
        <button
          mat-button
          type="button"
          class="__button"
          (click)="closeTrigger.emit(); popRef.close();">
          {{ closeButtonText }}
        </button>
      </div>

    </div>

  </mat-card>
</sat-popover>
