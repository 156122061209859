export const NESTJS_IMPORT_YAML = `project:
  name: recipe-nestjs
  tags:
    - zerops-recipe

services:
  - hostname: api
    type: nodejs@20
    buildFromGit: https://github.com/zeropsio/recipe-nestjs
    enableSubdomainAccess: true
    priority: 5
    verticalAutoscaling:
      minRam: 0.25
      minFreeRamGB: 0.125

  - hostname: app
    type: static
    buildFromGit: https://github.com/zeropsio/recipe-backend-ui
    enableSubdomainAccess: true

  - hostname: db
    type: postgresql@16
    mode: NON_HA
    priority: 10

  - hostname: storage
    type: object-storage
    objectStoragePolicy: public-read
    objectStorageSize: 2
    priority: 10

  - hostname: adminer
    type: php-apache@8.1
    buildFromGit: https://github.com/zeropsio/recipe-adminer
    enableSubdomainAccess: true

  - hostname: mailpit
    type: go@1
    buildFromGit: https://github.com/zeropsio/recipe-mailpit
    enableSubdomainAccess: true`;
