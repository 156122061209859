<mat-card
  class="__info"
  [ngClass]="{
    'is-orange': color === 'orange',
    'is-green': color === 'green',
    'is-base': color === 'base'
  }">

  <p class="__desc">
    <zui-docs-text>

      <div class="__desc_group">
        Make sure to point <strong>A</strong> and / or <strong>AAAA</strong> DNS records of listed domains to the unique IP&nbsp;addresses assigned to the project <strong>{{ projectName }}</strong>.
      </div>

      <div class="__desc_group">
        <span *ngIf="!ipV4">
          Alternatively you can point the&nbsp;<strong>A</strong>&nbsp;record to the free <a data-zui-docs="routing/using-shared-ipv4.html">shared IPv4&nbsp;address</a>
          &nbsp;
          <zef-copy-to-clipboard
            [copyData]="sharedIpV4"
            [tooltip]="'Copy shared IPv4 address to clipboard'">
            <strong>{{ sharedIpV4 }}</strong>
          </zef-copy-to-clipboard> along with the&nbsp;<strong>AAAA</strong>&nbsp;record pointing the unique IPv6 address (required for shared IPv4 to work).
        </span>
      </div>

      <div class="__desc_group  __desc_group--last">
        If you've used a wildcard, you need to additionally point <br/>
        <em>1.</em> <strong>*.example.com</strong> CNAME <strong>example.com</strong> <br/>
        <em>2.</em> <strong>_acme-challenge.<strong>example.com</strong></strong> CNAME <strong><strong>example.com</strong>.zerops.zone</strong>
      </div>

    </zui-docs-text>
  </p>

  <zui-ip-info
    class="__dns"
    [ipV6]="ipV6"
    [ipV4]="ipV4"
    [ipV4PendingRequest]="ipV4PendingRequest"
    [requestIpV4ButtonText]="requestIpV4ButtonText"
    [ipV4Prefix]="ipV4Prefix"
    [ipV6Prefix]="ipV6Prefix"
    [requestIpv4Key]="requestIpv4Key"
    [showOnlyIpV4Button]="showOnlyIpV4Button"
    [projectStatus]="projectStatus"
    [ipV4AddonInProcess]="ipV4AddonInProcess"
    (requestIpV4)="requestIpV4.emit()">
  </zui-ip-info>

</mat-card>
