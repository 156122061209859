/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ZefForm, ZefFormsManagerService } from '@zerops/zef/forms';
import { AppState } from '@zerops/zerops/app';
import {
  FormControlState,
  FormGroupState,
  FormState,
  setErrors,
  validate
} from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { FEATURE_NAME } from './billing-info-form.constant';

export interface BillingInfoFormValue {
  vatPayer: boolean;
  vatNumber: string;
  companyNumber: string;
  companyName: string;
  invoiceAddressStreet: string;
  invoiceAddressCity: string;
  invoiceAddressPostcode: string;
  invoiceAddressCountryId: string;
}

export type BillingInfoFormState = FormState<BillingInfoFormValue>;

const requiredOnVatPayerTrue = (s: FormControlState<string>, ps: FormGroupState<BillingInfoFormValue>) => {
  if (ps?.controls?.vatPayer?.value === true) {
    return validate<string>(required)(s);
  } else {
    return setErrors(s, {})
  }
};

@Injectable({ providedIn: 'root' })
export class TopUpBillingInfoForm extends ZefForm<BillingInfoFormValue> {
  constructor(
    public store: Store<AppState>,
    public formsManager: ZefFormsManagerService
  ) {
    super(
      store,
      formsManager,
      FEATURE_NAME,
      {
        vatPayer: false,
        vatNumber: null,
        companyName: null,
        companyNumber: null,
        invoiceAddressCity: null,
        invoiceAddressStreet: null,
        invoiceAddressPostcode: null,
        invoiceAddressCountryId: null
      },
      {
        companyName: requiredOnVatPayerTrue,
        invoiceAddressCity: requiredOnVatPayerTrue,
        invoiceAddressStreet: requiredOnVatPayerTrue,
        invoiceAddressPostcode: requiredOnVatPayerTrue,
        invoiceAddressCountryId: requiredOnVatPayerTrue
      }
    );
  }
}
