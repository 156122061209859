import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ZefReactiveComponent, basicZeropsYmlOutline, escapeEnvContent } from '@zerops/zef/core';
import { selectZefDialogState } from '@zerops/zef/dialog';
import { zefDialogClose } from '@zerops/zef/dialog';
import {
  EMPTY,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs';
import { AppState } from '@zerops/zerops/app';
import { FEATURE_NAME } from './pipeline-trigger-dialog.constant';
import { PipelineTriggerForm } from './pipeline-trigger-dialog.form';
import { formValueOnValid } from '@zerops/zef/forms';
import { ServiceStackEntity, triggerPipeline } from '@zerops/zerops/core/service-stack-base';
import { AppVersionEntity } from '@zerops/zerops/core/app-version-base';
import { HttpClient } from '@angular/common/http';
import { UserDataEntity } from '@zerops/zerops/core/user-data-base';

@Component({
  selector: 'z-pipeline-trigger-dialog',
  templateUrl: './pipeline-trigger-dialog.feature.html',
  styleUrl: './pipeline-trigger-dialog.feature.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PipelineTriggerDialogFeature extends ZefReactiveComponent {

  // # Deps
  #store = inject<Store<AppState>>(Store);
  #form = inject(PipelineTriggerForm);
  #http = inject(HttpClient);
  #serviceStackEntity = inject(ServiceStackEntity);
  #appVersionEntity = inject(AppVersionEntity);
  #userDataEntity = inject(UserDataEntity);

  // # Event Streams
  onClose$ = new Subject<void>();
  onSubmit$ = new Subject<void>();
  onPefillBuildFromGitYamlFromGitRepo$ = new Subject<void>();
  onPrefillZeropsYmlFromLastDeploy$ = new Subject<string>();
  onPrefillZeropsYmlOutline$ = new Subject<void>();
  onPrefillEnvs$ = new Subject<void>();

  // # Data
  // -- sync
  key = FEATURE_NAME;
  actionKey = triggerPipeline.type;

  // -- async
  dialogState$ = this.#store.pipe(select(selectZefDialogState(FEATURE_NAME)));
  open$ = this.dialogState$.pipe(map((data) => data?.state));
  id$ = this.dialogState$.pipe(map((data) => data?.meta));
  service$ = this.id$.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    switchMap((d) => this.#serviceStackEntity.entityById$(d).pipe(filter((d) => !!d)))
  );
  userData$ = this.service$.pipe(
    switchMap((service) => this.#userDataEntity.list$({
      name: this.key,
      id: service.id
    }).pipe(filter((d) => !!d?.length)))
  );
  activeAppVersionId$ = this.service$.pipe(
    map((d) => d.activeAppVersion?.id),
    filter((d) => !!d)
  );
  activeAppVersion$ = this.service$.pipe(
    switchMap((service) => this.#appVersionEntity.list$({
      name: this.key,
      id: service.id
    }).pipe(
      filter((d) => !!d?.length),
      map((d) => d[0])
    ))
  );
  formState$ = this.#form.state$;
  formValue$ = this.#form.value$;
  buildFromGitValueContainsGithub$ = this.formValue$.pipe(
    map((v) => !!v.buildFromGit?.includes('github.com'))
  );
  recipeInfoLoading = signal(false);

  // # State resolver
  state = this.$connect({
    open: this.open$,
    formState: this.formState$,
    activeAppVersion: this.activeAppVersion$,
    buildFromGitValueContainsGithub: this.buildFromGitValueContainsGithub$
  });

  constructor() {
    super();

    // # Dispatcher
    this.$dispatchActions([

      this.onClose$.pipe(
        map(() => zefDialogClose({ key: FEATURE_NAME }))
      ),

      this.onPrefillZeropsYmlFromLastDeploy$.pipe(
        withLatestFrom(this.activeAppVersion$, this.service$),
        map(([ target, appVersion, service ]) => this.#form.setValue(
          target,
          `zerops:
    - setup: ${service.name}
${appVersion.configContent.split('\n').map((line) => `      ${line}`).join('\n')}
`
        ))
      ),

      this.onPrefillZeropsYmlOutline$.pipe(
        withLatestFrom(this.service$),
        map(([ _, service ]) => this.#form.setValue(
          'zeropsYaml',
          basicZeropsYmlOutline(
            service.name,
            service.serviceStackTypeInfo.serviceStackTypeVersionName
          )
        ))
      ),

      this.onPefillBuildFromGitYamlFromGitRepo$.pipe(
        withLatestFrom(this.formValue$),
        switchMap(([ _, value ]) => this.#http
          .get<{ zeropsYaml: any; }>(`/api/recipe/info?url=${value.buildFromGit}@main`)
          .pipe(
            tap(() => this.recipeInfoLoading.set(true)),
            map((d) => {
              this.recipeInfoLoading.set(false);
              return d.zeropsYaml?.[0]?.content;
            }),
            catchError(() => {
              this.recipeInfoLoading.set(false);
              return EMPTY;
            })
          )
        ),
        filter((d) => !!d),
        map((raw) => this.#form.setValue(
          'buildFromGitYaml',
          raw
        ))
      ),

      this.onPrefillEnvs$.pipe(
        withLatestFrom(this.userData$, this.service$),
        map(([ _, userData, service ]) => {
          const value = userData
            ?.filter((itm) => itm.type === 'SECRET' && itm.serviceStackId === service.id)
            ?.reduce((str, itm) => {
              const formattedContent = escapeEnvContent(itm.content);
              return str + `${itm.key}=${formattedContent}\n`;
            }, '');
            if (!value) {
            return false as any;
          }
          return this.#form.setValue(
            'userDataEnvFile',
            value
          );
        }),
        filter((d) => !!d)
      ),

      this.onSubmit$.pipe(
        formValueOnValid(this.#form),
        withLatestFrom(this.id$),
        map(([ data, id ]) => {
          const hasBuildFromGitToggle = data.buildFromGitToggle === true;
          const hasZeropsSetupToggle = data.zeropsYamlToggle === true;

          return triggerPipeline({
            data: {
              data: {
                ...data,
                buildFromGit: hasBuildFromGitToggle
                ? !!data.buildFromGit
                    ? data.buildFromGit
                    : undefined
                : undefined,
                zeropsSetup: hasBuildFromGitToggle
                  ? !!data.buildFromGitSetup
                      ? data.buildFromGitSetup
                      : undefined
                  : undefined,
                zeropsYaml: hasZeropsSetupToggle
                  ? (data.zeropsYaml ? data.zeropsYaml : undefined)
                  : (hasBuildFromGitToggle && !!data.buildFromGitYaml
                    ? data.buildFromGitYaml
                    : undefined),
                userDataEnvFile: data?.userDataEnvFile
                  ? data?.userDataEnvFile
                  : undefined,

                // remove toggles & buildFromGitSetup
                buildFromGitToggle: undefined,
                buildFromGitSetup: undefined,
                zeropsYamlToggle: undefined,
                buildFromGitYaml: undefined
              },
              id
            }
          }, { type: 'pop' });

        })
      )

    ]);
  }

}
