import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { onZefDialogClose, zefDialogClose, zefDialogOpen } from '@zerops/zef/dialog';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { pipelineTriggerDialogActions } from './pipeline-trigger-dialog.state';
import { FEATURE_NAME } from './pipeline-trigger-dialog.constant';
import { PipelineTriggerForm } from './pipeline-trigger-dialog.form';
import { ServiceStackEntity, triggerPipelineSuccess } from '@zerops/zerops/core/service-stack-base';
import { firstAvailable } from '@zerops/zef/core';
import { UserDataEntity } from '@zerops/zerops/core/user-data-base';
import { AppVersionEntity } from '@zerops/zerops/core/app-version-base';

@Injectable()
export class PipelineTriggerDialogEffect {

  // # Deps
  #actions$ = inject(Actions);
  #form = inject(PipelineTriggerForm);
  #userDataEntity = inject(UserDataEntity);
  #appVersionEntity = inject(AppVersionEntity);
  #serviceStackEntity = inject(ServiceStackEntity);

  // # Streams
  serviceStackAvailable$ = this.#actions$.pipe(
    ofType(pipelineTriggerDialogActions.open),
    map((d) => d.id),
    filter((d) => !!d),
    switchMap((d) => this.#serviceStackEntity.entityById$(d).pipe(
      firstAvailable()
    ))
  );
  dialogClose$ = this.#actions$.pipe(onZefDialogClose(FEATURE_NAME));

  // # Effects
  onDialogOpen$ = createEffect(() => this.#actions$.pipe(
    ofType(pipelineTriggerDialogActions.open),
    map(({ id }) => zefDialogOpen({
      key: FEATURE_NAME,
      meta: id
    }))
  ));

  setupUserDataListStream$ = createEffect(() => this.serviceStackAvailable$.pipe(
    map((serviceStack) => this.#userDataEntity.listSubscribe(
      serviceStack.project.clientId,
      {
        name: FEATURE_NAME,
        id: serviceStack.id
      },
      {
        search: [
          {
            name: 'serviceStackId',
            operator: 'eq',
            value: serviceStack.id
          }
        ]
      }
    ))
  ));

  setupAppVersionListStream$ = createEffect(() => this.serviceStackAvailable$.pipe(
    map((serviceStack) => this.#appVersionEntity.listSubscribe(
      serviceStack.project.clientId,
      {
        name: FEATURE_NAME,
        id: serviceStack.id
      },
      {
        search: [
          {
            name: 'serviceStackId',
            operator: 'eq',
            value: serviceStack.id
          },
          {
            name: 'id',
            operator: 'eq',
            value: serviceStack.activeAppVersion?.id
          }
        ]
      }
    ))
  ));

  onDialogOpenSetForm$ = createEffect(() => this.onDialogOpen$.pipe(
    switchMap(({ meta }) => this.#userDataEntity.list$({
      name: FEATURE_NAME,
      id: meta
    }).pipe(
      filter((d) => !!d?.length),
      take(1),
      switchMap((userData) => this.#appVersionEntity.list$({
        name: FEATURE_NAME,
        id: meta
      }).pipe(
        filter((d) => !!d?.length),
        take(1),
        map((appVersions) => ({ userData, appVersion: appVersions[0] }))
      )),
      map(({ appVersion }) => this.#form.setValue({
        ...this.#form.defaultValues,
        buildFromGit: this.#getGitUrlWithBranch(
          appVersion.publicGitSource?.gitUrl,
          appVersion.publicGitSource?.branchName
        ),
        buildFromGitSetup: appVersion.publicGitSource?.explicitSetup
      }))
    ))
  ));

  onTriggerPipelineSuccess$ = createEffect(() => this.#actions$.pipe(
    ofType(triggerPipelineSuccess),
    map(() => zefDialogClose({ key: FEATURE_NAME }))
  ));

  onDialogCloseResetForm$ = createEffect(() => this.dialogClose$.pipe(
    mergeMap(() => [
      this.#form.reset(),
      this.#form.setDefaultValues()
    ])
  ));

  #getGitUrlWithBranch(gitUrl: string, branchName: string) {
    if (!gitUrl) { return undefined; }
    if (branchName === 'main' || branchName === 'master' || !branchName) {
      return gitUrl;
    }
    return `${gitUrl}@${branchName}`
  }
}
