import { ViewModes } from '@zerops/zui/view-mode-switcher';

import { Sort } from '@zerops/zef/sort';

export enum ViewModeSettingNames {
  DASHBOARD_PROJECTS_VIEW_MODE = 'dashboardProjectsViewMode',
  DASHBOARD_USERS_VIEW_MODE = 'dashboardUsersViewMode',
  PROJECT_STACKS_VIEW_MODE = 'projectStacksViewMode'
}

export enum SortModeSettingNames {
  DASHBOARD_PROJECTS_SORT_MODE = 'dashboardProjectsSortMode',
  DASHBOARD_USERS_SORT_MODE = 'dashboardUsersSortMode',
  PROJECT_STACKS_SORT_MODE = 'projectStacksSortMode'
}

export enum ShowModeSettingNames {
  STACK_DETAIL_CONTAINER_RESOURCES_HISTORY_SHOW_MODE = 'stackDetailContainerResourcesHistoryShowMode'
}

interface ViewModeUserSettings {
  [ViewModeSettingNames.DASHBOARD_PROJECTS_VIEW_MODE]: ViewModes | null;
  [ViewModeSettingNames.DASHBOARD_USERS_VIEW_MODE]: ViewModes | null;
  [ViewModeSettingNames.PROJECT_STACKS_VIEW_MODE]: ViewModes | null;
}

interface SortModeUserSettings {
  [SortModeSettingNames.DASHBOARD_PROJECTS_SORT_MODE]: Sort | null;
  [SortModeSettingNames.DASHBOARD_USERS_SORT_MODE]: Sort | null;
  [SortModeSettingNames.PROJECT_STACKS_SORT_MODE]: Sort | null;
}

interface ShowModeUserSettings {
  [ShowModeSettingNames.STACK_DETAIL_CONTAINER_RESOURCES_HISTORY_SHOW_MODE]: boolean | null;
}

export interface PersistentUserSettings {
  viewMode: ViewModeUserSettings;
  sortMode: SortModeUserSettings;
  showMode: ShowModeUserSettings;
}
